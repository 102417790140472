import React, { useRef, useState } from 'react';
import { Form, Input, Typography, Button } from 'antd';
import { EditFilled } from '@ant-design/icons';

import { DataTable } from '../../../components';
import FormProduct from './FormProduct/FormProduct.component';

import { getParamURL } from '../../../utils/options';

import config from './DetailProduct.component.config';
import './DetailProduct.component.styles.css';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const DetailProduct = ({
  productGroupCat,
  productData,
  productUpdate,
  setProductDetail,
  ...props
}) => {
  const detailData = productData?.data;
  console.log('idDetailProduk', detailData);
  const productID = getParamURL('productID');
  const [state, setState] = useState({
    renderFormProduct: false,
    renderDetailProduct: true,
  });

  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      switch (nav.type) {
        case 'editProduct':
          currentState.renderFormProduct = !currentState.renderFormProduct;
          currentState.dataDetailProduct = nav?.data;
          break;
        default:
          currentState.renderListProduct = true;
          currentState.renderDetailProduct = false;
          currentState.renderFormProduct = false;
          break;
      }
      return { ...currentState };
    });
  };
  const columnHeader = [
    {
      title: 'Tanggal',
      key: '',
      dataIndex: '',
      formatDate: true,
      sortFormatDate: true,
    },
    {
      title: 'Detail',
      key: '',
      dataIndex: '',
      sort: true,
    },
    {
      title: 'Dilakukan oleh',
      key: '',
      dataIndex: '',
      sort: true,
    },
  ];
  const actionUpdateProduct = (payload) => {
    const requestBody = {
      ...payload,
      id: productID,
    };
    productUpdate(requestBody).then(() => {
      setProductDetail({ id: productID });
    });
  };
  return (
    <div id="detailProduct">
      <div className="button-action" style={{ float: 'right' }}>
        <Button
          type="link"
          icon={<EditFilled />}
          onClick={() =>
            navigationContainer({ type: 'editProduct', data: detailData })
          }
        >
          Ubah Data Produk
        </Button>
      </div>
      <div>
        <Title level={4}>Product Name : {detailData?.service_name}</Title>
        <Paragraph
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
        >
          <b>Deskripsi Produk</b> : {detailData?.description}
        </Paragraph>
      </div>
      <div className="history-product">
        <Title level={5}>Histori perubahan produk</Title>
        <DataTable
          columns={columnHeader}
          dataSource={detailData?.history ? detailData?.history : []}
          withPagination={10}
          sizeChanger
        />
      </div>
      <FormProduct
        sourceData={detailData}
        visibleModal={state.renderFormProduct}
        navigationContainer={navigationContainer}
        actionUpdateProduct={actionUpdateProduct}
        productGroupCat={productGroupCat.data}
      />
    </div>
  );
};

DetailProduct.displayName = config.displayName;
DetailProduct.defaultProps = config.defaultProps;
DetailProduct.propTypes = config.propTypes;

export default DetailProduct;
