import { format } from 'date-fns';
import { isNaN, round } from 'lodash';
import moment from 'moment';
import 'moment/locale/id';

export const formatNumber = (number, fixed) => {
  const numberParsing = round(parseFloat(number));
  if (isNaN(numberParsing)) return '-';
  return numberParsing
    .toFixed(fixed)
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatPercentage = (number, fixed, withSpace) => {
  const numberParsing = parseFloat(number);
  if (isNaN(numberParsing)) return '-';
  return `${formatNumber(numberParsing, fixed)}${withSpace ? ' %' : '%'}`;
};

export const formatCurrency = (number, currency) => {
  if (isNaN(number)) return '-';
  return `${currency || 'IDR'} ${formatNumber(number)}`;
};

export const formatDate = (date) => {
  const dateParse = 'n/a';
  if (date !== null) return moment(new Date(date)).format('dddd, DD MMMM YYYY');
  return dateParse;
};

export const formatDateTime = (date) => {
  let data = '-';
  if (date !== null) data = moment(new Date(date)).format('LLLL');
  // .format('dddd, DD MMM YYYY, HH:mm:ss');
  return data;
};
