import React, { useRef, useState } from 'react';
import { Form, Input, Typography, Button } from 'antd';
import { EditFilled } from '@ant-design/icons';

import { DataTable } from '../../../components';
import FormClient from './FormClient/FormClient.component';

import { getParamURL } from '../../../utils/options';

import config from './DetailClient.component.config';
import './DetailClient.component.styles.css';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const DetailClient = ({ clientData, clientUpdate, clientDetail, ...props }) => {
  const detailData = clientData?.data;
  const clientID = getParamURL('clientID');
  const [state, setState] = useState({
    renderFormClient: false,
    renderDetailClient: true,
  });

  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      switch (nav.type) {
        case 'editClient':
          currentState.renderFormClient = !currentState.renderFormClient;
          currentState.dataDetailClient = nav?.data;
          break;
        default:
          currentState.renderListClient = true;
          currentState.renderDetailClient = false;
          currentState.renderFormClient = false;
          break;
      }
      return { ...currentState };
    });
  };
  const columnHeader = [
    {
      title: 'Invoice Number',
      dataIndex: '',
      key: '',
      sort: true,
      linked: true,
    },
    {
      title: 'Tipe',
      dataIndex: '',
      key: '',
      sort: true,
    },
    {
      title: 'Nama Project',
      dataIndex: '',
      key: '',
      sort: true,
    },
    {
      title: 'Dibuat Pada',
      dataIndex: '',
      key: '',
      formatDate: true,
      sortFormatDate: true,
    },
    {
      title: 'Jatuh Tempo',
      dataIndex: '',
      key: '',
      formatDate: true,
      sortFormatDate: true,
    },
    {
      title: 'Total Tagihan',
      dataIndex: '',
      key: '',
      formatCurrency: 'Rp.',
      sortFormatNumber: true,
    },
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      sort: true,
    },
  ];

  const actionUpdateClient = (payload) => {
    const requestBody = {
      ...payload,
      id: clientID,
    };
    clientUpdate(requestBody).then(() => {
      clientDetail({ id: clientID });
    });
  };
  return (
    <div id="detailClient">
      <div className="button-action" style={{ float: 'right' }}>
        <Button
          type="link"
          icon={<EditFilled />}
          onClick={() =>
            navigationContainer({ type: 'editClient', data: detailData })
          }
        >
          Ubah Data Client
        </Button>
      </div>
      <div>
        <Title level={3}>{detailData?.brand_name}</Title>
        <Paragraph
          ellipsis={{
            rows: 6,
            expandable: false,
          }}
        >
          <div>Nama Kontak Klien : {detailData?.person_in_charge}</div>
          <div>Nomor Kontak Klien : {detailData?.phone}</div>
          <div>VA Number Klien : {detailData?.virtual_account}</div>
          <div>Email Kontak Klien : {detailData?.primary_email}</div>
          <div>CC Email Kontak Klien : {detailData?.emailcc}</div>
          <div>Alamat Klien : {detailData.address}</div>
        </Paragraph>
      </div>
      <div>
        <Title level={5}>List Invoice Jatuh Tempo</Title>
        <div className="history-client">
          <DataTable
            columns={columnHeader}
            dataSource={detailData?.history ? detailData?.history : []}
            withPagination={10}
            sizeChanger
          />
        </div>
      </div>
      <FormClient
        sourceData={detailData}
        visibleModal={state.renderFormClient}
        navigationContainer={navigationContainer}
        actionUpdateClient={actionUpdateClient}
      />
    </div>
  );
};

DetailClient.displayName = config.displayName;
DetailClient.defaultProps = config.defaultProps;
DetailClient.propTypes = config.propTypes;

export default DetailClient;
