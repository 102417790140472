import React, { useRef } from 'react';
import { Modal, Form, Input, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import config from './FormClient.component.config';
import './FormClient.component.styles.css';

const { TextArea } = Input;
const { Title, Text } = Typography;

const FormClient = ({
  sourceData,
  visibleModal,
  navigationContainer,
  actionUpdateClient,
  ...props
}) => {
  const refModal = useRef();
  setTimeout(() => {
    refModal?.current?.setFieldsValue({
      client_code: sourceData?.client_code,
      brand_name: sourceData?.brand_name,
      person_in_charge: sourceData?.person_in_charge,
      phone: sourceData?.phone,
      primary_email: sourceData?.primary_email,
      emailcc: sourceData?.emailcc,
      address: sourceData?.address,
    });
  }, 500);
  return (
    <Modal
      className="modal-client"
      title={
        <div>
          <Title level={4}>Ubah Data Klien : {sourceData?.brand_name}</Title>
          <Text style={{ fontSize: 12 }}>
            <b>Virtual Account :</b>
            {sourceData?.virtual_account}
          </Text>
        </div>
      }
      visible={visibleModal}
      onCancel={() => navigationContainer({ type: 'editClient' })}
      maskClosable={false}
      width={600}
      footer={false}
      centered
      destroyOnClose
    >
      <Form
        ref={refModal}
        layout="horizontal"
        name="client"
        onFinish={() => {
          const formData = refModal.current.getFieldsValue();
          actionUpdateClient(formData);
          setTimeout(() => {
            navigationContainer({ type: 'editClient' });
          }, 500);
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label="Client code" name="client_code">
          <Input placeholder="input here..." readOnly />
        </Form.Item>
        <Form.Item
          label="Client / Brand Name"
          name="brand_name"
          rules={[
            {
              required: true,
              message: 'Brand Name is required',
            },
          ]}
        >
          <Input type="text" placeholder="input here..." />
        </Form.Item>
        <Form.Item
          label="PIC Name"
          name="person_in_charge"
          rules={[
            {
              required: true,
              message: 'PIC Name is required',
            },
          ]}
        >
          <Input placeholder="input here..." />
        </Form.Item>
        <Form.Item
          label="Email"
          name="primary_email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            {
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        >
          <Input type="email" placeholder="input here..." />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Phone Number is required',
            },
          ]}
        >
          <Input type="number" placeholder="input here..." />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <TextArea type="text" placeholder="input here..." rows={5} />
        </Form.Item>
        <div className="button-action">
          <Button
            type="default"
            onClick={() => navigationContainer({ type: 'editClient' })}
          >
            Batalkan
          </Button>
          <Button type="primary" htmlType="submit">
            Update Client
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

FormClient.displayName = config.displayName;
FormClient.defaultProps = config.defaultProps;
FormClient.propTypes = config.propTypes;

export default FormClient;
