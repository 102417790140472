import { get, round } from 'lodash';
import {
  SET_INVOICE_LIST_MASTER,
  SET_INVOICE_LIST,
  SET_INVOICE_LIST_DASHBOARD,
  SET_INVOICE_DETAIL,
  SET_INVOICE_CHECKOUT,
} from '../reduxType';

import api from '../../services/api.service';
import { getToken } from '../../services/token.service';
import { generateNotification } from '../../utils/general.utils';
const { apiService, setAuthToken } = api.getInstance();

const generateConfig = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()?.access_token}`,
    },
  };
  return { ...config };
};

// Create Invoice
export const invoiceCreate = (payload) => async () => {
  let result;
  try {
    const { status, data } = await apiService.post(
      'invoice/create_invoice',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Invoice has been created',
    });
    result = Promise.resolve(data.data);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

// Update Invoice
export const invoiceUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'invoice/update_invoice',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Invoice has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

// Update Invoice Transaction
export const invoiceUpdateTT = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'invoice/update_invoice_transaction',
      {
        ...payload,
      },
      generateConfig(),
    );
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const reqInvoiceSentEmail = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'invoice/req_sent_email_invoice',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Invoice Transaction has been sent.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const invoiceUpdateTTRecuring = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'invoice/update_invoice_transaction_recuring',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Invoice Transaction has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

export const updateConditionInvoice = (payload) => async () => {
  let result = null;
  try {
    const { status, data } = await apiService.put(
      `/invoice/update_condition_invoice`,
      { ...payload },
      generateConfig(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

// List Invoice
export const invoiceListMaster = (payload) => async (dispatch) => {
  dispatch({
    type: SET_INVOICE_LIST_MASTER,
    payload: {
      loading: true,
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/invoice/get_invoice_list_tm`,
      generateConfig(),
    );
    const generateData = get(data, 'data').map((dt) => ({
      id: dt.id,
      key: dt.id,
      brand_name: dt.brand_name,
      created_at: dt.created_at,
      due_date: dt.due_date,
      due_date_count: dt.due_date_count,
      is_recurring: dt.is_recurring,
      is_recurring_status: dt.is_recurring_status,
      notes: dt.notes,
      project_name: dt.project_name,
      recurring_count: dt.recurring_count,
      scheduled_date: dt.scheduled_date,
      status: dt.status,
      total_amount: dt.total_amount,
      updated_at: dt.updated_at,
    }));
    dispatch({
      type: SET_INVOICE_LIST_MASTER,
      payload: {
        data: generateData,
        loading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_INVOICE_LIST_MASTER,
      payload: {
        loading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};
export const invoiceList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_INVOICE_LIST,
    payload: {
      loading: true,
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/invoice/get_invoice_list`,
      generateConfig(),
    );
    dispatch({
      type: SET_INVOICE_LIST,
      payload: {
        data: get(data, 'data'),
        loading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_INVOICE_LIST,
      payload: {
        loading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const invoiceListForExport = (payload) => async (dispatch) => {
  let result;
  try {
    let urlRequest = `/invoice/get_invoice_list?dateStart=${payload.date_start}&dateEnd=${payload.date_end}`;
    if (payload.status !== 0) {
      urlRequest += `&statusInvoice=${payload.status}`;
    }
    if (payload.invoice_type !== '-') {
      urlRequest += `&typeInvoice=${payload.invoice_type}`;
    }

    const { status, data } = await apiService.get(urlRequest, generateConfig());
    const returnData = data.data?.map((invoice) => ({
      id: invoice.id,
      key: invoice.id,
      invoice_number: invoice.invoice_number,
      project_name: invoice.project_name,
      brand_name: invoice.brand_name,
      scheduled: invoice.invoice_scheduled,
      due_date: invoice.invoice_due_date,
      type: invoice.invoice_type,
      month: invoice.invoice_type === 'Satuan' ? '-' : invoice.invoice_month,
      amount: round(invoice.total_amout),
      status_master: invoice.invoice_status,
      status: invoice.invoice_status_transaction,
      created: invoice.invoicett_created_at,
      updated: invoice.invoicett_updated_at,
    }));
    result = Promise.resolve(returnData);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const dashboardInvoiceList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_INVOICE_LIST_DASHBOARD,
    payload: {
      satuan: {
        data: [],
        loading: false,
      },
      terjadwal: {
        data: [],
        loading: false,
      },
      jatuh_tempo: {
        data: [],
        loading: false,
      },
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/invoice/get_new_invoice_list?paramInvoiceType=${payload}`,
      generateConfig(),
    );

    let mockData = null;
    switch (payload) {
      case 'satuan':
        mockData = {
          satuan: {
            data: get(data, 'data'),
            loading: false,
          },
        };
        break;
      case 'terjadwal':
        mockData = {
          terjadwal: {
            data: get(data, 'data'),
            loading: false,
          },
        };
        break;
      default:
        mockData = {
          jatuh_tempo: {
            data: get(data, 'data'),
            loading: false,
          },
        };
        break;
    }
    dispatch({
      type: SET_INVOICE_LIST_DASHBOARD,
      payload: mockData,
    });
    result = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_INVOICE_LIST_DASHBOARD,
      payload: {
        loading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

// export const invoiceDetail = (payload) => async (dispatch) => {
//   dispatch({
//     type: SET_INVOICE_LIST,
//     payload: {
//       loading: true,
//     },
//   });

//   let result;
//   try {
//     const { status, data } = await apiService.get(
//       `/invoice/get_invoice_detail?paramInvoiceID=${payload}`,
//       generateConfig(),
//     );
//     dispatch({
//       type: SET_INVOICE_DETAIL,
//       payload: {
//         data: data?.data,
//       },
//     });
//     result = Promise.resolve();
//   } catch (error) {
//     dispatch({
//       type: SET_INVOICE_LIST,
//       payload: {
//         loading: false,
//       },
//     });
//     generateNotification({
//       type: 'error',
//       response: error?.response?.status,
//       messages: error?.response?.data?.detail,
//     });
//     result = Promise.reject();
//   }
//   return result;
// };

export const setInvoiceDetail = (payload) => async (dispatch) => {
  let result = true;
  try {
    const { status, data } = await apiService.get(
      `/invoice/get_invoice_detail?paramInvoiceID=${payload?.id}`,
      generateConfig(),
    );
    dispatch({
      type: SET_INVOICE_DETAIL,
      payload: {
        data: data.data,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const getInvoiceForCheckout = (payload) => async (dispatch) => {
  dispatch({
    type: SET_INVOICE_CHECKOUT,
    payload: {},
  });
  let result = null;
  try {
    const { status, data } = await apiService.get(
      `/co/data?hash=${payload}`,
      generateConfig(),
    );
    dispatch({
      type: SET_INVOICE_CHECKOUT,
      payload: data.data,
    });
    result = Promise.resolve(data.data);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    dispatch({
      type: SET_INVOICE_CHECKOUT,
      payload: {},
    });
    result = Promise.reject();
  }
  return result;
};

export const requestInvoiceXendit = (payload) => async () => {
  let result = null;
  try {
    const { status, data } = await apiService.post(
      `/co/invoice`,
      { ...payload },
      generateConfig(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const updateInvoiceXendit = (payload) => async () => {
  let result = null;
  try {
    const { status, data } = await apiService.put(
      `/co/invoice`,
      { ...payload },
      generateConfig(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const generateInvoiceRecurring = (payload) => async () => {
  let result = null;
  try {
    let texinvoiceID = null;
    if (payload.invoiceID !== undefined) {
      texinvoiceID = `?invoiceID=${payload.invoiceID}`;
    }
    const { status, data } = await apiService.get(
      `/sch/generate_invoice_transaction_recuring${texinvoiceID}`,
      generateConfig(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    console.log(error);
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages:
        'Tidak bisa membuat invoice baru, Invoice sebelumnya masih dalam status draft, kirim terlebih dahulu invoice yang berstatus draft!',
    });
    result = Promise.reject();
  }
  return result;
};
