import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import headerReducer from './header.reducer';
import commonReducer from './common.reducer';
import authReducer from './auth.reducer';
import productReducers from './product.reducers';
import clientReducers from './client.reducers';
import invoiceReducers from './invoice.reducers';

const headerPersistConfig = {
  key: 'header',
  storage,
};
const authPersistConfig = {
  key: 'auth',
  storage,
};
const productPersistConfig = {
  key: 'product',
  storage,
};
const clientPersistConfig = {
  key: 'client',
  storage,
};
const invoicePersistConfig = {
  key: 'invoice',
  storage,
};

const moduleState = {
  header: persistReducer(headerPersistConfig, headerReducer),
  common: commonReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  product: persistReducer(productPersistConfig, productReducers),
  client: persistReducer(clientPersistConfig, clientReducers),
  invoice: persistReducer(invoicePersistConfig, invoiceReducers),
};

export default combineReducers(moduleState);
