import { connect } from 'react-redux';
import AdminProduct from './AdminProduct.component';
import {
  productCreate,
  productUpdate,
  productGroup,
  productList,
  productDelete,
  setProductDetail,
} from '../../../redux/actions/product.actions';

const mapStateToProps = (state) => ({
  productGroupCat: state.product.group,
  productListData: state.product.list,
});

const mapDispatchToProps = {
  productCreate,
  productUpdate,
  productGroup,
  productList,
  productDelete,
  setProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProduct);
