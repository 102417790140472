import { connect } from 'react-redux';
import DetailProduct from './DetailProduct.component';
import {
  productUpdate,
  setProductDetail,
} from '../../../redux/actions/product.actions';

const mapStateToProps = (state) => ({
  productGroupCat: state.product.group,
  productData: state.product.detail,
});

const mapDispatchToProps = {
  productUpdate,
  setProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailProduct);
