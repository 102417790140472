import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Image,
  Card,
  Checkbox,
} from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { getToken } from '../../services/token.service';

import { BG_LOGIN, BTN_LOGIN, BDD_LOGO } from '../../assets';
import config from './Auth.component.config';
import './Auth.component.styles.css';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;

const Auth = ({ ...props }) => {
  const { user } = props;
  const refForm = React.createRef();
  const isUserToken = getToken();
  const [state, setState] = React.useState({
    loadingForm: false,
    showQuickResetPass: false,
    passHasReset: false,
  });

  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e);

  const onFinishAction = () => {
    const fields = refForm.current.getFieldsValue();
    const { fAuthUser } = props;
    fAuthUser(fields).then((resp) => {
      if (resp) navigationMenu('/admin/*');
    });
  };

  useEffect(() => {
    if (isUserToken !== null) {
      navigationMenu('/admin/*');
    }
  });

  const renderFormAuth = () => (
    <Form
      className="form-auth"
      layout="vertical"
      name="authUser"
      ref={refForm}
      onFinish={onFinishAction}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Email is required',
          },
          {
            type: 'email',
            message: 'Email is not valid',
          },
        ]}
      >
        <Input
          placeholder="Masukkan alamat email..."
          autoComplete="off"
          className="input-text"
          type="email"
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
        ]}
        extra={
          <div className="extra-btn-pwd">
            <Checkbox
              key="remember"
              type="link"
              className="btn-remember"
              onClick={() => console.log('remember password')}
            >
              Ingatkan login ini
            </Checkbox>
            <Button
              key="forgotPassword"
              type="link"
              className="btn-forgot"
              onClick={() => console.log('forgot password')}
            >
              Lupa Password?
            </Button>
          </div>
        }
      >
        <Input.Password
          autoComplete="off"
          placeholder="Masukkan password..."
          size="large"
          type="password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          size="large"
          htmlType="submit"
          type="primary"
          icon={<Image src={BTN_LOGIN} preview={false} />}
          loading={state.loadingForm}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layout id="AuthLayout">
      <Content id="authForm" style={{ backgroundImage: `url(${BG_LOGIN})` }}>
        <div className="auth-logo">
          <div
            className="img-logo"
            style={{ backgroundImage: `url(${BDD_LOGO})` }}
          />
          <Title level={2}>
            BDD <br /> Web Invoicing System
          </Title>
        </div>
        <Card>
          <div className="section-form">
            <Title level={3}>Login</Title>
            <Text>Log in ke BDD Web Invoicing System</Text>
            {renderFormAuth()}
          </div>
        </Card>

        <Footer>
          <div className="footer" style={{ cursor: 'pointer' }}>
            <Text
              className="site-policy"
              onClick={() =>
                window.open(
                  `${window.location.origin}/site-policy/terms-of-service`,
                )
              }
            >
              Site Policy
            </Text>
          </div>
          <Text className="footer">
            Copyright © {format(new Date(), 'yyyy')} Boleh Dicoba Digital. All
            rights reserved
          </Text>
        </Footer>
      </Content>
    </Layout>
  );
};

Auth.displayName = config.displayName;
Auth.defaultProps = config.defaultProps;
Auth.propTypes = config.propTypes;

export default Auth;
