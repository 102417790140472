import { get } from 'lodash';
import {
  SET_PRODUCT_LIST,
  SET_PRODUCT_GROUP,
  SET_PRODUCT_GROUP_LIST,
  SET_PRODUCT_DETAIL,
} from '../reduxType';
// import { generateNotification } from '../../utils/options';

import api from '../../services/api.service';
import { getToken } from '../../services/token.service';
import { generateNotification } from '../../utils/general.utils';
const { apiService, setAuthToken } = api.getInstance();

const generateConfig = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()?.access_token}`,
    },
  };
  return { ...config };
};

// Create Product
export const productCreate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'product/create',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Product has been created',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

// Update Product
export const productUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'product/update',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Product has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

// List Product Group
export const productGroup = (payload) => async (dispatch) => {
  let result;
  try {
    const { status, data } = await apiService.get(
      `/product/get_product_group`,
      generateConfig(),
    );
    dispatch({
      type: SET_PRODUCT_GROUP,
      payload: {
        data,
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

// List Product List Group
export const productListGroup = (payload) => async (dispatch) => {
  let result;
  try {
    const { status, data } = await apiService.get(
      `/product/get_product_group_list`,
      generateConfig(),
    );
    dispatch({
      type: SET_PRODUCT_GROUP_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

// List Product
export const productList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/product/get_product_list`,
      generateConfig(),
    );
    dispatch({
      type: SET_PRODUCT_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_PRODUCT_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const setProductDetail = (payload) => async (dispatch) => {
  let result = true;
  console.log(payload);
  try {
    const { status, data } = await apiService.get(
      `/product/get_product_detail?paramProductCode=${payload?.id}`,
      generateConfig(),
    );
    dispatch({
      type: SET_PRODUCT_DETAIL,
      payload: {
        data: data?.data[0],
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

// Delete Product
export const productDelete = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.delete(
      `product/delete_product?productId=${payload}`,
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Product has been deleted.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    const descriptionMsg = `
      ${error?.response?.data?.detail}
      ${error?.response?.data?.detail?.[0]?.loc.join('/')}
      ${error?.response?.data?.detail?.[0]?.msg}
      ${error?.response?.data?.detail?.[0]?.type}
    `;
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: descriptionMsg,
    });
    result = Promise.reject(error);
  }
  return result;
};
