import { notification } from 'antd';
import _, { isNaN } from 'lodash';

export const getParamURL = (param) => {
  const rawUrl = window.location.search;

  const objectURL = _.chain(rawUrl)
    .replace('?', '')
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value();

  const getParam = objectURL[param] || '';

  return getParam;
};

export const generateNotification = ({ ...props }) => {
  const notif = notification;
  switch (props?.type) {
    case 'error':
      notif.error({
        message: `System Err [${props?.response}]`,
        description: props?.messages,
      });
      break;
    case 'success':
      notif.success({
        message: `Success [${props?.response}]`,
        description: props?.messages,
      });
      break;
    case 'info':
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
    default:
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
  }
  return notif;
};

export const toDataUrl = async (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};
