import aws from 'aws-sdk';
import axios from 'axios';

const region = process.env.S3_REGION;
const bucketName = process.env.S3_BUCKETNAME;
const accessKeyId = process.env.S3_ACCESS_KEY_ID;
const secretAccessKey = process.env.S3_SECRET_ACCESS_KEY;

export const awsS3 = new aws.S3({
  region,
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
});

export const generateS3UploadUrl = async (pathS3, fileObject) => {
  const fileExt = fileObject.name.split('.').pop();
  const imageName = Math.floor(Math.random() * 9999999999999).toString(16);
  const params = {
    Bucket: `${bucketName}/${pathS3}`,
    Key: `${imageName}.${fileExt}`,
    Expires: 60,
  };

  const uploadUrl = await awsS3.getSignedUrlPromise('putObject', params);
  return uploadUrl;
};

export const generateS3UploadUrl2 = async (pathS3, setup) => {
  const fileExt = setup.extension;
  const fileName = `${setup.filename}`;
  // const fileName = `${setup.filename}##${Math.floor(
  //   Math.random() * 9999999999999,
  // ).toString(4)}`;
  const params = {
    Bucket: `${bucketName}/${pathS3}`,
    Key: `${fileName}.${fileExt}`,
    Expires: 60,
  };

  const uploadUrl = await awsS3.getSignedUrlPromise('putObject', params);
  return uploadUrl;
};

export const executeS3UploadUrl = async (url, file) => {
  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: file,
  });
  return url.split('?')[0];
};

const getSignedURLEndpoint = async (keyObject, operation) => {
  const options = {
    Bucket: bucketName,
    Key: keyObject,
    Expires: 3000,
  };

  const url = await awsS3.getSignedUrlPromise(operation, options);
  return url;
};

export const getObjectAwsS3Bucket = async (keyObject) => {
  let urlDataImage = null;
  await getSignedURLEndpoint(keyObject, 'getObject').then(async (url) => {
    urlDataImage = url;
    // await axios
    //   .get(url)
    //   .then((res) => {
    //     // console.log('response s3', res);
    //     const responseData = res.data;
    //     const imgBlob = new Blob([responseData.toString()], {
    //       type: 'image/png',
    //     });
    //     urlDataImage = imgBlob;
    //   })
    //   .catch((err) => console.log('response error', err));
  });
  return urlDataImage;
  // await awsS3.getObject(params, (err, data) => {
  //   if (err) {
  //     console.log(err, err.stack);
  //   } else {
  //     const dataBlob = new Blob([data.Body.toString()], {
  //       type: 'text/csv;charset=utf-8;',
  //     });
  //     console.log(dataBlob);
  //     // downloadBlob(csvBlob, `${template}`);
  //   }
  // });
};
