import React, { useState, useEffect } from 'react';
import { isArray } from 'lodash';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Typography, Button } from 'antd';

import config from './AdminDashboard.component.config';
import './AdminDashboard.component.styles.css';
import { CardDataTable } from '../../../components';

const { Title, Text } = Typography;

const AdminDashboard = ({
  invoiceListData,
  dashboardInvoiceList,
  setInvoiceDetail,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const invoiceSatuan = invoiceListData?.satuan?.data;
  const invoiceSatuanLoading = invoiceListData?.satuan?.loading;
  const invoiceTerjadwal = invoiceListData?.terjadwal?.data;
  const invoiceTerjadwalLoading = invoiceListData?.terjadwal?.loading;
  const invoiceJatuhTempo = invoiceListData?.jatuh_tempo?.data;
  const invoiceJatuhTempoLoading = invoiceListData?.jatuh_tempo?.loading;
  const [state, setState] = useState({
    needMounting: true,
    showEmpty: false,
    showList: true,
    showDetail: false,
  });

  const mountingFirstData = () => {
    dashboardInvoiceList('jatuh tempo');
    dashboardInvoiceList('satuan');
    dashboardInvoiceList('terjadwal');
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };

  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const selectInvoiceData = (type, idx) => {
    let dataInvoice = null;
    let forwardTo = 'draft';
    if (type === 'satuan') {
      dataInvoice = invoiceSatuan.find((items) => items.id === idx);
      forwardTo =
        dataInvoice.invoice_status.toLowerCase() === 'active'
          ? 'detail'
          : 'draft';
    }
    if (type === 'terjadwal') {
      dataInvoice = invoiceTerjadwal.find((items) => items.id === idx);
      forwardTo =
        dataInvoice.invoice_status.toLowerCase() === 'active'
          ? 'detail'
          : 'draft';
    }
    if (type === 'jtempo') {
      dataInvoice = invoiceJatuhTempo.find((items) => items.id === idx);
      forwardTo =
        dataInvoice.invoice_status.toLowerCase() === 'active'
          ? 'detail'
          : 'draft';
    }
    // const dataInvoice = invoiceListArr.find((items) => items.id === idx);
    setInvoiceDetail(dataInvoice).then(() =>
      navigate(
        `${window.location.pathname.replaceAll(
          'dashboard',
          'invoice',
        )}/${forwardTo}?invoiceID=${idx}`,
      ),
    );
  };
  const buttonViewDetailSatuan = (data) => (
    <Button type="primary" onClick={() => selectInvoiceData('satuan', data)}>
      View Detail
    </Button>
  );
  const buttonViewDetailTerjadwal = (data) => (
    <Button type="primary" onClick={() => selectInvoiceData('terjadwal', data)}>
      View Detail
    </Button>
  );
  const buttonViewDetailJatuhTempo = (data) => (
    <Button type="primary" onClick={() => selectInvoiceData('jtempo', data)}>
      View Detail
    </Button>
  );
  return (
    <div id="AdminDashboard">
      <div className="title-dashboard">
        <Title level={3}>Selamat Datang Kembali, Hanny Dzikria</Title>
        <Text>Highlight Invoice Hari ini.</Text>
      </div>
      <div className="content-dashboard">
        <CardDataTable
          titleCard="List Invoice Yang Sudah Jatuh Tempo"
          tableColumn={config.defaultProps.headerTable}
          tableData={isArray(invoiceJatuhTempo) ? invoiceJatuhTempo : []}
          loading={invoiceJatuhTempoLoading}
          tableWithPagination={10}
          tableDisablePagination
          tableAction={[buttonViewDetailJatuhTempo]}
        />
        <CardDataTable
          titleCard="List Invoice Satuan"
          titleAction={<Button type="link">Lihat Semua Invoice Satuan</Button>}
          tableColumn={config.defaultProps.headerTable}
          tableData={isArray(invoiceSatuan) ? invoiceSatuan : []}
          loading={invoiceSatuanLoading}
          tableWithPagination={10}
          tableSizeChanger
          tableAction={[buttonViewDetailSatuan]}
        />
        <CardDataTable
          titleCard="List Invoice Terjadwal"
          titleAction={
            <Button type="link">Lihat Semua Invoice Terjadwal</Button>
          }
          tableColumn={config.defaultProps.headerTable}
          tableData={isArray(invoiceTerjadwal) ? invoiceTerjadwal : []}
          loading={invoiceTerjadwalLoading}
          tableWithPagination={10}
          tableSizeChanger
          tableAction={[buttonViewDetailTerjadwal]}
        />
      </div>
    </div>
  );
};

AdminDashboard.displayName = config.displayName;
AdminDashboard.defaultProps = config.defaultProps;
AdminDashboard.propTypes = config.propTypes;

export default AdminDashboard;
