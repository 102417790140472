import { connect } from 'react-redux';
import Checkout from './Checkout.component';
import {
  getInvoiceForCheckout,
  requestInvoiceXendit,
} from '../../redux/actions/invoice.actions';

const mapStateToProps = (state) => ({
  invoiceData: state.invoice.checkout,
});

const mapDispatchToProps = {
  getInvoiceForCheckout,
  requestInvoiceXendit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
