import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { AppLayout } from '../../components';
import { ADMIN_ROUTE } from '../../navigations/admin.route';
import { destroyToken, getToken } from '../../services/token.service';

import config from './Admin.component.config';
import './Admin.component.styles.css';

const Admin = ({ fConsumeToken, ...props }) => {
  const location = useLocation();
  const isUserToken = getToken();
  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e);
  useEffect(() => {
    fConsumeToken();
    if (isUserToken === null) {
      navigationMenu('/auth');
      destroyToken();
    }
  });
  return (
    <AppLayout routeMenu={ADMIN_ROUTE} location={location}>
      <Routes>
        {ADMIN_ROUTE.map((items) => (
          <Route key={items.key} {...items} />
        ))}
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
        <Route path="*" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    </AppLayout>
  );
};

Admin.displayName = config.displayName;
Admin.defaultProps = config.defaultProps;
Admin.propTypes = config.propTypes;

export default Admin;
