import PropTypes from 'prop-types';

const displayName = 'AdminInvoice';
const propTypes = {};
const defaultProps = {
  headerInvoiceMaster: [
    {
      width: 80,
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      width: 200,
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
      sort: true,
    },
    {
      width: 250,
      title: 'Client Name',
      dataIndex: 'brand_name',
      key: 'brand_name',
      sort: true,
    },
    {
      width: 150,
      title: 'Invoice Type',
      dataIndex: 'is_recurring_status',
      key: 'is_recurring_status',
      sort: true,
    },
    {
      width: 200,
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      formatDateTime: true,
      sortFormatDate: true,
      defaultSortOrder: 'desc',
    },
    {
      width: 200,
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      formatDate: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Scheduled Date',
      dataIndex: 'scheduled_date',
      key: 'scheduled_date',
      formatDate: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      formatCurrency: 'Rp.',
      sortFormatNumber: true,
    },
    {
      width: 100,
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sort: true,
    },
    {
      width: 200,
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      formatDateTime: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      fixed: 'right',
    },
  ],
  headerInvoiceTransaction: [
    {
      width: 200,
      title: 'Invoice Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sort: true,
    },
    {
      width: 250,
      title: 'Client Name',
      dataIndex: 'brand_name',
      key: 'brand_name',
      sort: true,
    },
    {
      width: 150,
      title: 'Type',
      dataIndex: 'invoice_type',
      key: 'invoice_type',
      sort: true,
    },
    {
      width: 200,
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
      sort: true,
    },
    {
      width: 200,
      title: 'Created at',
      dataIndex: 'invoice_created_at',
      key: 'invoice_created_at',
      formatDateTime: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Due Date',
      dataIndex: 'invoice_due_date',
      key: 'invoice_due_date',
      formatDate: true,
      sortFormatDate: true,
      defaultSortOrder: 'desc',
    },
    {
      width: 200,
      title: 'Total Amount',
      dataIndex: 'total_amout',
      key: 'total_amout',
      formatCurrency: 'Rp.',
      sortFormatNumber: true,
    },
    {
      width: 100,
      title: 'Status',
      dataIndex: 'invoice_status_transaction',
      key: 'invoice_status_transaction',
      sort: true,
    },
    {
      width: 200,
      title: 'Updated at',
      dataIndex: 'invoice_updated_at',
      key: 'invoice_updated_at',
      formatDateTime: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      fixed: 'right',
    },
  ],
  headerInvoiceTransactionExport: [
    {
      width: 200,
      title: 'Invoice',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sort: true,
    },
    {
      width: 200,
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
      sort: true,
    },
    {
      width: 200,
      title: 'Client',
      dataIndex: 'brand_name',
      key: 'brand_name',
      sort: true,
    },
    {
      width: 200,
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sort: true,
    },
    {
      width: 200,
      title: 'Created at',
      dataIndex: 'created',
      key: 'created',
      formatDateTime: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      formatDate: true,
      sortFormatDate: true,
      defaultSortOrder: 'desc',
    },
    {
      width: 200,
      title: 'Total Amount',
      dataIndex: 'amount',
      key: 'amount',
      formatCurrency: 'Rp.',
      sortFormatNumber: true,
    },
    {
      width: 200,
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sort: true,
    },
    {
      width: 200,
      title: 'Updated at',
      dataIndex: 'updated',
      key: 'updated',
      formatDateTime: true,
      sortFormatDate: true,
    },
    {
      width: 200,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      fixed: 'right',
    },
  ],
};

export default { displayName, propTypes, defaultProps };
