/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';

const displayName = 'DetailInvoiceOpen';
const propTypes = {};
const defaultProps = {
  columnHeader: [
    {
      width: 220,
      title: 'Nama Produk',
      dataIndex: 'product',
      key: 'product',
    },
    {
      width: 110,
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      width: 250,
      title: 'Harga Satuan',
      dataIndex: 'amount',
      key: 'amount',
      formatCurrency: 'Rp.',
    },
    {
      // width: 250,
      title: 'Diskon',
      dataIndex: 'discount',
      key: 'discount',
      formatCurrency: 'Rp.',
    },
    {
      // width: 200,
      title: 'Harga total',
      dataIndex: 'sub_total',
      key: 'sub_total',
      formatCurrency: 'Rp.',
    },
    {
      // width: 100,
      title: 'PPN',
      dataIndex: 'ppn_nominal',
      key: 'ppn_nominal',
      formatCurrency: 'Rp.',
    },
    {
      // width: 100,
      title: 'PPH 23',
      dataIndex: 'pph_nominal',
      key: 'pph_nominal',
      formatCurrency: 'Rp.',
    },
  ],
}

export default { displayName, propTypes, defaultProps };
