import { connect } from 'react-redux';
import DetailInvoiceSend from './DetailInvoiceSend.component';
import {
  invoiceUpdateTT,
  setInvoiceDetail,
  reqInvoiceSentEmail,
} from '../../../redux/actions/invoice.actions';
import { productList } from '../../../redux/actions/product.actions';
import { clientList } from '../../../redux/actions/client.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  invoiceData: state.invoice.detail,
  productListData: state.product.list,
  clientListData: state.client.list,
});

const mapDispatchToProps = {
  invoiceUpdateTT,
  reqInvoiceSentEmail,
  productList,
  clientList,
  setInvoiceDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailInvoiceSend);
