import { connect } from 'react-redux';
import AdminDashboard from './AdminDashboard.component';
import {
  dashboardInvoiceList,
  setInvoiceDetail,
} from '../../../redux/actions/invoice.actions';

const mapStateToProps = (state) => ({
  invoiceListData: state.invoice.dashboard,
});

const mapDispatchToProps = {
  dashboardInvoiceList,
  setInvoiceDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
