import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Typography,
  Button,
  Input,
  Tabs,
  Modal,
  Space,
  DatePicker,
  Popover,
  Radio,
} from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { addDays, addMonths, addWeeks, addYears, format } from 'date-fns';
import * as XLSX from 'xlsx';

import { CardDataTable, DataTable } from '../../../components';
// import FormInvoice from '../../AdminInvoiceForm/AdminInvoiceForm.component';

import config from './AdminInvoice.component.config';
import './AdminInvoice.component.styles.css';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const AdminInvoice = ({
  invoiceListMasterData,
  invoiceListData,
  invoiceCreate,
  invoiceListMaster,
  invoiceList,
  invoiceListForExport,
  setInvoiceDetail,
  ...props
}) => {
  const [invoiceListMasterArr, setInvoiceListMasterArr] = useState();
  const invoiceListMasterLoading = invoiceListMasterData?.loading;
  const [invoiceListArr, setInvoiceListArr] = useState();
  const invoiceListLoading = invoiceListData?.loading;
  const navigate = useNavigate();
  const [state, setState] = useState({
    needMounting: true,
    showEmpty: false,
    showList: true,
    showDetail: false,
  });
  const last7DaysStart = format(
    new Date(addDays(new Date(), -7)),
    'yyyy-MM-dd',
  );
  const last7DaysEnd = format(new Date(), 'yyyy-MM-dd');
  const [stateExport, setStateExport] = React.useState({
    open: false,
    date_start: last7DaysStart,
    date_end: last7DaysEnd,
    status: 0,
    invoice_type: '-',
    data: [],
    loading: true,
  });

  const mountingFirstData = () => {
    invoiceListMaster();
    // invoiceList();
    invoiceListForExport({ ...stateExport }).then((response) => {
      setStateExport((currentState) => {
        const current = { ...currentState };
        current.data = response;
        current.loading = false;
        return { ...current };
      });
    });
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const searchData = (type, text) => {
    if (type === 'master') {
      let sourceMaster = invoiceListMasterData?.data;
      if (text !== '') {
        sourceMaster = invoiceListMasterData?.data?.filter(
          (item) =>
            item.brand_name?.toLowerCase()?.includes(text?.toLowerCase()) ||
            item.project_name?.toLowerCase()?.includes(text?.toLowerCase()),
        );
      }
      setInvoiceListMasterArr(sourceMaster);
    }

    if (type === 'transaction') {
      let sourceTransaction = stateExport.data;
      if (text !== '') {
        sourceTransaction = stateExport.data?.filter(
          (item) =>
            item.invoice_number?.toLowerCase()?.includes(text?.toLowerCase()) ||
            item.brand_name?.toLowerCase()?.includes(text?.toLowerCase()),
        );
      }
      setInvoiceListArr(sourceTransaction);
    }
  };
  const selectOneInvoice = (type, idx) => {
    let dataInvoice = null;
    let forwardTo = 'draft';

    if (type === 'master') {
      dataInvoice = invoiceListMasterData?.data.find(
        (items) => items.id === idx,
      );
      forwardTo =
        dataInvoice.status.toLowerCase() === 'active' ? 'detail' : 'draft';
    } else {
      // if (type === 'transaction') {
      // dataInvoice = invoiceListData?.data.find((items) => items.id === idx);
      dataInvoice = stateExport.data.find((items) => items.id === idx);
      forwardTo =
        // dataInvoice.status.toLowerCase() === 'open' ? 'detail' : 'draft';
        dataInvoice.status_master.toLowerCase() === 'active'
          ? 'detail'
          : 'draft';
    }
    // Validate state by type and status to set Invoice Detail
    if (dataInvoice !== null) {
      setInvoiceDetail(dataInvoice).then(() =>
        navigate(`${window.location.pathname}/${forwardTo}?invoiceID=${idx}`),
      );
    }
  };

  const buttonViewDetailMaster = (data) => (
    <Button type="primary" onClick={() => selectOneInvoice('master', data)}>
      View Detail
    </Button>
  );
  const buttonViewDetailTransaction = (data) => (
    <Button
      type="primary"
      onClick={() => selectOneInvoice('transaction', data)}
    >
      View Detail
    </Button>
  );

  const executeExportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(stateExport.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Export Data');
    const formatFileName = `${moment(stateExport.date_start).format(
      'YYYYMMDD',
    )}-${moment(stateExport.date_end).format('YYYYMMDD')}`;
    XLSX.writeFile(workbook, `BIS-${formatFileName}.xlsx`);
  };

  return (
    <div id="AdminInvoice">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<b>Active Invoice</b>} key="1">
          <CardDataTable
            // titleCard="List Invoice Transaction"
            titleAction={[
              <div>
                <Input
                  size="middle"
                  placeholder="Search by invoice number or client name..."
                  allowClear
                  style={{
                    width: 300,
                  }}
                  prefix={<SearchOutlined />}
                  onChange={(e) => searchData('transaction', e.target.value)}
                />
              </div>,
              <div>
                <RangePicker
                  style={{
                    borderRadius: 10,
                  }}
                  defaultValue={[moment(last7DaysStart), moment(last7DaysEnd)]}
                  onChange={(picker) => {
                    setStateExport((currentState) => {
                      const current = { ...currentState };
                      current.date_start = moment(picker[0]).format(
                        'YYYY-MM-DD',
                      );
                      current.date_end = moment(picker[1]).format('YYYY-MM-DD');
                      current.loading = true;
                      return { ...current };
                    });
                    invoiceListForExport({
                      ...stateExport,
                      date_start: moment(picker[0]).format('YYYY-MM-DD'),
                      date_end: moment(picker[1]).format('YYYY-MM-DD'),
                    }).then((response) => {
                      setStateExport((currentState) => {
                        const current = { ...currentState };
                        current.data = response;
                        current.loading = false;
                        return { ...current };
                      });
                    });
                  }}
                />
              </div>,
              <div>
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div id="export-data">
                      <div className="main-option">
                        <Space direction="vertical">
                          <div>
                            <Title level={5} style={{ fontSize: 12 }}>
                              Status:
                            </Title>
                            <Radio.Group
                              onChange={(val) =>
                                setStateExport((currentState) => {
                                  const current = { ...currentState };
                                  current.status = val.target.value;
                                  return { ...current };
                                })
                              }
                              value={stateExport.status}
                            >
                              <Radio value={0}>All</Radio>
                              <Radio value={1}>Open</Radio>
                              <Radio value={2}>Paid</Radio>
                            </Radio.Group>
                          </div>
                          <div>
                            <Title level={5} style={{ fontSize: 12 }}>
                              Invoice Type:
                            </Title>
                            <Radio.Group
                              onChange={(val) =>
                                setStateExport((currentState) => {
                                  const current = { ...currentState };
                                  current.invoice_type = val.target.value;
                                  return { ...current };
                                })
                              }
                              value={stateExport.invoice_type}
                            >
                              <Radio value="-">All</Radio>
                              <Radio value={0}>Satuan</Radio>
                              <Radio value={1}>Terjadwal</Radio>
                            </Radio.Group>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={() => {
                                setStateExport((currentState) => {
                                  const current = { ...currentState };
                                  current.loading = true;
                                  return { ...current };
                                });
                                invoiceListForExport({ ...stateExport }).then(
                                  (response) => {
                                    setStateExport((currentState) => {
                                      const current = { ...currentState };
                                      current.data = response;
                                      current.loading = false;
                                      return { ...current };
                                    });
                                  },
                                );
                              }}
                            >
                              Find Data
                            </Button>
                            <Button
                              style={{
                                marginLeft: 10,
                              }}
                              type="default"
                              onClick={() => {
                                setStateExport((currentState) => {
                                  const current = { ...currentState };
                                  current.loading = true;
                                  return { ...current };
                                });
                                invoiceListForExport({
                                  date_start: last7DaysStart,
                                  date_end: last7DaysEnd,
                                  status: 0,
                                  invoice_type: '-',
                                }).then((response) => {
                                  setStateExport((currentState) => {
                                    const current = { ...currentState };
                                    current.data = response;
                                    current.date_start = last7DaysStart;
                                    current.date_end = last7DaysEnd;
                                    current.status = 0;
                                    current.invoice_type = '-';
                                    current.loading = false;
                                    return { ...current };
                                  });
                                });
                              }}
                            >
                              Clear Filter
                            </Button>
                          </div>
                        </Space>
                      </div>
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    icon={<FilterOutlined />}
                    onClick={() => {
                      setStateExport((currentState) => {
                        const current = { ...currentState };
                        current.open = !current.open;
                        return { ...current };
                      });
                    }}
                  >
                    Filter Table
                  </Button>
                </Popover>
              </div>,
              <div>
                <Button
                  type="primary"
                  // icon={<FilterOutlined />}
                  onClick={() => executeExportData()}
                >
                  Export Data
                </Button>
              </div>,
            ]}
            tableColumn={config.defaultProps.headerInvoiceTransactionExport}
            tableData={
              invoiceListArr?.length > 0 ? invoiceListArr : stateExport.data
            }
            tableAction={[buttonViewDetailTransaction]}
            loading={stateExport.loading}
            tableWithPagination={10}
            tableSizeChanger={false}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<b>Inactive Invoice</b>} key="2">
          <CardDataTable
            // titleCard="Inactive Invoice"
            titleAction={[
              <div>
                <Input
                  size="middle"
                  placeholder="Search by project name or client name..."
                  allowClear
                  style={{
                    width: 300,
                  }}
                  prefix={<SearchOutlined />}
                  onChange={(e) => searchData('master', e.target.value)}
                />
              </div>,
              <div>
                <Button type="primary" icon={<FilterOutlined />}>
                  Filter Table
                </Button>
              </div>,
            ]}
            tableColumn={config.defaultProps.headerInvoiceMaster}
            tableData={
              invoiceListMasterArr?.length > 0
                ? invoiceListMasterArr
                : invoiceListMasterData?.data
            }
            tableAction={[buttonViewDetailMaster]}
            loading={invoiceListMasterLoading}
            tableWithPagination={10}
            tableSizeChanger={false}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

AdminInvoice.displayName = config.displayName;
AdminInvoice.defaultProps = config.defaultProps;
AdminInvoice.propTypes = config.propTypes;

export default AdminInvoice;
