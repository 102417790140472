import React from 'react';
import { Typography } from 'antd';
import config from './TextLayout.component.config';

const { Title, Text } = Typography;

const TextLayout = ({ data }) => (
  <>
    {data.heading && <Title style={{ fontSize: 52 }}>{data.heading}</Title>}
    {data.lastUpdated && <Text>{data.lastUpdated}</Text>}
    <Title level={2} style={{ marginTop: -2 }}>
      {data.title}
    </Title>
    <Text>
      <pre className="text-site-policy">
        {data.description}
        {data.link && <a href={data.href && data.href}>{data.link}</a>}
      </pre>
    </Text>
    {data.subcontent &&
      data.subcontent.map((item) => (
        <>
          <Title style={{ fontSize: 20 }}>{item.title}</Title>
          <Text>
            <pre className="text-site-policy">{item.description}</pre>
          </Text>
          {item.list &&
            item.list.map((text) => (
              <ul
                style={{
                  listStyleType: item.styledList ? 'disc' : 'none',
                  padding: '0 0 0 40px',
                }}
              >
                <li>{text.label}</li>
              </ul>
            ))}
          {item.middleText && (
            <Text>
              <pre className="text-site-policy">{item.middleText}</pre>
            </Text>
          )}
          {item.middleList &&
            item.middleList.map((text) => (
              <ul
                style={{
                  listStyleType: item.styledList ? 'disc' : 'none',
                  padding: '0 0 0 40px',
                }}
              >
                <li>{text.label}</li>
              </ul>
            ))}
          {item.deepSubContent &&
            item.deepSubContent.map((deepItem) => (
              <>
                <Title level={5}>{deepItem.title}</Title>
                <Text>
                  <pre className="text-site-policy">{deepItem.description}</pre>
                </Text>
                {deepItem.list &&
                  deepItem.list.map((text) => (
                    <ul
                      style={{
                        listStyleType: deepItem.styledList ? 'disc' : 'none',
                        padding: '0 0 0 40px',
                      }}
                    >
                      <li>{text.label}</li>
                    </ul>
                  ))}
                {deepItem.middleText && (
                  <Text>
                    <pre className="text-site-policy">
                      {deepItem.middleText}
                    </pre>
                  </Text>
                )}
                {deepItem.middleList &&
                  deepItem.middleList.map((text) => (
                    <ul
                      style={{
                        listStyleType: deepItem.styledList ? 'disc' : 'none',
                        padding: '0 0 0 40px',
                      }}
                    >
                      <li>{text.label}</li>
                    </ul>
                  ))}
                {deepItem.finalText && (
                  <Text>
                    <pre className="text-site-policy">{deepItem.finalText}</pre>
                  </Text>
                )}
              </>
            ))}
        </>
      ))}
    {data.list &&
      data.list.map((item) => (
        <ul
          style={{
            listStyleType: data.styledList ? 'disc' : 'none',
            padding: '0 0 0 40px',
          }}
        >
          <li>
            {item.label}
            {item.link && <a href={item.link}>{item.link}</a>}
          </li>
        </ul>
      ))}
    {data.middleText && (
      <Text>
        <pre className="text-site-policy">{data.middleText}</pre>
      </Text>
    )}
  </>
);

TextLayout.displayName = config.displayName;
TextLayout.defaultProps = config.defaultProps;
TextLayout.propTypes = config.propTypes;

export default TextLayout;
