import { SET_DEFAULT_REDUX_HEADER } from '../reduxType';

const initialState = {
  app: {
    name: 'BDD Invoicing System',
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_DEFAULT_REDUX_HEADER:
      return {
        ...state,
        app: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
