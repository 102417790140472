/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';

const displayName = 'AdminClient';
const propTypes = {};
const defaultProps = {
  dummClient: () => {
    const data = [];
    for (let index = 0; index < 10; ) {
      data.push({
        id: index,
        'client name': `Client ${index}`,
      });
      index += 1;
    }
    return data;
  },
};

//   dummClient: {
//     data: [
//       {
//         id: 1,
//         grup_produk: 1,
//         service_name: 'Layanan 1',
//         description:
//           'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio quo maiores porro. Iure mollitia consectetur quam veritatis voluptatibus officiis aspernatur aperiam aut. Consectetur perspiciatis odio quisquam sequi fugiat? Repellendus, eos.',
//         history: [
//           {
//             tanggal: '2022-08-01',
//             detail: 'perubahan nama layanan',
//             // editor: 'Hanny',
//           },
//           {
//             tanggal: '2022-08-03',
//             detail: 'perubahan nama layanan',
//             // editor: 'Hanny',
//           },
//           {
//             tanggal: '2022-08-05',
//             detail: 'perubahan nama layanan',
//             // editor: 'Hanny',
//           },
//         ],
//       },
//       {
//         id: 2,
//         grup_produk: 2,
//         service_name: 'Layanan 2',
//         description:
//           'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio quo maiores porro. Iure mollitia consectetur quam veritatis voluptatibus officiis aspernatur aperiam aut. Consectetur perspiciatis odio quisquam sequi fugiat? Repellendus, eos.',
//         history: [],
//       },
//       {
//         id: 3,
//         grup_produk: 4,
//         service_name: 'Layanan 3',
//         description:
//           'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio quo maiores porro. Iure mollitia consectetur quam veritatis voluptatibus officiis aspernatur aperiam aut. Consectetur perspiciatis odio quisquam sequi fugiat? Repellendus, eos.',
//         history: [],
//       },
//       {
//         id: 4,
//         grup_produk: 5,
//         service_name: 'Layanan 4',
//         description:
//           'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio quo maiores porro. Iure mollitia consectetur quam veritatis voluptatibus officiis aspernatur aperiam aut. Consectetur perspiciatis odio quisquam sequi fugiat? Repellendus, eos.',
//         history: [],
//       },
//       {
//         id: 5,
//         grup_produk: 6,
//         service_name: 'Layanan 5',
//         description:
//           'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio quo maiores porro. Iure mollitia consectetur quam veritatis voluptatibus officiis aspernatur aperiam aut. Consectetur perspiciatis odio quisquam sequi fugiat? Repellendus, eos.',
//       },
//     ],
//   },
// };
export default { displayName, propTypes, defaultProps };
