import {
  SET_INVOICE_LIST_MASTER,
  SET_INVOICE_LIST,
  SET_INVOICE_LIST_DASHBOARD,
  SET_INVOICE_DETAIL,
  SET_INVOICE_CHECKOUT,
} from '../reduxType';

const initialState = {
  dashboard: {
    satuan: {
      data: [],
      loading: false,
    },
    terjadwal: {
      data: [],
      loading: false,
    },
    jatuh_tempo: {
      data: [],
      loading: false,
    },
  },
  listMaster: {
    data: [],
    loading: false,
  },
  list: {
    data: [],
    loading: false,
  },
  detail: {
    data: {},
    loading: false,
  },
  checkout: {},
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_INVOICE_LIST_DASHBOARD:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...payload,
        },
      };
    case SET_INVOICE_LIST_MASTER:
      return {
        ...state,
        listMaster: {
          ...state.listMaster,
          ...payload,
        },
      };
    case SET_INVOICE_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case SET_INVOICE_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case SET_INVOICE_CHECKOUT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          ...payload,
        },
      };
    default:
      return state;
  }
};
