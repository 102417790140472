import React from 'react';
import moment from 'moment';
import {
  Layout,
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Modal,
  Popconfirm,
} from 'antd';

import { BDD_LOGO, BDD_LOGO_EXPAND } from '../../../assets';
import { getParamURL } from '../../../utils/options';
import { DataTable } from '../../../components';
import { formatNumber } from '../../../utils/string';
import config from './CheckoutPaid.component.config';
import './CheckoutPaid.component.styles.css';

const { Title, Text, Link } = Typography;

const idLocale = require('moment/locale/id');
moment().locale('id', idLocale);

const CheckoutPaid = ({
  invoiceData,
  getInvoiceForCheckout,
  updateInvoiceXendit,
}) => {
  const transactionID = getParamURL('trx');
  const [state, setState] = React.useState({
    collectData: true,
    transactionData: invoiceData,
    productList: [],
    totalData: {
      subtotal: 0,
      total_ppn: 0,
      total_pph: 0,
      total: 0,
    },
    loadingTransaction: false,
  });
  console.log(invoiceData);
  const collectDataInvoice = () => {
    console.log(transactionID);
    updateInvoiceXendit({ hash: transactionID }).then(() => {
      getInvoiceForCheckout(transactionID).then((reData) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.transactionData = reData;
          if (currentState.transactionData.product !== undefined) {
            currentState.productList = currentState.transactionData.product.map(
              (val) => ({
                ...val,
                existing: true,
                ppn_nominal:
                  val.ppn === 1
                    ? (val.qty * val.amount - val.discount) * 0.11
                    : 0,
                pph_nominal:
                  val.pph === 1
                    ? (val.qty * val.amount - val.discount) * 0.02
                    : 0,
              }),
            );
          }
          currentState.totalData.subtotal = currentState.productList?.reduce(
            (acc, obj) => acc + obj.amount,
            0,
          );
          currentState.totalData.total_ppn = currentState.productList?.reduce(
            (acc, obj) => acc + obj.ppn_nominal,
            0,
          );
          currentState.totalData.total_pph = currentState.productList?.reduce(
            (acc, obj) => acc + obj.pph_nominal,
            0,
          );
          currentState.totalData.total = currentState.productList?.reduce(
            (acc, obj) =>
              acc + obj.ppn_nominal - obj.pph_nominal + obj.sub_total,
            0,
          );
          currentState.collectData = false;
          currentState.loadingTransaction = false;
          return { ...currentState };
        });
      });
    });
  };
  console.log(state.loadingTransaction);
  React.useEffect(() => {
    if (state.collectData) {
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.loadingTransaction = true;
        return { ...currentState };
      });
      collectDataInvoice();
    }
  }, [state.transactionData, state.productList]);

  return (
    <div id="CheckoutPaid">
      <Card
        loading={state.loadingTransaction}
        title={
          state.loadingTransaction && <Title level={3}>Please wait...</Title>
        }
      >
        <div className="section-content">
          <div
            style={{ width: '60%', padding: 20, backgroundColor: '#FBE192' }}
          >
            <Title
              style={{
                fontWeight: 'bold',
                marginTop: 30,
              }}
            >
              TERIMAKASIH!
            </Title>
          </div>
          <div style={{ width: '40%', textAlign: 'right', padding: 25 }}>
            <Image src={BDD_LOGO_EXPAND} preview={false} height={60} />
            <Title level={5} style={{ marginTop: 10 }}>
              PT. BOLEH DICOBA DIGITAL
            </Title>
          </div>
        </div>
        <div className="section-content" style={{ backgroundColor: '#FFA96B' }}>
          <div style={{ width: '60%', padding: 20 }}>
            <Text style={{ fontSize: 16 }}>
              Bukti Pembayaran Untuk <br />
              <b>
                {state.transactionData?.person_in_charge},{' '}
                {state.transactionData?.brand_name},{' '}
                {state.transactionData?.address},{' '}
              </b>
            </Text>
            <br />
            {/* <Text>Tagihan Bulan 1</Text> */}
          </div>
          <div style={{ padding: 20 }}>
            <Text style={{ fontSize: 16 }}>
              Total Pembayaran <br />
              <b>Rp. {formatNumber(state.totalData.total, 2)}</b>
            </Text>
          </div>
        </div>
        <div className="section-content">
          <div style={{ width: '60%', padding: 20 }}>
            <Text>
              Tanggal <br />
              <b>
                {moment(state.transactionData?.updated_at).format(
                  'dddd, Do MMMM, YYYY',
                )}
              </b>
            </Text>
          </div>
          <div style={{ padding: 20 }}>
            <Text>
              Invoice ID
              <br />
              <b>{state.transactionData?.transaction?.invoice_number}</b>
            </Text>
          </div>
        </div>
        <div
          className="section-content"
          style={{ width: '100%', padding: 20, alignItems: 'end' }}
        >
          <Text>
            <b>Informasi Bantuan</b> <br />
            Untuk Informasi Bantuan lainnya, silakan hubungi ke email{' '}
            <a href="_blank">finance@bolehdicoba.com</a>.
          </Text>
          <Text
            onClick={() => window.open(`${window.location.origin}/site-policy`)}
            style={{ cursor: 'pointer', color: '#fa530a' }}
            className="site-policy"
          >
            Site Policy
          </Text>
        </div>
      </Card>
    </div>
  );
};

CheckoutPaid.displayName = config.displayName;
CheckoutPaid.defaultProps = config.defaultProps;
CheckoutPaid.propTypes = config.propTypes;

export default CheckoutPaid;
