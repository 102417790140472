import {
  SET_PRODUCT_LIST,
  SET_PRODUCT_GROUP,
  SET_PRODUCT_GROUP_LIST,
  SET_PRODUCT_DETAIL,
} from '../reduxType';

const initialState = {
  list: {
    data: [],
    loading: false,
  },
  grouplist: {
    data: [],
    loading: false,
  },
  group: {
    data: [],
    loading: false,
  },
  detail: {
    data: {},
    loading: false,
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_PRODUCT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case SET_PRODUCT_GROUP:
      return {
        ...state,
        group: {
          ...state.group,
          ...payload,
        },
      };
    case SET_PRODUCT_GROUP_LIST:
      return {
        ...state,
        grouplist: {
          ...state.grouplist,
          ...payload,
        },
      };
    case SET_PRODUCT_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    default:
      return state;
  }
};
