/* eslint-disable prettier/prettier */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import config from './RefundPolicy.component.config';
import TextLayout from '../../../components/TextLayout/Textlayout.component';
import {
  SITEPOLICY_DATA_EN,
  SITEPOLICY_DATA_ID,
  SITEPOLICY_LINK_EN,
  SITEPOLICY_LINK_ID,
} from '../../../sitePolicyData';

const { Title, Text } = Typography;

const RefundPolicy = ({ lang, activePage }) => {
  const navigate = useNavigate();
  return (
    <div style={{ display: 'flex', padding: '30px 30px 30px 12vw' }}>
      <div
        className="table-of-content"
        style={{
          width: '15%',
        }}
      >
        <div className="sticky-toc">
          <h4>Contents</h4>
          <nav>
            <ul style={{ listStyleType: 'none', padding: '6px 6px 0 0' }}>
              {lang
                ? SITEPOLICY_LINK_ID.map((data) => (
                  <li style={{ marginBottom: 10, cursor: 'pointer' }}>
                    <Text
                      strong={activePage === data.scrollTo}
                      style={{color: activePage === data.scrollTo ? 'black':'grey'}}
                      onClick={() => {
                        navigate(`/site-policy/${data.scrollTo}`);
                      }}
                    >
                      {data.title}
                    </Text>
                  </li>
                ))
                : SITEPOLICY_LINK_EN.map((data) => (
                  <li style={{ marginBottom: 10, cursor: 'pointer' }}>
                    <Text
                      strong={activePage === data.scrollTo}
                      style={{color: activePage === data.scrollTo ? 'black':'grey'}}
                      onClick={() => {
                        navigate(`/site-policy/${data.scrollTo}`);
                      }}
                    >
                      {data.title}
                    </Text>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
      <div
        className="content"
        style={{
          width: '85%',
          padding: '30px 15vw 30px 4vw',
        }}
      >
        {lang ? SITEPOLICY_DATA_ID[2].sections.map((item) => (
          <TextLayout data={item} />
        )) : SITEPOLICY_DATA_EN[2].sections.map((item) => (
          <TextLayout data={item} />
        ))}
      </div>
    </div>
  );
};

RefundPolicy.displayName = config.displayName;
RefundPolicy.defaultProps = config.defaultProps;
RefundPolicy.propTypes = config.propTypes;

export default RefundPolicy;
