/* eslint-disable indent */
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Image, Layout, Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BDD_LOGO } from '../../assets';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.component';
import RefundPolicy from './RefundPolicy/RefundPolicy.component';
import config from './SitePolicy.component.config';
import './SitePolicy.component.styles.css';
import TermsOfService from './TermsOfService/TermsOfService.component';

const { Content, Footer } = Layout;
const { Title, Text } = Typography;
const items = [
  {
    key: '1',
    label: 'Bahasa Indonesia',
  },
  {
    key: '2',
    label: 'English',
  },
];

const SitePolicy = ({ ...props }) => {
  const navigate = useNavigate();
  const rawUrl = window.location.href;
  const [language, setLanguage] = useState('ID');
  const [activePage, setActivePage] = useState('terms-of-service');

  const renderContent = () => {
    let textContent = null;

    switch (activePage) {
      case 'privacy-policy':
        textContent = <PrivacyPolicy lang={language} activePage={activePage} />;
        break;
      case 'refund-policy':
        textContent = <RefundPolicy lang={language} activePage={activePage} />;
        break;
      default:
        textContent = (
          <TermsOfService lang={language} activePage={activePage} />
        );
        break;
    }

    return textContent;
  };

  useEffect(() => {
    const pageActive = rawUrl.substring(rawUrl.lastIndexOf('/') + 1);
    setActivePage(pageActive);
  }, [rawUrl]);

  return (
    <Layout>
      <Layout.Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 50,
          paddingBottom: 50,
          backgroundColor: 'white',
        }}
      >
        <div
          className="headline-menu"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image preview={false} src={BDD_LOGO} />
          <Title level={4} hidden={false}>
            BDD <br />
            Web Invoicing
          </Title>
        </div>
        <Space direction="horizontal">
          <Text>{language ? 'Ubah Bahasa: ' : 'Change Language: '}</Text>
          <Select
            labelInValue
            defaultValue={{
              value: true,
              label: 'Bahasa Indonensia',
            }}
            style={{ width: '100%' }}
            onChange={(value) => setLanguage(value.value)}
            options={[
              {
                value: true,
                label: 'Bahasa Indonesia',
              },
              {
                value: false,
                label: 'English',
              },
            ]}
          />
        </Space>
      </Layout.Header>
      {renderContent()}
    </Layout>
  );
};

SitePolicy.displayName = config.displayName;
SitePolicy.defaultProps = config.defaultProps;
SitePolicy.propTypes = config.propTypes;

export default SitePolicy;
