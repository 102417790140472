/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Input,
  InputNumber,
  Form,
  Row,
  Col,
  Select,
  Checkbox,
  Space,
  Radio,
  List,
  Popconfirm,
  Upload,
  Dropdown,
  Menu,
  message,
  notification,
  Collapse,
  TreeSelect,
} from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
  DownOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {
  generateS3UploadUrl,
  executeS3UploadUrl,
} from '../../../services/aws.service';

import { getParamURL } from '../../../utils/options';

import config from './DetailInvoiceDraft.component.config';
import './DetailInvoiceDraft.component.styles.css';
import { formatNumber } from '../../../utils/string';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const DetailInvoiceDraft = ({
  productListGroupData,
  productListGroup,
  productListData,
  productList,
  setProductDetail,
  clientListData,
  clientList,
  setClientDetail,
  invoiceCreate,
  invoiceData,
  invoiceUpdate,
  invoiceDetail,
  updateConditionInvoice,
  setInvoiceDetail,
  ...props
}) => {
  const invoiceID = getParamURL('invoiceID');
  const detailData = invoiceData?.data;
  const refInvoice = useRef();
  const productListArr = productListData?.data;
  const clientListArr = clientListData?.data;
  // detailData.transaction.length - 1
  const statusTransaction =
    detailData.transaction?.length > 0
      ? detailData.transaction[0].payment_status
      : detailData.status;
  const [klienOptions, setKlienOptions] = useState([]);
  const [clientCodeOptions, setClientCodeOptions] = useState([]);

  // STATE NAVIGATION
  const navigate = useNavigate();
  const [state, setState] = useState({
    needMounting: true,
    showEmpty: true,
    renderListProduct: true,
    renderListClient: true,
    renderFormInvoice: false,
    renderDetailInvoiceDraft: true,
    renderInvoicePreview: false,
    fileList: [],
    signatureList: [],
    invoiceType: 0,
  });
  const [stateTotal, setStateTotal] = React.useState({
    loadProgressDone: true,
    total_ppn: 0,
    total_pph: 0,
    total: 0,
    total_amount: 0,
  });
  const [stateDaftarLayanan, setStateDaftarLayanan] = React.useState({
    loadProgressDone: true,
    list_data: [],
  });

  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      switch (nav.type) {
        case 'editInvoice':
          currentState.renderFormInvoice = !currentState.renderFormInvoice;
          currentState.dataDetailInvoiceDraft = nav?.data;
          break;
        case 'invoicePreview':
          currentState.renderInvoicePreview =
            !currentState.renderInvoicePreview;
          currentState.dataDetailInvoiceDraft = nav?.data;
          break;
        case 'showModalSend':
          currentState.renderFormInvoicePreview =
            !currentState.renderFormInvoicePreview;
          currentState.dataDetailInvoiceDraft = nav?.data;
          break;
        case 'showModalDraft':
          currentState.showModalCreate = false;
          break;
        default:
          currentState.renderListInvoice = true;
          currentState.renderDetailInvoiceDraft = false;
          currentState.renderFormInvoice = false;
          currentState.renderFormClient = false;
          currentState.renderInvoicePreview = false;
          break;
      }
      return { ...currentState };
    });
  };
  // section header invoice
  const renderInvoiceIdentity = () => (
    <div className="inv-identity">
      <Row gutter={24}>
        <Col md={12}>
          <Form.Item
            label="Nomor Invoice"
            name="invoice_number"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="text" placeholder="input here ..." />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Tanggal Pembuatan Invoice"
            name="scheduled_date"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>
        <Col md={24}>
          <Form.Item
            label="Nama Project"
            name="project_name"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="text" placeholder="input here ..." />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  const renderClientDetail = () => (
    <div className="inv-client">
      <Row gutter={24}>
        <Col md={12}>
          <Form.Item
            label="Kode Klien"
            name="client_code"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Nama Klien"
            name="brand_name"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Nama Kontak Klien"
            name="person_in_charge"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="text" placeholder="input here ..." />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Nomor Kontak Klien"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="input here ..."
              onKeyPress={(e) => !/[0-9+]/.test(e.key) && e.preventDefault()}
            />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Email Kontak Klien"
            name="primary_email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Dont make me empty :(',
              },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
          >
            <Input type="text" placeholder="input here ..." />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="CC Email Kontak Klien" name="emailcc">
            <TextArea
              type="text"
              placeholder="Pisahkan email dengan tanda koma (,)"
            />
          </Form.Item>
        </Col>
        {/* <Col md={12}></Col> */}
        <Col md={12}>
          <Form.Item
            label="Alamat Klien"
            name="address"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <TextArea type="text" placeholder="input here ..." />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  // section product listing
  const onChangeInputValueLayanan = (indexLayanan, typeValue, value) => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      const findExistLayanan = currentLayanan.list_data.find(
        (layanan, index) => index === indexLayanan,
      );
      if (findExistLayanan !== undefined) {
        switch (typeValue) {
          case 'product':
            findExistLayanan.service_id = value;
            break;
          case 'quantity':
            findExistLayanan.qty = value;
            // update sub quantity
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            break;
          case 'amount':
            findExistLayanan.amount = value;
            // update sub total
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'discount':
            findExistLayanan.discount = value;
            // update discount
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'ppn':
            // update ppn
            // value ? 1 : 0; validasi jika value nya TRUE kasi nilai 1
            findExistLayanan.ppn = value ? 1 : 0;
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'pph':
            // update pph
            // value ? 1 : 0; validasi jika value nya TRUE kasi nilai 1
            findExistLayanan.pph = value ? 1 : 0;
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          default:
            break;
        }
      }
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });
  };
  const tambahLayanan = () => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.list_data = [
        ...currentLayanan.list_data,
        {
          service_id: 0,
          qty: 0,
          amount: 0,
          discount: 0,
          ppn: 0,
          pph: 0,
          sub_total: 0,
          ppn_nominal: 0,
          pph_nominal: 0,
        },
      ];
      return { ...currentLayanan };
    });
  };
  const hapusLayanan = (indexLayanan) => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.loadProgressDone = false;
      const layananBaru = currentLayanan.list_data.filter(
        (items, index) => index !== indexLayanan,
      );
      currentLayanan.list_data = layananBaru;
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });
    setTimeout(() => {
      setStateDaftarLayanan((prevLayanan) => {
        const currentLayanan = { ...prevLayanan };
        currentLayanan.loadProgressDone = true;
        return { ...currentLayanan };
      });
    }, 500);
  };

  const calculateTotalAmount = (returnData) => {
    setStateTotal({
      ...stateTotal,
      /* eslint no-return-assign: "error" */
      total_ppn: returnData.reduce((acc, obj) => acc + obj.ppn_nominal, 0),
      total_pph: returnData.reduce((acc, obj) => acc + obj.pph_nominal, 0),
      total_amount: returnData.reduce((acc, obj) => acc + obj.sub_total, 0),
      total: returnData.reduce(
        (acc, obj) => acc + obj.ppn_nominal - obj.pph_nominal + obj.sub_total,
        0,
      ),
    });
  };
  const renderInvoiceDetail = () => (
    <div className="inv-detail">
      <div className="content-inv-detail">
        <div className="header">
          <Row gutter={21}>
            <Col md={1} className="actions-"></Col>
            <Col md={5}>
              <Text level={5}>Nama Layanan</Text>
            </Col>
            <Col md={2}>
              <Text level={5}>Qty</Text>
            </Col>
            <Col md={5}>
              <Text level={5}>Harga Satuan</Text>
            </Col>
            <Col md={5}>
              <Text level={5}>Diskon</Text>
            </Col>
            <Col md={3}>
              <Text level={5}>Subtotal</Text>
            </Col>
            <Col md={2} className="ppn-pph">
              <Text level={5}>PPN</Text>
            </Col>
            <Col md={2} className="ppn-pph">
              <Text level={5}>PPH 23</Text>
            </Col>
          </Row>
        </div>
        <div className="body-layanan">
          {stateDaftarLayanan.loadProgressDone &&
            stateDaftarLayanan.list_data.map((itemLayanan, indexLayanan) => (
              <div className="content-field-layanan">
                <Row gutter={21}>
                  <Col md={1} className="actions-">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<MinusOutlined />}
                      onClick={() => hapusLayanan(indexLayanan)}
                    />
                  </Col>
                  <Col md={5}>
                    {/* product */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Pilih Produk',
                        },
                      ]}
                    >
                      <TreeSelect
                        style={{
                          width: '100%',
                        }}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: 'auto',
                        }}
                        showSearch
                        treeDefaultExpandAll
                        filterTreeNode={(input, option) =>
                          (option?.title ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        treeData={productListGroupData.data}
                        onChange={(val) =>
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'product',
                            val,
                          )
                        }
                        defaultValue={
                          itemLayanan.service_id !== 0
                            ? itemLayanan.service_id
                            : null
                        }
                      />
                      {/* <Select
                        placeholder="choose..."
                        defaultValue={
                          itemLayanan.service_id !== 0
                            ? itemLayanan.service_id
                            : null
                        }
                        onChange={(val) =>
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'product',
                            val,
                          )
                        }
                      >
                        {productListArr.map((option) => (
                          <option value={option.id}>
                            {option.service_name}
                          </option>
                        ))}
                      </Select> */}
                    </Form.Item>
                  </Col>
                  <Col md={2}>
                    {/* qty */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Quantity',
                        },
                        {
                          min: 1,
                          message: 'quantity tidak boleh kurang dari 1',
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="input quantity..."
                        defaultValue={
                          itemLayanan.qty !== 0 ? itemLayanan.qty : null
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'quantity',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5}>
                    {/* harga satuan */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Amount',
                        },
                        {
                          min: 1,
                          message: 'harga tidak boleh 0',
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        prefix="Rp. "
                        placeholder="input harga..."
                        defaultValue={
                          itemLayanan.amount !== 0 ? itemLayanan.amount : null
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'amount',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5}>
                    {/* discount */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Discount',
                        },
                      ]}
                    >
                      <InputNumber
                        defaultValue={
                          itemLayanan.discount !== 0
                            ? itemLayanan.discount
                            : null
                        }
                        controls={false}
                        placeholder="input diskon ..."
                        prefix="Rp. "
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'discount',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    {/* sub total */}
                    <Text>Rp. {formatNumber(itemLayanan.sub_total)}</Text>
                  </Col>
                  <Col md={2} className="ppn-pph">
                    {/* ppn */}
                    <Form.Item>
                      <Checkbox
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        defaultChecked={
                          itemLayanan.ppn !== 0 ? 'checked' : false
                        }
                        onClick={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'ppn',
                            Number(val.target.checked),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} className="ppn-pph">
                    {/* pph */}
                    <Form.Item>
                      <Checkbox
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        defaultChecked={
                          itemLayanan.pph !== 0 ? 'checked' : false
                        }
                        onClick={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'pph',
                            Number(val.target.checked),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      </div>
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => tambahLayanan()}
      >
        Tambah Layanan
      </Button>
    </div>
  );

  // Validate Attachments File Size
  const beforeUploadAttachment = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('File must smaller than 10MB!');
    } else message.success('File has been uploaded!');
    return isLt10M;
  };
  // Handle Upload Attachment
  const actionUpload = (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
      status: 'done',
    };
    generateS3UploadUrl('invoice/attachment', upload?.file).then((url) => {
      executeS3UploadUrl(url, upload?.file).then((imageUrl) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.fileList = [...currentState.fileList];
          currentState.fileList.push({
            url: imageUrl,
            ...tempData,
            status: imageUrl ? 'done' : 'error',
          });
          return { ...currentState };
        });
      });
    });
  };
  const handleDeleteAttachment = (data) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.fileList = currentState.fileList.filter(
        (items) => items.url !== data.url,
      );
      return { ...currentState };
    });
  };

  // Case Terjadwal
  const onChangeTipeInvoice = (e) => {
    setState((prevState) => {
      const currenState = { ...prevState };
      currenState.invoiceType = e.target.value;
      return { ...currenState };
    });
  };
  const renderInvoiceTerjadwal = () => (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Bulan 1" key="1">
        <Form.Item label="Catatan" name="notes">
          <TextArea
            name="notes"
            type="text"
            placeholder="Isi catatan disini..."
          />
        </Form.Item>
        <div className="inv-attachments">
          <Form.Item label="Lampiran" name="attachment">
            <Upload
              fileList={state.fileList}
              customRequest={actionUpload}
              beforeUpload={beforeUploadAttachment}
              onRemove={(data) => handleDeleteAttachment(data)}
            >
              {state.fileList.length !== 10 && (
                <Button icon={<UploadOutlined />}>Upload (Max: 10)</Button>
              )}
            </Upload>
          </Form.Item>
        </div>
      </Panel>
    </Collapse>
  );
  const renderSelectionTypeInvoice = () => (
    <div className="selection-type-invoice">
      <Row gutter={24}>
        <Col md={18}>
          <Form.Item
            name="is_recurring"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
            initialValue={state.invoiceType}
          >
            <Radio.Group
              onChange={onChangeTipeInvoice}
              defaultValue={state.invoiceType}
            >
              <Space direction="vertical">
                <Radio value={0}>Invoice Satuan</Radio>
                <Radio value={1}>
                  <Text>
                    Invoice Terjadwal <br />
                    <span style={{ color: 'grey' }}>
                      Invoice terjadwal akan diset pengingat di setiap tanggal
                      yang sama dengan jadwal pengiriman di setiap bulannya
                    </span>
                  </Text>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name="due_date_count"
            label="Jatuh Tempo Setelah"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
            initialValue={7}
          >
            <Input
              type="text"
              addonAfter="Hari"
              onKeyUp={(e) => {
                if (e.target.value !== '' && e.target.value <= 0) {
                  refInvoice.current.setFieldsValue({
                    due_date_count: 7,
                  });
                  notification.warning({
                    message: 'Jatuh tempo tidak boleh 0, Minimal 1',
                  });
                }
              }}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            />
          </Form.Item>
          {state.invoiceType === 1 && (
            <Form.Item
              name="recurring_count"
              label="Ingatkan Selama"
              rules={[
                {
                  required: true,
                  message: 'Dont make me empty :(',
                },
              ]}
              initialValue={1}
            >
              <Input
                type="text"
                addonAfter="Bulan"
                onKeyUp={(e) => {
                  if (e.target.value !== '' && e.target.value <= 0) {
                    refInvoice.current.setFieldsValue({
                      recurring_count: 1,
                    });
                    notification.warning({
                      message: 'Ingatkan selama tidak boleh 0, Minimal 1',
                    });
                  }
                }}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      {state.invoiceType === 1 ? (
        <Col flex="auto">{renderInvoiceTerjadwal()}</Col>
      ) : (
        <>
          <Col md={24}>
            <Form.Item label="Catatan" name="notes">
              <TextArea
                name="notes"
                type="text"
                placeholder="Isi catatan disini..."
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <div className="inv-attachments">
              <Form.Item label="Lampiran" name="attachment">
                <Upload
                  fileList={state.fileList}
                  customRequest={actionUpload}
                  beforeUpload={beforeUploadAttachment}
                  onRemove={(data) => handleDeleteAttachment(data)}
                >
                  {state.fileList.length !== 10 && (
                    <Button icon={<UploadOutlined />}>Upload (Max: 10)</Button>
                  )}
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </>
      )}
    </div>
  );

  // Hitung Total
  const renderOverviewInvoice = () => (
    <div className="inv-overview">
      <List.Item className="item-overview">
        <Text>Subtotal:</Text>
        <Text> Rp. {formatNumber(stateTotal.total_amount)} </Text>
      </List.Item>
      <List.Item className="item-overview">
        <Text>PPN:</Text>
        <Text> Rp. {formatNumber(stateTotal.total_ppn)} </Text>
      </List.Item>
      <List.Item className="item-overview">
        <Text>PPh 23:</Text>
        <Text> Rp. {formatNumber(stateTotal.total_pph)} </Text>
      </List.Item>
      <List.Item className="item-overview total">
        <Text>
          <b>Total Ditagihkan:</b>
        </Text>
        <Text>
          <b>Rp. {formatNumber(stateTotal.total)}</b>
        </Text>
      </List.Item>
      <Text>
        Proses Pembayaran melalui BCA Virtual Account, nomor
        <br></br>
        Virtual Account akan diatur otomatis di halaman berikutnya
        <br></br>
        <br></br>
      </Text>
    </div>
  );

  // Handle Upload Signature
  const actionUploadSignature = (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
      status: 'done',
    };
    generateS3UploadUrl('invoice/signature', upload?.file).then((url) => {
      executeS3UploadUrl(url, upload?.file).then((imageUrl) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.signatureList = [
            {
              url: imageUrl,
              ...tempData,
            },
          ];
          return { ...currentState };
        });
      });
    });
  };
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
    } else message.success('Signature has been uploaded!');
    return isJpgOrPng && isLt10M;
  };

  const renderSignature = () => (
    <Row gutter={24}>
      <Col md={12}>
        <div className="inv-signature">
          <Form.Item
            name="signature"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Upload
                name="files"
                customRequest={actionUploadSignature}
                beforeUpload={beforeUpload}
                fileList={state.signatureList}
                listType="picture"
                accept=".png, .jpeg, .jpg"
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: false,
                }}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Ganti Tandatangan</Button>
              </Upload>
            </Space>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="approver"
            label="Approver"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </div>
      </Col>
    </Row>
  );

  const mountingFirstData = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setInvoiceDetail({ id: invoiceID });
    productList();
    productListGroup();
    clientList().then((data) => {
      // get client list when rendering component or page
      setKlienOptions(
        data.map((el, idx) => ({
          key: idx,
          value: el.brand_name,
          data: el,
        })),
      );
      setClientCodeOptions(
        data.map((el, idx) => ({
          key: idx,
          value: el.client_code,
          data: el,
        })),
      );
    });

    setState((prevState) => {
      const currenState = { ...prevState };
      // // CONVERTING ATTACHMENT WHEN INIT
      const transactionData = detailData?.transaction;
      let isAttachTransaction = null;
      if (transactionData.length !== 0) {
        if (detailData.is_recurring === 1) {
          isAttachTransaction =
            transactionData[transactionData.length - 1]?.attachment;
        } else {
          isAttachTransaction = transactionData[0]?.attachment;
        }

        if (isAttachTransaction !== '') {
          const splitAttachment = isAttachTransaction.split(';');
          const tempAttachment = [];
          splitAttachment.forEach((items) => {
            tempAttachment.push({
              uid: items,
              name: items,
              url: items,
              status: 'done',
            });
          });
          currenState.fileList = tempAttachment;
        }
      }

      // converting signature source
      if (detailData.signature !== '' && detailData.signature !== null) {
        currenState.signatureList = [
          {
            uid: detailData.signature,
            name: detailData.signature,
            url: detailData.signature,
            status: 'done',
          },
        ];
      }

      if (Number(detailData.is_recurring) === 1) {
        currenState.invoiceType = Number(detailData.is_recurring);
      }

      return { ...currenState };
    });
    // INIT CALCULATED DATA PRODUCT - TOTAL AMOUNT
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.list_data = detailData.product.map((val) => ({
        ...val,
        ppn_nominal:
          val.ppn === 1 ? (val.qty * val.amount - val.discount) * 0.11 : 0,
        pph_nominal:
          val.pph === 1 ? (val.qty * val.amount - val.discount) * 0.02 : 0,
      }));
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });

    // INIT DATA IDENTITY, CLIENT, TYPE, OVERVIEW, SIGNATURE
    let fieldsExisting = {
      scheduled_date: detailData?.scheduled_date,
      project_name: detailData?.project_name,
      due_date_count: detailData?.due_date_count,
      notes: detailData?.notes,
      is_recurring: detailData?.is_recurring,
      recurring_count: detailData?.recurring_count,
      client_id: detailData?.client_id,
      client_code: detailData?.client_code,
      brand_name: detailData?.brand_name,
      person_in_charge: detailData?.person_in_charge,
      phone: detailData?.phone,
      primary_email: detailData?.primary_email,
      emailcc: detailData?.emailcc,
      address: detailData?.address,
      signature: detailData?.signature,
      approver: detailData?.approver,
    };

    if (detailData.transaction.length > 0) {
      fieldsExisting = {
        invoice_number: detailData?.transaction[0].invoice_number,
        ...fieldsExisting,
      };
    }
    refInvoice.current.setFieldsValue(fieldsExisting);

    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };

  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  }, [state.needMounting]);
  // CREATE INVOICE
  const readRulesDaftarLayanan = () => {
    let readyToCreate = false;
    if (stateDaftarLayanan.list_data.length === 0) {
      notification.warning({
        message:
          'Anda belum menambahkan produk untuk invoice ini, silahkan untuk menambahkan produk terlebih dahulu',
      });
      readyToCreate = false;
    } else {
      stateDaftarLayanan.list_data.forEach((items) => {
        if (items.service_id === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi belum ada produk yang di pilih',
          });
          readyToCreate = false;
        } else if (items.service_id !== 0 && items.qty === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi quatity produk belum ditambahkan',
          });
          readyToCreate = false;
        } else if (items.service_id !== 0 && items.amount === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi harga satuan produk belum ditambahkan',
          });
          readyToCreate = false;
        } else {
          readyToCreate = true;
        }
      });
    }

    if (state.signatureList[0] === undefined) {
      notification.warning({
        message:
          'Anda belum menambahkan tanda tangan untuk invoice ini, silahkan untuk menambahkan signature terlebih dahulu',
      });
      readyToCreate = false;
    } else {
      readyToCreate = true;
    }

    return readyToCreate;
  };

  const updateInvoice = (isForward) => {
    const readyToCreate = readRulesDaftarLayanan();
    const getField = refInvoice.current.getFieldsValue();
    const findClientID = clientListArr.find(
      (items) => items.client_code === getField.client_code,
    );

    if (readyToCreate) {
      const requestBody = {
        ...getField,
        id: invoiceID === undefined ? 0 : invoiceID,
        invoice_id: invoiceID === undefined ? 0 : invoiceID,
        invoice_number: getField.invoice_number,
        scheduled_date: getField.scheduled_date,
        project_name: getField.project_name,
        client_id: findClientID === undefined ? 0 : findClientID.id,
        client_code: getField.client_code,
        brand_name: getField.brand_name,
        person_in_charge: getField.person_in_charge,
        phone: getField.phone,
        primary_email: getField.primary_email,
        emailcc: getField.emailcc,
        address: getField.address,
        virtual_account:
          findClientID === undefined ? '' : findClientID.virtual_account,
        product: stateDaftarLayanan.list_data,
        is_recurring: getField.is_recurring,
        due_date_count: getField.due_date_count,
        recurring_count:
          getField.recurring_count === undefined ? 0 : getField.recurring_count,
        total_amount: stateTotal.total,
        notes: getField.notes === undefined ? '-' : getField.notes,
        attachment: state.fileList.map((u) => u.url).join(';'),
        signature:
          state.signatureList[0].url === undefined
            ? ''
            : state.signatureList[0].url,
        approver: getField.approver,
      };
      invoiceUpdate(requestBody).then((resp) => {
        if (isForward) {
          // condition when click button simpan dan kirim, akan di forward ke halaman send
          setInvoiceDetail({ id: invoiceID }).then(() => {
            navigate(`/admin/invoice/send?invoiceID=${invoiceID}`);
          });
        } else {
          navigate(`/admin/invoice`);
        }
        refInvoice.current.setFieldsValue({
          invoice_number: null,
          scheduled_date: new Date(),
          project_name: null,
          client_code: null,
          brand_name: null,
          person_in_charge: null,
          phone: null,
          primary_email: null,
          emailcc: null,
          address: null,
          is_recurring: null,
          due_date_count: 7,
          recurring_count: 1,
          notes: null,
          attachment: null,
          signature: null,
          approver: null,
        });
      });
    }
  };

  // handle Menu
  const handleMenuClick = (e) => {
    if (e !== undefined) {
      let statusDef = 'draft';
      switch (e.key) {
        // case '1':
        //   message.info('Your invoice has been Duplicated!');
        //   break;
        case '2':
          // message.info('Your invoice has been Marked as Void!');
          statusDef = 'void';
          break;
        case '3':
          // message.info('Your invoice has been Removed!');
          statusDef = 'remove';
          break;
        default:
          // message.info('Applied');
          statusDef = 'draft';
          break;
      }
      updateConditionInvoice({
        id: detailData.transaction[0].id,
        status_condition: statusDef,
      }).then(() => {
        setInvoiceDetail({ id: invoiceID }).then(() => {
          navigate(`/admin/invoice/draft?invoiceID=${invoiceID}`);
        });
      });
    }
  };
  const menuDropDown = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Mark as Draft',
          key: '1',
          icon: <PlusSquareOutlined />,
          disabled: detailData.transaction[0].payment_status_id === 0,
        },
        {
          label: 'Mark as Void',
          key: '2',
          icon: <CloseCircleOutlined />,
          disabled: detailData.transaction[0].payment_status_id === 3,
        },
        {
          label: 'Remove',
          key: '3',
          icon: <DeleteOutlined />,
          disabled: detailData.transaction[0].payment_status_id === 0,
        },
      ]}
    />
  );

  return (
    <div id="DetailInvoiceDraft">
      <Row gutter={24}>
        <Col flex="auto">
          <Title level={2}>
            <b>Draft Invoice</b>
          </Title>
        </Col>
        <Col flex="auto">
          <div className="button-action-draft" style={{ float: 'right' }}>
            <Dropdown overlay={menuDropDown}>
              <Button
                htmlType="submit"
                onClick={() =>
                  navigationContainer({ type: 'editInvoice', data: detailData })
                }
              >
                {statusTransaction}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Form
        ref={refInvoice}
        layout="vertical"
        name="invoice"
        onFinish={() => updateInvoice(false)}
      >
        <div className="section-field">{renderInvoiceIdentity()}</div>
        <div className="section-field">
          <Title level={3}>Data Klien</Title>
          {renderClientDetail()}
        </div>
        <div className="section-field">
          <Title level={3}>Detail Invoice</Title>
          <Title level={5}>Daftar Layanan</Title>
          {renderInvoiceDetail()}
        </div>
        <div className="section-field">
          <Title level={5}>Tipe Invoice</Title>
          {renderSelectionTypeInvoice()}
        </div>
        <div className="section-field">{renderOverviewInvoice()}</div>
        <div className="section-field">
          <Title level={5}>Signature</Title>
          {renderSignature()}
        </div>
        <div className="button-action section-field">
          <Popconfirm
            placement="top"
            title="Pastikan Data Invoice sudah benar dan Tidak Ada Yang Terlewat"
            onConfirm={() => updateInvoice(true)}
            okText="Simpan dan Kirim Invoice"
            cancelText="Cek Sekali Lagi"
          >
            <Button type="primary">Kirim Invoice</Button>
          </Popconfirm>
          <Button htmlType="submit" type="default">
            Simpan Sebagai Draft
          </Button>
        </div>
      </Form>
    </div>
  );
};

DetailInvoiceDraft.displayName = config.displayName;
DetailInvoiceDraft.defaultProps = config.defaultProps;
DetailInvoiceDraft.propTypes = config.propTypes;

export default DetailInvoiceDraft;
