import { connect } from 'react-redux';
import Admin from './Admin.component';
import { fConsumeToken } from '../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  name: state.header,
});

const mapDispatchToProps = {
  fConsumeToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
