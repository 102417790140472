import React from 'react';
import { Image } from 'antd';
import {
  HomeFilled,
  UsergroupAddOutlined,
  WalletOutlined,
  DatabaseFilled,
  SettingOutlined,
} from '@ant-design/icons';
import {
  ICON_CLIENT,
  ICON_DASHBOARD,
  ICON_INVOICE,
  ICON_PRODUCT,
} from '../assets';

import path from './path';
import AdminDashboardContainer from '../containers/Admin/AdminDashboard/AdminDashboard.container';
import AdminInvoiceContainer from '../containers/Admin/AdminInvoice/AdminInvoice.container';
import DetailInvoiceOpenContainer from '../containers/Admin/DetailInvoiceOpen/DetailInvoiceOpen.container';
import DetailInvoiceDraftContainer from '../containers/Admin/DetailInvoiceDraft/DetailInvoiceDraft.container';
import DetailInvoiceSendContainer from '../containers/Admin/DetailInvoiceSend/DetailInvoiceSend.container';
import AdminInvoiceFormContainer from '../containers/Admin/AdminInvoiceForm/AdminInvoiceForm.container';
import AdminClientContainer from '../containers/Admin/AdminClient/AdminClient.container';
import DetailClientContainer from '../containers/Admin/DetailClient/DetailClient.container';
import AdminProductContainer from '../containers/Admin/AdminProduct/AdminProduct.container';
import DetailProductContainer from '../containers/Admin/DetailProduct/DetailProduct.container';
import AdminAccountContainer from '../containers/Admin/AdminAccount/AdminAccount.container';
import SitePolicy from '../containers/SitePolicy/SitePolicy.container';

export const ADMIN_ROUTE = [
  {
    key: `/admin/${path.ADMIN_PATH.dashboard}`,
    label: 'Dashboard',
    icon: <ICON_DASHBOARD />,
    path: path.ADMIN_PATH.dashboard,
    element: <AdminDashboardContainer />,
    ismenu: 'yes',
    forUser: false,
  },
  {
    key: `/admin/${path.ADMIN_PATH.invoice}`,
    label: 'List Invoice',
    icon: <ICON_INVOICE />,
    path: path.ADMIN_PATH.invoice,
    element: <AdminInvoiceContainer />,
    ismenu: 'yes',
    forUser: false,
  },
  // {
  //   key: `List Invoice`,
  //   label: 'List Invoice',
  //   icon: <ICON_INVOICE />,
  //   path: path.ADMIN_PATH.invoice,
  //   element: <AdminInvoiceContainer />,
  //   ismenu: 'yes',
  //   forUser: false,
  //   children: [
  //     {
  //       key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.transaksi}`,
  //       label: 'List Transaksi',
  //       path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.transaksi}`,
  //       element: <AdminInvoiceContainer />,
  //       ismenu: 'yes',
  //       forUser: false,
  //     },
  //     {
  //       key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.masterdata}`,
  //       label: 'List Master Data',
  //       path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.masterdata}`,
  //       element: <AdminInvoiceContainer />,
  //       ismenu: 'yes',
  //       forUser: false,
  //     },
  //   ],
  // },
  {
    key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.draft}`,
    label: 'Invoice Draft',
    path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.draft}`,
    element: <DetailInvoiceDraftContainer />,
    ismenu: false,
  },
  {
    key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.send}`,
    label: 'Invoice Send',
    path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.send}`,
    element: <DetailInvoiceSendContainer />,
    ismenu: false,
  },
  {
    key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.detail}`,
    label: 'Invoice Detail',
    path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.detail}`,
    element: <DetailInvoiceOpenContainer />,
    ismenu: false,
  },
  {
    key: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.create}`,
    label: 'Invoice Create',
    path: `/${path.ADMIN_PATH.invoice}/${path.ADMIN_PATH.create}`,
    element: <AdminInvoiceFormContainer />,
    ismenu: false,
  },
  {
    key: `/admin/${path.ADMIN_PATH.client}`,
    label: 'List Klien',
    icon: <ICON_CLIENT />,
    path: path.ADMIN_PATH.client,
    element: <AdminClientContainer />,
    ismenu: 'yes',
    forUser: false,
  },
  {
    key: `/${path.ADMIN_PATH.client}/${path.ADMIN_PATH.detail}`,
    label: 'Client Detail',
    path: `/${path.ADMIN_PATH.client}/${path.ADMIN_PATH.detail}`,
    element: <DetailClientContainer />,
    ismenu: false,
  },
  {
    key: `/admin/${path.ADMIN_PATH.product}`,
    label: 'List Produk',
    icon: <ICON_PRODUCT />,
    path: path.ADMIN_PATH.product,
    element: <AdminProductContainer />,
    ismenu: 'yes',
    forUser: false,
  },
  {
    key: `/${path.ADMIN_PATH.product}/${path.ADMIN_PATH.detail}`,
    label: 'Product Detail',
    path: `/${path.ADMIN_PATH.product}/${path.ADMIN_PATH.detail}`,
    element: <DetailProductContainer />,
    ismenu: false,
  },
  // {
  //   key: `/admin/${path.ADMIN_PATH.tax}`,
  //   label: 'Perpajakan',
  //   icon: <WalletOutlined />,
  //   path: path.ADMIN_PATH.tax,
  //   element: <div>Pages Perpajakan</div>,
  //   ismenu: 'yes',
  //   forUser: false,
  // },
  {
    key: `/admin/${path.ADMIN_PATH.settings}`,
    label: 'Pengaturan',
    icon: <SettingOutlined />,
    path: path.ADMIN_PATH.settings,
    element: <AdminAccountContainer />,
    forUser: false,
  },
];
