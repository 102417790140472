import { connect } from 'react-redux';
import Auth from './Auth.component';
import { fAuthUser } from '../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  user: state.users,
});

const mapDispatchToProps = { fAuthUser };

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
