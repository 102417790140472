import { get } from 'lodash';
import { SET_AUTH_USER } from '../reduxType';
import { generateNotification } from '../../utils/general.utils';
import { getToken } from '../../services/token.service';
import api from '../../services/api.service';

const { apiService, setAuthToken } = api.getInstance();

export const fConsumeToken = () => async () => {
  const existingToken = getToken();
  try {
    const { data } = await apiService.post('auth/refresh_token', {
      refresh_token: existingToken?.refresh_token,
    });
    const newToken = {
      ...existingToken,
      ...data?.data,
    };
    setAuthToken(newToken);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    setAuthToken(existingToken);
  }
};

export const fAuthUser = (payload) => async (dispatch) => {
  let result;
  dispatch({
    type: SET_AUTH_USER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const { data, status } = await apiService.post('auth/login', {
      ...payload,
    });
    const returnData = {
      ...data.data,
      isLoading: false,
    };
    setAuthToken(returnData);
    dispatch({
      type: SET_AUTH_USER,
      payload: returnData,
    });
    result = Promise.resolve(true);
  } catch (error) {
    dispatch({
      type: SET_AUTH_USER,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

const generateConfig = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()?.access_token}`,
    },
  };
  return { ...config };
};
export const getSignature = () => async () => {
  let result;
  try {
    const { status, data } = await apiService.get(
      `/user/signature`,
      generateConfig(),
    );
    result = Promise.resolve(data);
  } catch (error) {
    result = Promise.reject(error);
  }
  return result;
};

export const updateSignature = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.put(
      'user/update_signature',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Signature has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};
