import { connect } from 'react-redux';
import DetailClient from './DetailClient.component';
import {
  clientUpdate,
  clientDetail,
} from '../../../redux/actions/client.actions';
const mapStateToProps = (state) => ({
  clientData: state.client.detail,
});

const mapDispatchToProps = {
  clientUpdate,
  clientDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailClient);
