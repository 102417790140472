import { notification } from 'antd';

export const generateNotification = ({ ...props }) => {
  const notif = notification;
  switch (props?.type) {
    case 'error':
      notif.error({
        message: `System Err [${props?.response}]`,
        description: props?.messages,
      });
      break;
    case 'success':
      notif.success({
        message: `Success [${props?.response}]`,
        description: props?.messages,
      });
      break;
    case 'info':
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
    default:
      notif.info({
        message: `Information: ${props?.messages}`,
      });
      break;
  }
  return notif;
};

export const cuntDownTime = (timer, func) => {
  let minutes = Math.floor(timer);
  let seconds = 0;

  setInterval(() => {
    if (seconds <= 0) {
      if (minutes !== 0) {
        seconds = 60;
        minutes -= 1;
      }
    } else {
      seconds -= 1;
    }
    func(`${minutes}m ${seconds}s`);
  }, 1000);
};
