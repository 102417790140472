import { connect } from 'react-redux';
import AdminInvoiceForm from './AdminInvoiceForm.component';
import {
  invoiceCreate,
  invoiceUpdate,
  invoiceList,
  setInvoiceDetail,
} from '../../../redux/actions/invoice.actions';

import {
  productList,
  productListGroup,
} from '../../../redux/actions/product.actions';
import { clientList } from '../../../redux/actions/client.actions';

import { getSignature } from '../../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  invoiceListData: state.invoice.list,
  productListData: state.product.list,
  productListGroupData: state.product.grouplist,
  clientListData: state.client.list,
});

const mapDispatchToProps = {
  invoiceCreate,
  invoiceUpdate,
  invoiceList,
  productList,
  productListGroup,
  clientList,
  setInvoiceDetail,
  getSignature,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInvoiceForm);
