import { get } from 'lodash';
import { SET_CLIENT_LIST, SET_CLIENT_DETAIL } from '../reduxType';

import api from '../../services/api.service';
import { getToken } from '../../services/token.service';
import { generateNotification } from '../../utils/general.utils';
const { apiService, setAuthToken } = api.getInstance();

const generateConfig = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()?.access_token}`,
    },
  };
  return { ...config };
};

// Update Client
export const clientUpdate = (payload) => async () => {
  let result;
  try {
    const { status } = await apiService.post(
      'client/update',
      {
        ...payload,
      },
      generateConfig(),
    );
    generateNotification({
      type: 'success',
      response: status,
      messages: 'Client has been updated.',
    });
    result = Promise.resolve(status);
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject(error);
  }
  return result;
};

// List Client
export const clientList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_CLIENT_LIST,
    payload: {
      isLoading: true,
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/client/get_client_list`,
      generateConfig(),
    );
    dispatch({
      type: SET_CLIENT_LIST,
      payload: {
        data: get(data, 'data'),
        isLoading: false,
      },
    });
    result = Promise.resolve(get(data, 'data'));
  } catch (error) {
    dispatch({
      type: SET_CLIENT_LIST,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const clientDetail = (payload) => async (dispatch) => {
  dispatch({
    type: SET_CLIENT_DETAIL,
    payload: {
      isLoading: true,
    },
  });

  let result;
  try {
    const { status, data } = await apiService.get(
      `/client/get_client_detail?paramClientCode=${payload}`,
      generateConfig(),
    );
    dispatch({
      type: SET_CLIENT_DETAIL,
      payload: {
        data: data?.data,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    dispatch({
      type: SET_CLIENT_DETAIL,
      payload: {
        isLoading: false,
      },
    });
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};

export const setClientDetail = (payload) => async (dispatch) => {
  let result = true;
  console.log(payload);
  try {
    dispatch({
      type: SET_CLIENT_DETAIL,
      payload: {
        data: payload,
      },
    });
    result = Promise.resolve();
  } catch (error) {
    generateNotification({
      type: 'error',
      response: error?.response?.status,
      messages: error?.response?.data?.detail,
    });
    result = Promise.reject();
  }
  return result;
};
