import React, { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  Menu,
  Layout,
  Button,
  Image,
  Typography,
  Avatar,
  Popconfirm,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { BTN_CREATE, BTN_LOGIN, BDD_LOGO, ICON_USER } from '../../../assets';

import { destroyToken } from '../../../services/token.service';
import config from './SideMenuLayout.component.config';
import './SideMenuLayout.component.styles.css';

const { Sider } = Layout;
const { Title, Text, Link } = Typography;

const SideMenuLayout = ({
  location,
  contentMenu,
  collapsed,
  toggleCollapsed,
  ...props
}) => {
  const currentPath = () => {
    const locationPath = location.pathname;
    const patternIgnore = ['detail', 'create', 'draft', 'send'];
    let lockPattern = null;
    patternIgnore.forEach((text) => {
      if (locationPath.includes(`/${text}`)) {
        lockPattern = text;
      }
    });

    const pattern = locationPath.includes(`/${lockPattern}`)
      ? locationPath.split(`/${lockPattern}`)[0]
      : locationPath;

    return pattern;
  };
  const navigate = useNavigate();
  const navigationMenu = (e) => navigate(e.key);

  const generateMenu = (routing) =>
    routing.map((item) => (item.ismenu === 'yes' ? item : null));

  return (
    <Sider
      id="SideMenu"
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      width={250}
    >
      <div className="headline-menu">
        <Image preview={false} src={BDD_LOGO} />
        <Title level={4} hidden={collapsed}>
          BDD <br />
          Web Invoicing
        </Title>
      </div>
      <div className="user-info">
        <Button
          type="primary"
          icon={<BTN_CREATE />}
          size="large"
          style={{ width: collapsed ? 'auto' : 180 }}
          onClick={() => {
            navigate('/admin/invoice/create');
          }}
        >
          {!collapsed && 'Buat Baru'}
        </Button>
      </div>
      <Menu
        mode="inline"
        theme="light"
        items={generateMenu(contentMenu)}
        onClick={navigationMenu}
        defaultSelectedKeys={currentPath()}
        selectedKeys={currentPath()}
      />
      <div className="action-user">
        <Button
          className="btn-logout"
          style={{ width: collapsed ? 'auto' : 180, marginBottom: 10 }}
          onClick={() => {
            navigate('/admin/settings');
          }}
        >
          {!collapsed && 'Account'}
        </Button>
        <Popconfirm
          placement="right"
          title="Are you sure to logout?"
          onConfirm={() => {
            destroyToken();
            navigate('/auth');
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            items={[
              {
                label: 'Logout',
                key: 'logout',
              },
            ]}
            className="btn-logout"
            style={{ width: collapsed ? 'auto' : 180 }}
          >
            {!collapsed && 'Log Out'}
          </Button>
        </Popconfirm>
        {!collapsed && (
          <>
            <div className="footer">
              <Text>
                ©{format(new Date(), 'yyyy')} BDD Invoicing System All right
                reserved
              </Text>
            </div>
            <div className="footer" style={{ cursor: 'pointer' }}>
              <Text
                className="site-policy"
                onClick={() =>
                  window.open(`${window.location.origin}/site-policy`)
                }
              >
                Site Policy
              </Text>
            </div>
          </>
        )}
      </div>
    </Sider>
  );
};

SideMenuLayout.displayName = config.displayName;
SideMenuLayout.defaultProps = config.defaultProps;
SideMenuLayout.propTypes = config.propTypes;

export default SideMenuLayout;
