import { connect } from 'react-redux';
import DetailInvoiceDraft from './DetailInvoiceDraft.component';
import {
  invoiceCreate,
  invoiceUpdate,
  setInvoiceDetail,
  updateConditionInvoice,
} from '../../../redux/actions/invoice.actions';

import {
  productList,
  productListGroup,
} from '../../../redux/actions/product.actions';
import { clientList } from '../../../redux/actions/client.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  invoiceData: state.invoice.detail,
  productListData: state.product.list,
  productListGroupData: state.product.grouplist,
  clientListData: state.client.list,
});

const mapDispatchToProps = {
  invoiceCreate,
  invoiceUpdate,
  updateConditionInvoice,
  productList,
  productListGroup,
  clientList,
  setInvoiceDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailInvoiceDraft);
