import PropTypes from 'prop-types';

const displayName = 'AppLayout';
const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
const defaultProps = {
  rulesBackFrom: ['detail', 'create', 'update'],
};

export default { displayName, propTypes, defaultProps };
