import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Button, Input, Modal, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { DataTable } from '../../../components';
import FormProduct from './FormProduct/FormProduct.component';

import config from './AdminProduct.component.config';
import './AdminProduct.component.styles.css';

const { Title, Text } = Typography;

const AdminProduct = ({
  productGroupCat,
  productListData,
  productCreate,
  productDelete,
  productGroup,
  productList,
  setProductDetail,
  ...props
}) => {
  const productListArr = productListData?.data;
  const productListLoading = productListData?.isLoading;
  const navigate = useNavigate();
  const [state, setState] = useState({
    needMounting: true,
    showEmpty: false,
    showList: true,
    showDetail: false,
  });

  const mountingFirstData = () => {
    productGroup();
    productList().then(() => {
      if (productListArr?.length > 0) {
        navigationContainer({ action: 'list' });
      }
    });
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (nav.type) {
        case 'list':
          currentState.showEmpty = false;
          currentState.showList = true;
          currentState.showModalCreate = false;
          currentState.showDetail = false;
          break;
        case 'showModal':
          currentState.showModalCreate = true;
          break;
        case 'closeModal':
          currentState.showModalCreate = false;
          break;
        default:
          currentState.showEmpty = false;
          currentState.showList = true;
          currentState.showModalCreate = false;
          currentState.showDetail = false;
          break;
      }
      return { ...currentState };
    });
  };

  const selectOneProduct = (idx) => {
    const selectProduct = productListArr.find((items) => items.id === idx);
    setProductDetail(selectProduct).then(() =>
      navigate(`${window.location.pathname}/detail?productID=${idx}`),
    );
  };
  const headerProduk = [
    {
      width: 200,
      title: 'Kode Produk',
      dataIndex: 'service_code',
      key: 'service_code',
      sort: true,
    },
    {
      width: 200,
      title: 'Nama Produk',
      dataIndex: 'service_name',
      key: 'service_name',
      sort: true,
    },
    {
      width: 250,
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
      sort: true,
    },
    {
      width: 200,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      fixed: 'right',
    },
  ];

  const buttonViewDetail = (data) => (
    <Button type="primary" onClick={() => selectOneProduct(data)}>
      View Detail
    </Button>
  );
  const buttonDeleteProduct = (data) => (
    <Popconfirm
      placement="top"
      title="Are you sure to delete this product?"
      onConfirm={() => {
        productDelete(data).then(() => mountingFirstData());
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary">Delete</Button>
    </Popconfirm>
  );
  const actionExtra = [buttonViewDetail, buttonDeleteProduct];

  // CRUD section
  const createProduct = (payload) => {
    productCreate(payload)
      .then(() => {
        productList();
        navigationContainer({ action: 'list' });
      })
      .catch((err) => console.log('productCreate', err));
  };

  const emptyContent = () => (
    <div className="empty-content">
      <div className="content-empty">
        <Title level={2}>Kamu Belum Membuat Product</Title>
        <Text>
          Buat Produk/Layanan baru untuk memenuhi list data dibawah ini!
        </Text>
      </div>
      <Button
        type="primary"
        onClick={() => navigationContainer({ action: 'showModal' })}
      >
        Create Product
      </Button>
    </div>
  );

  return (
    <div id="AdminProduct">
      {state.showEmpty && emptyContent()}
      {state.showList && (
        <>
          <div className="title-dashboard">
            <Title level={3}>Daftar Produk dan Layanan BDD</Title>
            <div className="search-container">
              <Input.Search
                size="large"
                placeholder="cari produk disini..."
                style={{ width: '50%' }}
              />
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => navigationContainer({ type: 'showModal' })}
              >
                Tambah Produk
              </Button>
            </div>
          </div>
          <div className="content-dashboard">
            {state.showList && (
              <DataTable
                withPagination={10}
                columns={headerProduk}
                haveAction={actionExtra}
                dataSource={productListArr}
                loading={productListLoading}
                sizeChanger
              />
            )}
          </div>
        </>
      )}
      <FormProduct
        productGroupCat={productGroupCat.data}
        showModal={state.showModalCreate}
        closeModal={navigationContainer}
        createProduct={createProduct}
      />
    </div>
  );
};

AdminProduct.displayName = config.displayName;
AdminProduct.defaultProps = config.defaultProps;
AdminProduct.propTypes = config.propTypes;

export default AdminProduct;
