import React from 'react';
import {
  Layout,
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Modal,
  Popconfirm,
} from 'antd';

import { BDD_LOGO, BDD_LOGO_EXPAND } from '../../assets';
import { getParamURL } from '../../utils/options';
import { DataTable } from '../../components';
import { formatNumber } from '../../utils/string';
import config from './Checkout.component.config';
import './Checkout.component.styles.css';

const { Title, Text } = Typography;

const Checkout = ({
  invoiceData,
  getInvoiceForCheckout,
  requestInvoiceXendit,
}) => {
  const [state, setState] = React.useState({
    collectData: true,
    transactionData: invoiceData,
    productList: [],
    totalData: {
      subtotal: 0,
      total_ppn: 0,
      total_pph: 0,
      total: 0,
    },
    loadingTransaction: false,
  });
  const [showLoading, setShowloading] = React.useState(false);
  const transactionID = getParamURL('trx');

  const collectDataInvoice = () => {
    getInvoiceForCheckout(transactionID).then((reData) => {
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.transactionData = reData;
        if (currentState.transactionData.product !== undefined) {
          currentState.productList = currentState.transactionData.product.map(
            (val) => ({
              ...val,
              existing: true,
              ppn_nominal:
                val.ppn === 1
                  ? (val.qty * val.amount - val.discount) * 0.11
                  : 0,
              pph_nominal:
                val.pph === 1
                  ? (val.qty * val.amount - val.discount) * 0.02
                  : 0,
            }),
          );
        }
        currentState.totalData.subtotal = currentState.productList?.reduce(
          (acc, obj) => acc + obj.sub_total,
          0,
        );
        currentState.totalData.total_ppn = currentState.productList?.reduce(
          (acc, obj) => acc + obj.ppn_nominal,
          0,
        );
        currentState.totalData.total_pph = currentState.productList?.reduce(
          (acc, obj) => acc + obj.pph_nominal,
          0,
        );
        currentState.totalData.total = currentState.productList?.reduce(
          (acc, obj) => acc + obj.ppn_nominal - obj.pph_nominal + obj.sub_total,
          0,
        );
        currentState.collectData = false;
        currentState.loadingTransaction = false;
        return { ...currentState };
      });
    });
  };
  React.useEffect(() => {
    if (state.collectData) {
      setState((prevState) => {
        const currentState = { ...prevState };
        currentState.loadingTransaction = true;
        return { ...currentState };
      });
      collectDataInvoice();
    }
  }, [state.transactionData, state.productList]);

  const actionRequestInvoice = () => {
    const rawData = state.transactionData;
    const bodyRequest = {
      hash: transactionID,
      invoice_number: rawData.transaction.invoice_number,
      primary_email: rawData.primary_email,
      project_name: `${rawData.project_name}, ${rawData.transaction.notes}`,
      amount: rawData.transaction.total_amount,
    };
    setShowloading(true);
    requestInvoiceXendit(bodyRequest).then((invUrl) => {
      setShowloading(false);
      window.open(invUrl);
    });
  };

  return (
    <div id="Checkout">
      <Card
        loading={state.loadingTransaction}
        title={
          <Row gutter={24}>
            <Col flex="auto">
              <Image src={BDD_LOGO_EXPAND} preview={false} height={50} />
            </Col>
            <Col flex="auto" style={{ textAlign: 'right' }}>
              <Title
                style={{
                  fontWeight: 'bold',
                  color: '#FA530A',
                  margin: 'unset',
                }}
              >
                INVOICE
              </Title>
              <Text>
                Invoice ID: {state.transactionData?.transaction?.invoice_number}
              </Text>
            </Col>
          </Row>
        }
      >
        <div className="detail-client">
          <Row gutter={24}>
            <Col flex="auto">
              <div style={{ width: 300 }}>
                <Text>
                  <b>Ditagihkan kepada:</b> <br />
                  {state.transactionData?.person_in_charge},{' '}
                  <b>{state.transactionData?.brand_name}</b>,{' '}
                  {state.transactionData?.address},{' '}
                </Text>
              </div>
            </Col>
            <Col flex="auto">
              <div className="invoice-info-created">
                <div className="item-info">
                  <div>Dibuat Pada Tanggal:</div>
                  <div>{state.transactionData?.updated_at}</div>
                </div>
                <div className="item-info">
                  <div>Jadwal Invoice:</div>
                  <div>
                    Terjadwal - Bulan{' '}
                    {state.transactionData?.transaction?.recurring_count}
                  </div>
                </div>
                <div className="item-info">
                  <div>Jatuh Tempo:</div>
                  <div>{state.transactionData?.due_date}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="product-invoice">
          <DataTable
            columns={config.defaultProps.columnHeader}
            dataSource={state.productList}
            withPagination={99}
            disablePagination
            sizeChanger
          />
        </div>
        <div className="invoice-overview">
          <div className="item-overview">
            <div>Subtotal:</div>
            <div>Rp. {formatNumber(state.totalData.subtotal, 2)}</div>
          </div>
          <div
            className="item-overview"
            hidden={state.totalData.total_ppn === 0 ? 'hidden' : false}
          >
            <div>PPN:</div>
            <div>Rp. {formatNumber(state.totalData.total_ppn, 2)}</div>
          </div>
          <div
            className="item-overview"
            hidden={state.totalData.total_pph === 0 ? 'hidden' : false}
          >
            <div>PPh 23:</div>
            <div>Rp. {formatNumber(state.totalData.total_pph, 2)}</div>
          </div>
          <div className="item-overview total-tagihan">
            <div>Total Ditagihkan:</div>
            <div>Rp. {formatNumber(state.totalData.total, 2)}</div>
          </div>
          <div className="item-overview">
            {state.transactionData?.transaction?.payment_status === 2 && (
              <Text>Tagihan Sudah Dibayar</Text>
            )}
            {state.transactionData?.transaction?.payment_status === 1 && (
              <Popconfirm
                placement="top"
                title="Apakah anda yakin untuk melanjutkan ke pembayaran?"
                okText="Ya, Lanjutkan ke Pembayaran"
                cancelText="Tidak, Cek sekali lagi"
                onConfirm={() => actionRequestInvoice()}
              >
                <Button block type="primary" size="large" loading={showLoading}>
                  Bayar Tagihan Sekarang
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
        <div
          className="detail-info"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            padding: '0px 40px',
          }}
        >
          <Text>
            <b>Informasi Bantuan</b> <br />
            Untuk Informasi Bantuan lainnya, silakan hubungi ke email{' '}
            <a href="_blank">finance@bolehdicoba.com</a>
          </Text>
          <Text
            className="site-policy"
            onClick={() => window.open(`${window.location.origin}/site-policy`)}
            style={{ cursor: 'pointer' }}
          >
            Site Policy
          </Text>
        </div>
      </Card>
    </div>
  );
};

Checkout.displayName = config.displayName;
Checkout.defaultProps = config.defaultProps;
Checkout.propTypes = config.propTypes;

export default Checkout;
