import { connect } from 'react-redux';
import DetailInvoiceOpen from './DetailInvoiceOpen.component';
import {
  invoiceUpdateTT,
  setInvoiceDetail,
  invoiceUpdateTTRecuring,
  updateConditionInvoice,
  generateInvoiceRecurring,
  reqInvoiceSentEmail,
} from '../../../redux/actions/invoice.actions';
import {
  productList,
  productListGroup,
} from '../../../redux/actions/product.actions';
import {
  clientList,
  clientUpdate,
  clientDetail,
} from '../../../redux/actions/client.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  invoiceData: state.invoice.detail,
  productListData: state.product.list,
  productListGroupData: state.product.grouplist,
  clientListData: state.client.list,
});

const mapDispatchToProps = {
  invoiceUpdateTT,
  productList,
  productListGroup,
  clientList,
  clientDetail,
  clientUpdate,
  setInvoiceDetail,
  invoiceUpdateTTRecuring,
  updateConditionInvoice,
  generateInvoiceRecurring,
  reqInvoiceSentEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailInvoiceOpen);
