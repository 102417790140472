/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
// import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import {
  Form,
  Typography,
  Button,
  Popconfirm,
  Card,
  Upload,
  List,
  Image,
} from 'antd';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';

import moment from 'moment';
import { DataTable } from '../../../components';
import { getParamURL, toDataUrl } from '../../../utils/options';
import { formatNumber } from '../../../utils/string';
import {
  executeS3UploadUrl,
  generateS3UploadUrl2,
  getObjectAwsS3Bucket,
} from '../../../services/aws.service';

import logo from '../../../assets/logo.png';
import config from './DetailInvoiceSend.component.config';
import './DetailInvoiceSend.component.styles.css';

const { Title, Text, Paragraph } = Typography;

const DetailInvoiceSend = ({
  invoiceData,
  invoiceUpdate,
  invoiceUpdateTT,
  reqInvoiceSentEmail,
  setInvoiceDetail,
  ...props
}) => {
  const invoiceID = getParamURL('invoiceID');
  const detailData = invoiceData?.data;
  const detailDataLoading = invoiceData?.loading;
  // transaction data
  const transactionList = detailData.transaction;
  const transactionSelected = transactionList[0];

  // STATE NAVIGATION
  const navigate = useNavigate();
  const [state, setState] = useState({
    needMounting: true,
    renderFormClient: false,
    renderDetailInvoiceSend: true,
    fileList: [],
  });

  // Convert Created Date
  const created = new Date(detailData?.created_at)
    .toString()
    .replace(/\S+\s(\S+)\s(\d+)\s(\d+)\s.*/, '$2 $1 $3');
  // Convert Due Date
  const due = moment(new Date(detailData?.due_date)).format('DD MMMM YYYY');

  // Initializing Transaction data //
  // CONVERTING PPN-PPH WHEN INITIATING PRODUCT DATA
  const initDataProduct = () => {
    const returnDataProduct = [];
    detailData?.product.forEach((val) => {
      returnDataProduct.push({
        ...val,
        ppn_nominal:
          val.ppn === 1 ? (val.qty * val.amount - val.discount) * 0.11 : 0,
        pph_nominal:
          val.pph === 1 ? (val.qty * val.amount - val.discount) * 0.02 : 0,
      });
    });

    detailData?.product_tt.forEach((prod) => {
      if (transactionSelected.id === prod.transaction_id)
        returnDataProduct.push({
          ...prod,
          ppn_nominal:
            prod.ppn === 1
              ? (prod.qty * prod.amount - prod.discount) * 0.11
              : 0,
          pph_nominal:
            prod.pph === 1
              ? (prod.qty * prod.amount - prod.discount) * 0.02
              : 0,
        });
    });
    return returnDataProduct;
  };
  // SET PRODUCT LIST
  const [stateDaftarLayanan, setStateDaftarLayanan] = React.useState({
    loadProgressDone: true,
    list_data: initDataProduct(),
  });
  // SET Calculation data
  const [stateTotal, setStateTotal] = React.useState({
    loadProgressDone: true,
    list_data: initDataProduct(),
  });

  // KALKULASI TOTAL AMOUNT LAYANAN
  const totalPPN = initDataProduct().reduce((a, b) => a + b.ppn_nominal, 0);
  const totalPPH = initDataProduct().reduce((a, b) => a + b.pph_nominal, 0);
  const totalAmount = initDataProduct().reduce((a, b) => a + b.sub_total, 0);
  const total = initDataProduct().reduce(
    (a, b) => a + b.ppn_nominal - b.pph_nominal + b.sub_total,
    0,
  );

  // UPDATE INVOICE TRANSACTION
  const actionUpdateInvoiceTT = async (fileAttachment) => {
    const requestBody = {
      // ...payload,
      invoice_id: invoiceID,
      status: 1,
      total_amount: transactionSelected.total_amount,
      client_id: detailData?.client_id,
      transaction_id: transactionSelected.id,
      invoice_number: transactionSelected.invoice_number,
      attachment:
        fileAttachment.length > 0
          ? fileAttachment.map((u) => u.url).join(';')
          : state.fileList.map((u) => u.url).join(';'),
    };
    await invoiceUpdateTT(requestBody).then(async () => {
      await reqInvoiceSentEmail(requestBody);
      setInvoiceDetail({ id: invoiceID }).then(() => {
        navigate(`/admin/invoice/detail?invoiceID=${invoiceID}`);
      });
    });
  };

  const [imgSignature, setImgSignature] = React.useState(null);
  const signatureImage = () => {
    const signatureIMG = detailData.signature;
    getObjectAwsS3Bucket(signatureIMG.split('amazonaws.com/')[1]).then(
      async (response) => {
        toDataUrl(response, (imgData) => {
          // console.log(imgData);
          setImgSignature(imgData.replace('multipart/form-data', 'image/png'));
        });
      },
    );
  };

  // Pratinjau Email - Restyling under develop
  const emailPreviewRef = React.useRef();
  const emailPreview = () => {
    signatureImage();
    return (
      <div
        ref={emailPreviewRef}
        id="previewDataEmail"
        style={{
          background: 'white',
          backgroundColor: 'white',
          padding: '20px 30px',
          width: '793.7007874px',
          height: '1122.519685px',
          marginRight: 'auto',
          marginLeft: 'auto',
          position: 'relative',
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <Image alt="logo" src={logo} width={200} preview={false} />
          <div style={{ marginLeft: 60, marginTop: 10 }}>
            <div>PT. Boleh Dicoba Digital</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>finance@bolehdicobadigital.com</div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ marginBottom: 20, textAlign: 'center' }}>
          <Title
            level={3}
            style={{ width: 400, margin: 'auto', marginBottom: 20 }}
          >
            Invoice - {detailData.brand_name} - {detailData?.project_name}
          </Title>
          <div
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              alignContent: 'center',
              alignItems: 'center',
              padding: 16,
              gap: 40,
              margin: 'auto',
              marginBottom: 20,
              width: 400,
              border: '2px solid rgba(255, 126, 69, 0.55)',
              flex: 'none',
              order: 1,
              flexGrow: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '4px 0',
                gap: 8,
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 0,
                  gap: 41,
                  width: '100%',
                  height: 18,
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 18,
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  Invoice ID :
                </div>
                <div
                  style={{
                    width: 200,
                    height: 18,
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  {transactionSelected.invoice_number}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 0,
                  gap: 41,
                  width: '100%',
                  height: 18,
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 18,
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  Jatuh Tempo :
                </div>
                <div
                  style={{
                    width: 200,
                    height: 18,
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  {' '}
                  {due}{' '}
                </div>
              </div>
              {detailData.is_recurring === 1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 0,
                    gap: 41,
                    width: '100%',
                    height: 18,
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 18,
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: '18px',
                      textAlign: 'left',
                    }}
                  >
                    Jadwal Invoice :
                  </div>
                  <div
                    style={{
                      width: 200,
                      height: 18,
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '18px',
                      textAlign: 'left',
                    }}
                  >
                    Bulan {transactionSelected?.recurring_count} dari{' '}
                    {detailData.recurring_count}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 0,
                  gap: 41,
                  width: '100%',
                  height: 18,
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 18,
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  Total Tagihan :
                </div>
                <div
                  style={{
                    width: 200,
                    height: 18,
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  Rp. {formatNumber(transactionSelected.total_amount, 2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div
          style={{
            margin: '20px auto',
            alignContent: 'center',
            maxWidth: 600,
          }}
        >
          <div>Dear {detailData?.person_in_charge},</div>
          <br />
          <div>
            Terima kasih atas kepercayaan Anda kepada Boleh Dicoba Digital untuk
            melancarkan keperluan bisnis Anda.
          </div>
          <br />
          <div>
            Berikut adalah Invoice
            {` ${detailData?.transaction[0].invoice_number} `}, tertagih sebesar{' '}
            <b>
              Rp.
              {formatNumber(detailData?.transaction[0].total_amount, 2)}
            </b>
          </div>
        </div>
        <div
          style={{
            margin: '20px auto',
            maxWidth: 600,
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            Informasi Tambahan
          </div>
          <br />
          <div>
            Apabila melakukan pemotongan PPh 23, harap untuk mengirimkan bukti
            potong ke email berikut:
            <a href="mailto:finance@bolehdicobadigital">
              {' '}
              finance@bolehdicobadigital
            </a>
          </div>
          <br />
          <div>Dokumen pendamping sudah terlampir di dalam email ini.</div>
          <div style={{ marginTop: 20 }}>
            Catatan: {transactionSelected.notes}
          </div>
        </div>
        <div
          style={{
            margin: '20px auto',
            maxWidth: 600,
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>
            Informasi Bantuan
          </div>
          <div>
            Untuk Informasi Bantuan lainnya, silakan hubungi ke email{' '}
            <a href="mailto:finance@bolehdicobadigital">
              finance@bolehdicobadigital
            </a>
          </div>
        </div>
        <div
          style={{
            paddingTop: 15,
            marginLeft: 20,
            maxWidth: 250,
            position: 'absolute',
            bottom: 20,
            width: '100%',
          }}
        >
          <div>Diterbitkan oleh,</div>
          <div>Finance Dept. PT. Boleh Dicoba Digital</div>
          <div style={{ margin: '20px 0' }} id="image-section">
            <img
              id="image-signature"
              preview={false}
              height={60}
              src={imgSignature}
              alt="new"
            />
          </div>
          <div>{detailData?.approver.split(',')[0]}</div>
          <div>{detailData?.approver.split(',')[1]}</div>
        </div>
      </div>
    );
  };

  // Table Transaction
  const columnHeader = [
    {
      title: 'Nama Produk',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Harga Satuan',
      dataIndex: 'amount',
      key: 'amount',
      formatCurrency: 'Rp.',
      fixed: 'right',
    },
    {
      title: 'Diskon',
      dataIndex: 'discount',
      key: 'discount',
      formatCurrency: 'Rp.',
      fixed: 'right',
    },
    {
      title: 'Harga total',
      dataIndex: 'sub_total',
      key: 'sub_total',
      formatCurrency: 'Rp.',
      fixed: 'right',
    },
  ];

  // Data Table
  const dataTable = () => (
    <div className="history-invoice">
      <DataTable
        columns={columnHeader}
        dataSource={initDataProduct}
        loading={detailDataLoading}
        withPagination={99}
        disablePagination
        sizeChanger
      />
    </div>
  );
  // Transaksi
  const renderOverviewInvoice = () => (
    <div className="inv-overview">
      <List.Item className="item-overview">
        <Text>Subtotal:</Text>
        <Text>Rp. {formatNumber(totalAmount, 2)}</Text>
      </List.Item>
      <List.Item
        className="item-overview"
        hidden={Number(totalPPN) === 0 ? 'hidden' : false}
      >
        <Text>PPN:</Text>
        <Text>Rp. {formatNumber(totalPPN, 2)}</Text>
      </List.Item>
      <List.Item
        className="item-overview"
        hidden={Number(totalPPH) === 0 ? 'hidden' : false}
      >
        <Text>PPh 23:</Text>
        <Text>Rp. {formatNumber(totalPPH, 2)}</Text>
      </List.Item>
      <List.Item className="item-overview total">
        <Text>
          <b>Total Ditagihkan:</b>
        </Text>
        <Text>
          <b>Rp. {formatNumber(total, 2)}</b>
        </Text>
      </List.Item>
    </div>
  );

  const invoicePreview = () => (
    <div
      id="invoicePreview"
      style={{
        background: 'white',
        backgroundColor: 'white',
        padding: '20px 30px',
        width: '793.7007874px',
        height: '1122.519685px',
        marginRight: 'auto',
        marginLeft: 'auto',
        position: 'relative',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <img src={logo} alt="logo" maxHeight="100px" width={200} />
        </div>
        <div>
          <div
            style={{
              color: '#FA530A',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'right',
              textTransform: 'uppercase',
            }}
          >
            INVOICE
          </div>
          <div
            style={{
              flexDirection: 'row',
              alignSelf: 'flex-end',
              textAlign: 'right',
              left: 0,
              top: 25,
              fontSize: 14,
            }}
          >
            Invoice ID: {detailData?.transaction[0].invoice_number}
          </div>
        </div>
      </div>
      <hr />
      <div
        style={{
          marginLeft: 30,
        }}
      >
        PT. Boleh Dicoba Digital
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        finance@bolehdicobadigital.com
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        NPWP: 83.900.679.8-067.000
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        Kota Bandung, Jawa Barat,
      </div>
      <div
        style={{
          marginLeft: 30,
          paddingBottom: 20,
        }}
      >
        Indonesia
      </div>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: 16,
          marginLeft: 30,
        }}
      >
        Ditagihkan Kepada:
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        {detailData?.person_in_charge}
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        {detailData?.brand_name}
      </div>
      <div
        style={{
          marginLeft: 30,
        }}
      >
        {detailData?.address}
      </div>
      <div
        style={{
          flexDirection: 'row',
          alignSelf: 'flex-end',
          textAlign: 'right',
          marginRight: 30,
          marginTop: 30,
          marginBottom: 30,
          left: 0,
          top: 25,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: 150 }}>Dibuat Pada Tanggal :</div>
          <div style={{ width: 140, marginLeft: 10 }}>
            {moment(detailData?.created_at).format('DD MMMM YYYY')}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: 150 }}>Jadwal Invoice :</div>
          <div style={{ width: 140, marginLeft: 10 }}>
            {detailData.is_recurring === 1
              ? `Terjadwal - Bulan ${transactionSelected?.recurring_count}`
              : 'Satuan'}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: 150 }}>Jatuh Tempo :</div>
          <div style={{ width: 140, marginLeft: 10 }}>
            {moment(detailData?.due_date).format('DD MMMM YYYY')}
          </div>
        </div>
      </div>
      <div>{dataTable()}</div>
      <div>{renderOverviewInvoice()}</div>
      <div style={{ marginTop: 20 }}>Catatan: {transactionSelected.notes}</div>
      <div
        style={{
          paddingTop: 15,
          marginLeft: 20,
          maxWidth: 250,
          position: 'absolute',
          bottom: 20,
          width: '100%',
        }}
      >
        <div>Diterbitkan oleh,</div>
        <div>Finance Dept. PT. Boleh Dicoba Digital</div>
        <div style={{ margin: '20px 0' }} id="image-section">
          <img
            id="image-signature"
            preview={false}
            height={60}
            src={imgSignature}
            alt="new"
          />
        </div>
        <div>{detailData?.approver.split(',')[0]}</div>
        <div>{detailData?.approver.split(',')[1]}</div>
      </div>
    </div>
  );

  const mountingFirstData = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setInvoiceDetail({ id: invoiceID });
    setState((prevState) => {
      const currenState = { ...prevState };
      // // CONVERTING ATTACHMENT WHEN INIT
      const transactionData = detailData?.transaction;
      let isAttachTransaction = null;
      if (transactionData.length !== 0) {
        if (detailData.is_recurring === 1) {
          isAttachTransaction =
            transactionData[transactionData.length - 1]?.attachment;
        } else {
          isAttachTransaction = transactionData[0]?.attachment;
        }

        if (isAttachTransaction !== '') {
          const splitAttachment = isAttachTransaction.split(';');
          const tempAttachment = [];
          splitAttachment.forEach((items) => {
            tempAttachment.push({
              uid: items,
              name: items,
              url: items,
              status: 'done',
            });
          });
          currenState.fileList = tempAttachment;
        }
      }

      return { ...currenState };
    });

    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  }, [state.needMounting]);

  const draftBack = () => {
    if (detailData.is_recurring === 1) {
      if (transactionSelected.recurring_count !== 1) {
        navigate(`/admin/invoice/detail?invoiceID=${invoiceID}`);
      } else {
        navigate(`/admin/invoice/draft?invoiceID=${invoiceID}`);
      }
    } else {
      navigate(`/admin/invoice/draft?invoiceID=${invoiceID}`);
    }
  };

  const renderSectionTitleData = () => {
    const typeOfInvoice = detailData;
    return (
      <>
        <Title level={3}>
          <b>{detailData?.project_name}</b>
        </Title>
        <Paragraph
          ellipsis={{
            rows: 14,
            expandable: false,
          }}
        >
          <div className="title-data">
            <div>Dibuat Pada Tanggal : {transactionSelected?.created_at}</div>
            {detailData.is_recurring === 1 && (
              <div>
                Invoice Bulan Ke : {transactionSelected?.recurring_count} dari{' '}
                {detailData.recurring_count}
              </div>
            )}
            <div>Nomor Invoice : {transactionSelected.invoice_number}</div>
            <div>Dikirim Ke : {detailData?.primary_email}</div>
            <div
              style={{
                marginLeft: 73,
                maxWidth: 200,
              }}
            >
              {detailData?.emailcc}
            </div>
          </div>
        </Paragraph>
      </>
    );
  };

  const uploadPdfGenerator = async (filename, filepdf) => {
    let file = null;
    await generateS3UploadUrl2('invoice/exported', {
      extension: 'pdf',
      filename,
    }).then(async (url) => {
      await executeS3UploadUrl(url, filepdf).then((dataURL) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.fileList = [...currentState.fileList];
          file = {
            url: dataURL,
            uid: dataURL,
            name: dataURL,
            status: dataURL ? 'done' : 'error',
          };
          currentState.fileList.push(file);
          return { ...currentState };
        });
      });
    });
    return Promise.resolve({
      status: true,
      file,
    });
  };

  const uploadPreviewsEmail = async () => {
    const pratinjauEmail = document.getElementById('previewDataEmail');
    const returnUpload = {
      status: false,
      file: null,
    };
    await html2canvas(pratinjauEmail).then(async (canvas) => {
      const componentData = canvas.toDataURL('image/png');

      // const newPreview = document.getElementById('newPreview');
      // newPreview.appendChild(canvas);

      const doc = new JsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px',
      });

      doc.addImage(componentData, 'JPEG', 0, 0);

      await uploadPdfGenerator(
        `${detailData.brand_name}#lampiran-1`,
        doc.output('blob'),
      ).then((statusUpload) => {
        returnUpload.status = statusUpload.status;
        returnUpload.file = statusUpload.file;
      });
      // doc.save('download.pdf');
    });
    return Promise.resolve(returnUpload);
  };

  const uploadPreviewsInvoice = async () => {
    const pratinjauEmail = document.getElementById('invoicePreview');
    const returnUpload = {
      status: false,
      file: null,
    };
    await html2canvas(pratinjauEmail).then(async (canvas) => {
      const componentData = canvas.toDataURL('image/png');

      // const newPreview = document.getElementById('newPreview');
      // newPreview.appendChild(canvas);

      const doc = new JsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px',
        compress: true,
      });
      const docWidth = doc.internal.pageSize.getWidth();
      const docHeight = doc.internal.pageSize.getHeight();

      doc.addImage(componentData, 'JPEG', 0, 0, docWidth, docHeight, 'FAST');

      await uploadPdfGenerator(
        `Invoice_${transactionSelected?.invoice_number?.replaceAll('/', '-')}_${detailData?.brand_name
        }_${format(new Date(), 'ddMMyyyy')}`, // file name uploaded
        doc.output('blob'),
      ).then((statusUpload) => {
        returnUpload.status = statusUpload.status;
        returnUpload.file = statusUpload.file;
      });
      // doc.save('download.pdf');
    });
    return Promise.resolve(returnUpload);
  };

  const generateInvoiceSend = async () => {
    let dataFile = [...state.fileList];
    await uploadPreviewsInvoice().then(async (previewInvoice) => {
      console.log(previewInvoice);
      dataFile = [...dataFile, previewInvoice.file];
      if (previewInvoice.status) {
        await actionUpdateInvoiceTT(dataFile);
      }
    });
  };

  return (
    <div id="DetailInvoiceSend">
      <div className="button-action" style={{ float: 'right' }}>
        <Popconfirm
          placement="top"
          title="Sudah Yakin Untuk Simpan dan Kirim Invoice Ini?"
          onConfirm={() => generateInvoiceSend()}
          Text="Pastikan Data Invoice yang kamu masukkan sudah benar. data tidak dapat diubah jika invoice telah beruah status menjadi Open"
          okText="Ya, Simpan dan Kirim"
          cancelText="Kembali"
        >
          <Button type="primary">Kirim Sekarang</Button>
        </Popconfirm>
      </div>
      <div className="section-field">{renderSectionTitleData()}</div>
      <div className="section-field">
        <Title level={5}>
          <b>Pratinjau Email</b>
        </Title>
        <div className="preview-file-pdf">{emailPreview()}</div>
      </div>
      <div className="section-field">
        <Title level={5}>
          <b>Pratinjau PDF Invoice</b>
        </Title>
        <div className="preview-file-pdf">{invoicePreview()}</div>
      </div>
      <div className="section-field">
        <Title level={5}>
          <b>Lampiran Invoice</b>
        </Title>
        <Form.Item name="attachment">
          <Upload
            fileList={state.fileList}
            listType="text"
            showUploadList={{
              showRemoveIcon: false,
              showDownloadIcon: false,
            }}
            maxCount={10}
          />
        </Form.Item>
      </div>
      <div id="newPreview" style={{ background: 'red' }}></div>
      <div className="button-action">
        <Button type="default" onClick={draftBack}>
          Ubah Kembali Invoice
        </Button>
      </div>
    </div>
  );
};

DetailInvoiceSend.displayName = config.displayName;
DetailInvoiceSend.defaultProps = config.defaultProps;
DetailInvoiceSend.propTypes = config.propTypes;

export default DetailInvoiceSend;
