import React, { useRef } from 'react';
import { Modal, Form, Input, Typography, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import config from './FormProduct.component.config';
import './FormProduct.component.styles.css';

const { TextArea } = Input;
const { Title } = Typography;

const FormProduct = ({
  productGroupCat,
  sourceData,
  visibleModal,
  navigationContainer,
  actionUpdateProduct,
  ...props
}) => {
  console.log(sourceData);
  const refModal = useRef();
  const generateProductGroup = () => {
    const data = productGroupCat.map((item) => ({
      label: item.service_group,
      value: item.id,
    }));
    return data;
  };
  setTimeout(() => {
    refModal?.current?.setFieldsValue({
      service_group: `${sourceData?.service_group}`,
      service_code: `${sourceData?.service_code}`,
      service_name: `${sourceData?.service_name}`,
      description: sourceData?.description,
    });
  }, 500);
  return (
    <Modal
      className="modal-produk"
      title={<Title level={4}>Ubah Data Produk</Title>}
      visible={visibleModal}
      onCancel={() => navigationContainer({ type: 'editProduct' })}
      maskClosable={false}
      width={600}
      footer={false}
      centered
      destroyOnClose
    >
      <Form
        ref={refModal}
        layout="horizontal"
        name="produk"
        onFinish={() => {
          const formData = refModal.current.getFieldsValue();
          const findGroup = productGroupCat.find(
            (item) => item.service_group === formData.service_group,
          );
          const requestBody = {
            ...formData,
            service_group:
              findGroup === undefined ? formData.service_group : findGroup.id,
          };
          actionUpdateProduct(requestBody);
          setTimeout(() => {
            navigationContainer({ type: 'editProduct' });
          }, 500);
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="Grup Produk"
          name="service_group"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Select
            options={productGroupCat.map((item) => ({
              label: item.service_group,
              value: item.id,
            }))}
            placeholder="Pilih Grup Produk..."
          />
        </Form.Item>
        <Form.Item
          label="Kode Produk"
          name="service_code"
          rules={[
            {
              required: true,
              message: 'Product code is required',
            },
          ]}
        >
          <Input placeholder="Kode Produk..." />
        </Form.Item>
        <Form.Item
          label="Nama Produk"
          name="service_name"
          rules={[
            {
              required: true,
              message: 'Product Name is required',
            },
          ]}
        >
          <Input placeholder="Nama Produk..." />
        </Form.Item>
        <Form.Item label="description" name="description">
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <div className="button-action">
          <Button
            type="default"
            onClick={() => navigationContainer({ type: 'editProduct' })}
          >
            Batalkan
          </Button>
          <Button type="primary" htmlType="submit">
            Ubah Produk
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

FormProduct.displayName = config.displayName;
FormProduct.defaultProps = config.defaultProps;
FormProduct.propTypes = config.propTypes;

export default FormProduct;
