import { connect } from 'react-redux';
import AdminAccount from './AdminAccount.component';
import {
  fConsumeToken,
  getSignature,
  updateSignature,
} from '../../../redux/actions/authUser.actions';

const mapStateToProps = (state) => ({
  name: state.header,
});

const mapDispatchToProps = {
  fConsumeToken,
  getSignature,
  updateSignature,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccount);
