import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Button, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { DataTable } from '../../../components';
import FormClient from './FormClient/FormClient.component';

import config from './AdminClient.component.config';
import './AdminClient.component.styles.css';

const { Title, Text } = Typography;

const AdminClient = ({
  clientListData,
  // clientCreate,
  clientList,
  setClientDetail,
  ...props
}) => {
  const clientListArr = clientListData?.data;
  const clientListLoading = clientListData?.isLoading;
  const navigate = useNavigate();
  const [state, setState] = useState({
    needMounting: true,
    showEmpty: true,
    showList: true,
    showDetail: false,
  });
  console.log(clientListArr);
  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (nav.type) {
        case 'list':
          currentState.showEmpty = false;
          currentState.showList = true;
          currentState.showModalCreate = false;
          currentState.showDetail = false;
          break;
        case 'showModal':
          currentState.showModalCreate = true;
          break;
        case 'closeModal':
          currentState.showModalCreate = false;
          break;
        default:
          currentState.showEmpty = false;
          currentState.showList = true;
          currentState.showModalCreate = false;
          currentState.showDetail = false;
          break;
      }
      return { ...currentState };
    });
  };

  const mountingFirstData = () => {
    clientList().then(() => {
      if (clientListArr?.length > 0) {
        navigationContainer({ action: 'list' });
      }
    });
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  });

  const selectOneClient = (idx) => {
    const selectClient = clientListArr.find((items) => items.id === idx);
    setClientDetail(selectClient).then(() =>
      navigate(`${window.location.pathname}/detail?clientID=${idx}`),
    );
  };
  const headerClient = [
    {
      width: 250,
      title: 'Client Name',
      dataIndex: 'brand_name',
      key: 'brand_name',
      sort: true,
    },
    {
      width: 150,
      title: 'Client Contact',
      dataIndex: 'person_in_charge',
      key: 'person_in_charge',
      sort: true,
    },
    {
      width: 100,
      title: 'Email',
      dataIndex: 'primary_email',
      key: 'primary_email',
      sort: true,
    },
    // {
    //   title: 'Jumlah Transaksi',
    //   dataIndex: '',
    //   key: '',
    //   sort: true,
    // },
    // {
    //   title: 'Jumlah Invoice Jatuh Tempo',
    //   dataIndex: '',
    //   key: '',
    //   sort: true,
    // },
    {
      width: 200,
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      actionRows: true,
      fixed: 'right',
    },
  ];
  const buttonViewDetail = (data) => (
    <Button type="primary" onClick={() => selectOneClient(data)}>
      View Detail
    </Button>
  );
  const actionExtra = [buttonViewDetail];

  // CRUD section
  // const createClient = (payload) => {
  //   clientCreate(payload)
  //     .then(() => {
  //       clientList();
  //       navigationContainer({ action: 'list' });
  //     })
  //     .catch((err) => console.log('clientCreate', err));
  // };

  return (
    <div id="AdminClient">
      {state.showEmpty}
      {state.showList && (
        <>
          <div className="title-dashboard">
            <Title level={3}>Daftar Client BDD</Title>
            <div className="search-container">
              <Input.Search
                size="large"
                placeholder="cari client disini..."
                style={{ width: '50%' }}
              />
              {/* <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                onClick={() => navigationContainer({ type: 'showModal' })}
              >
                Tambah Client
              </Button> */}
            </div>
          </div>
          <div className="content-dashboard">
            {state.showList && (
              <DataTable
                withPagination={10}
                columns={headerClient}
                haveAction={actionExtra}
                dataSource={clientListArr}
                loading={clientListLoading}
                sizeChanger
              />
            )}
          </div>
        </>
      )}
      <FormClient
        showModal={state.showModalCreate}
        navigationContainer={navigationContainer}
      />
    </div>
  );
};

AdminClient.displayName = config.displayName;
AdminClient.defaultProps = config.defaultProps;
AdminClient.propTypes = config.propTypes;

export default AdminClient;
