import React from 'react';
import { Modal, Form, Input, Select, Typography, Button } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import config from './FormProduct.component.config';
import './FormProduct.component.styles.css';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;
const selectAfter = (
  <Select defaultValue="1" className="select-after">
    <Option value="1">1</Option>
    <Option value="2">2</Option>
    <Option value="21">21</Option>
    <Option value="22">22</Option>
    <Option value="4">4</Option>
    <Option value="5">5</Option>
    <Option value="6">6</Option>
    <Option value="7">7</Option>
    <Option value="9">9</Option>
    <Option value="10">10</Option>
  </Select>
);
const FormProduct = ({
  productGroupCat,
  showModal,
  closeModal,
  createProduct,
  ...props
}) => {
  const refForm = React.useRef();
  // const [state, setState] = React.useState({});
  const generateProductGroup = () => {
    const data = productGroupCat.map((item) => ({
      label: item.service_group,
      value: item.id,
    }));
    return data;
  };

  return (
    <Modal
      className="modal-produk"
      title={<Title level={4}>Tambah Produk Baru</Title>}
      visible={showModal}
      closeIcon={<CloseCircleFilled style={{ fontSize: 24 }} />}
      onCancel={() => closeModal({ type: 'closeModal' })}
      maskClosable={false}
      width={600}
      footer={false}
      centered
      destroyOnClose
    >
      <Form
        ref={refForm}
        layout="horizontal"
        id="createProduct"
        name="produk"
        onFinish={() => {
          const requestBody = {
            ...refForm.current.getFieldsValue(),
          };
          createProduct(requestBody);
          refForm.current.setFieldsValue({
            service_group: '',
            service_code: '',
            service_name: '',
            description: '',
          });
        }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {/* <Form.Item label="Group Produk" name="service_group">
          {selectAfter}
        </Form.Item> */}
        <Form.Item
          label="Grup Produk"
          name="service_group"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Select
            options={generateProductGroup()}
            placeholder="Pilih Grup Produk..."
          />
        </Form.Item>
        <Form.Item
          label="Kode Produk"
          name="service_code"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input placeholder="Kode Produk..." />
        </Form.Item>
        <Form.Item
          label="Nama Produk"
          name="service_name"
          rules={[
            {
              required: true,
              message: 'Dont make me empty :(',
            },
          ]}
        >
          <Input placeholder="Nama Produk..." />
        </Form.Item>
        <Form.Item label="Deskripsi" name="description">
          <TextArea
            type="text"
            placeholder="Isi deskripsi produk/layanan disini..."
            rows={5}
          />
        </Form.Item>
        <div className="button-action">
          <Button
            type="default"
            onClick={() => closeModal({ type: 'closeModal' })}
          >
            Batalkan
          </Button>
          <Button htmlType="submit" type="primary">
            Tambahkan Produk
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

FormProduct.displayName = config.displayName;
FormProduct.defaultProps = config.defaultProps;
FormProduct.propTypes = config.propTypes;

export default FormProduct;
