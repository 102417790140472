import React, { useRef } from 'react';
import { Modal, Form, Input, Typography, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import config from './FormClient.component.config';
import './FormClient.component.styles.css';

const { TextArea } = Input;
const { Title } = Typography;

const FormClient = ({ visibleModal, navigationContainer, ...props }) => {
  const refModal = useRef();
  return (
    <Modal
      className="modal-client"
      title={<Title level={4}>Tambah Client Baru</Title>}
      visible={visibleModal}
      onCancel={() => navigationContainer({ type: 'addClient' })}
      maskClosable={false}
      width={600}
      footer={false}
      centered
      destroyOnClose
    >
      <Form
        ref={refModal}
        layout="horizontal"
        name="client"
        onFinish={() => console.log(refModal)}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label="Nama Client" name="nama_client">
          <Input placeholder="Nama Client..." />
        </Form.Item>
        <Form.Item label="Deskripsi" name="deskripsi">
          <TextArea
            type="text"
            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit..."
            rows={5}
          />
        </Form.Item>
        <div className="button-action">
          <Button
            type="default"
            onClick={() => navigationContainer({ type: 'addClient' })}
          >
            Batalkan
          </Button>
          <Button type="primary">Tambahkan Client</Button>
        </div>
      </Form>
    </Modal>
  );
};

FormClient.displayName = config.displayName;
FormClient.defaultProps = config.defaultProps;
FormClient.propTypes = config.propTypes;

export default FormClient;
