/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { isNaN } from 'lodash';

import { Table, Input, Button, Row, Col, Tag, Progress } from 'antd';
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatNumber,
  formatPercentage,
} from '../../utils/string';

import config from './DataTable.component.config';
import './DataTable.component.styles.css';

const DataTable = ({
  title,
  columns,
  dataSource,
  search,
  withPagination,
  disablePagination,
  sizeChanger,
  haveAction,
  loading,
}) => {
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [statePagination, setStatePagination] = useState({
    showSizeChanger: sizeChanger,
    defaultPageSize: 10,
    pageSize: withPagination,
    hideOnSinglePage: disablePagination,
  });
  const [value, setValue] = useState('');
  const indicatorColour = [
    '#0a7ce5',
    '#1F36C1',
    '#3ca1ff',
    '#54adff',
    '#b6dbff',
  ];

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);
  const generateColumns = () => {
    let width = 0;
    let keyDefault = 0;
    const data = columns.map((val) => {
      width += val.width;
      const kIndex = val.key ? val.key : keyDefault;
      const arrayColumn = {
        title: val.title,
        dataIndex: kIndex,
        width: val.width,
        ...(val.fixed && {
          fixed: val.fixed ? val.fixed : 'left',
        }),
        ...(val.linked && {
          render: (keyData) => (
            <Button type="link" onClick={() => val.actionLinked(keyData)}>
              {keyData}
            </Button>
          ),
        }),
        ...(val.formatDate && {
          render: (keyData) => formatDate(keyData),
        }),
        ...(val.formatDateTime && {
          render: (keyData) => formatDateTime(keyData),
        }),
        ...(val.percentage && {
          render: (keyData) => (
            <>
              <Row>
                <Col flex={1} style={{ marginRight: '5px' }}>
                  {keyData[0]}%
                </Col>
                <Col flex={4} style={{ width: '150px' }}>
                  <Progress
                    percent={keyData[0]}
                    showInfo={false}
                    strokeColor="#ff5821"
                    size="small"
                  />
                </Col>
              </Row>
              <Row>
                <Col flex={1} style={{ marginRight: '5px' }}>
                  {keyData[1]}%
                </Col>
                <Col flex={4} style={{ width: '150px' }}>
                  <Progress
                    percent={keyData[1]}
                    showInfo={false}
                    strokeColor="#fdb400"
                    size="small"
                  />
                </Col>
              </Row>
            </>
          ),
        }),
        ...(val.formatNumber && {
          render: (keyData) =>
            formatNumber(keyData, val.numberFixed ? val.numberFixed : 0),
        }),
        ...(val.formatCurrency && {
          render: (keyData) => formatCurrency(keyData, val.formatCurrency),
        }),
        ...(val.formatPercentage && {
          render: (keyData) =>
            formatPercentage(keyData, val.numberFixed ? val.numberFixed : 0),
        }),
        ...(val.sort && {
          sorter: (a, b) => a[kIndex].localeCompare(b[kIndex]),
        }),
        ...(val.defaultSortOrder && {
          defaultSortOrder: val.defaultSortOrder,
        }),
        ...(val.sortNumber && {
          sorter: (a, b) =>
            Number(
              a.toString()[kIndex].replace(/[^0-9,-]+/g, '').replace(',', '.'),
            ) -
            Number(
              b.toString()[kIndex].replace(/[^0-9,-]+/g, '').replace(',', '.'),
            ),
        }),
        ...(val.sortFormatNumber && {
          sorter: (a, b) => a[kIndex] - b[kIndex],
        }),
        ...(val.sortFormatDate && {
          sorter: (a, b) =>
            new Date(a[kIndex]).getTime() -
            new Date(b[kIndex]).getTime(),
        }),
        ...(val.coloringValue && {
          render: (keyData) => {
            const keysIdx =
              keyData === '' || keyData === undefined
                ? keyData
                : keyData.replaceAll('%', '').replaceAll(',', '.');
            const valueColor = parseFloat(keysIdx).toFixed(2).toLocaleString();
            let colour = 'white';
            if (isNaN(parseFloat(keysIdx)))
              return (
                <div
                  style={{ width: 'auto', height: 30, backgroundColor: colour }}
                />
              );
            if (valueColor >= 0.1 && valueColor < 20) {
              colour = `${indicatorColour[3]}`;
            } else if (valueColor >= 20 && valueColor < 50) {
              colour = `${indicatorColour[2]}`;
            } else if (valueColor >= 50 && valueColor < 75) {
              colour = `${indicatorColour[1]}`;
            } else if (valueColor >= 75) {
              colour = `${indicatorColour[0]}`;
            } else {
              colour = `${indicatorColour[4]}`;
            }
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'auto',
                  height: 30,
                  backgroundColor: colour,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                {keyData}
              </div>
            );
          },
        }),
        ...(val.tags && {
          render: (keyData) => (
            <>
              {keyData.map((a) => (
                <Tag style={{ borderRadius: '15px' }} color="#1e87f0" key={a}>
                  {a}
                </Tag>
              ))}
            </>
          ),
        }),
        ...(val.actionRows && {
          render: (keyData) => (
            <div className="action-table">
              {haveAction.map((items) => items(keyData))}
            </div>
          ),
        }),
      };
      keyDefault += 1;
      return arrayColumn;
    });

    return {
      width,
      data,
    };
  };

  return (
    <div id="DataTable">
      <Table
        loading={loading}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? 'table-row-dark' : 'table-row-light'
        }
        dataSource={filteredDataSource}
        columns={generateColumns().data}
        scroll={{ x: generateColumns().width }}
        pagination={withPagination ? statePagination : null}
        onChange={(pagination) => setStatePagination(pagination)}
      />
    </div>
  );
};

DataTable.displayName = config.displayName;
DataTable.defaultProps = config.defaultProps;
DataTable.propTypes = config.propTypes;

export default DataTable;
