import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Layout,
  Input,
  Dropdown,
  Row,
  Col,
  Typography,
  Space,
  Menu,
  Avatar,
  Button,
} from 'antd';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';

import SideMenuLayout from '../SideMenuLayout/SideMenuLayout.component';

import config from './AppLayout.component.config';
import './AppLayout.component.styles.css';

const { Header, Content } = Layout;
const { Search } = Input;
const { Text } = Typography;

const AppLayout = ({ routeMenu, location, ...props }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const readMenu = () => {
    let isDashboard = true;
    let isDetail = false;
    if (
      location.pathname.indexOf('detail') > 0 ||
      location.pathname.indexOf('create') > 0
    ) {
      isDetail = true;
      isDashboard = false;
    }
    return { isDashboard, isDetail };
  };
  const navigate = useNavigate();
  const navigateBackFromDetail = () => {
    let locationForBack = location.pathname;
    if (location.pathname.indexOf('detail') > 0)
      locationForBack = location.pathname.replace('/detail', '');
    if (location.pathname.indexOf('create') > 0)
      locationForBack = location.pathname.replace('/create', '');
    navigate(locationForBack);
  };

  return (
    <Layout>
      <SideMenuLayout
        contentMenu={routeMenu}
        location={location}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
      />
      <Layout id="AppLayout" style={{ marginLeft: collapsed ? 100 : 280 }}>
        <Header>
          <Row gutter={24}>
            <Col flex="auto" className="filter-search">
              <Text>
                <b>Day {format(new Date(), 'eeee, dd MMMM yyyy')}</b>
              </Text>
              {/* {readMenu().isDashboard && (
                <div style={{ marginTop: 10 }}>
                  <Input.Search
                    size="middle"
                    placeholder="cari invoice disini..."
                  />
                </div>
              )} */}
              {readMenu().isDetail && (
                <div>
                  <Button
                    type="link"
                    icon={<LeftOutlined />}
                    onClick={() => navigateBackFromDetail()}
                  >
                    Kembali
                  </Button>
                </div>
              )}
            </Col>
            <Col flex="auto" className="user-info">
              <Space>
                <div>
                  <Avatar size={30} src="https://joeschmoe.io/api/v1/random" />
                  <Text>Hanny Dzikria</Text>
                </div>
                <DownOutlined />
              </Space>
            </Col>
          </Row>
        </Header>
        <Content className="content-layout">{props.children}</Content>
      </Layout>
    </Layout>
  );
};

AppLayout.displayName = config.displayName;
AppLayout.defaultProps = config.defaultProps;
AppLayout.propTypes = config.propTypes;

export default AppLayout;
