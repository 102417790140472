/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState, componentDidMount } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Typography,
  Button,
  Popconfirm,
  Space,
  Upload,
  Dropdown,
  Menu,
  message,
  List,
  Collapse,
  Row,
  Col,
  Select,
  TreeSelect,
  InputNumber,
  Checkbox,
  Card,
  notification,
} from 'antd';
import {
  UploadOutlined,
  DownOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  EditFilled,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { DataTable } from '../../../components';
// import FormInvoice from './DetailInvoice/DetailInvoiceOpen.components';
import FormClient from './FormClient/FormClient.component';

import { getParamURL } from '../../../utils/options';

import config from './DetailInvoiceOpen.component.config';
import './DetailInvoiceOpen.component.styles.css';
import { formatDate, formatDateTime, formatNumber } from '../../../utils/string';
import {
  executeS3UploadUrl,
  generateS3UploadUrl,
} from '../../../services/aws.service';

const { TextArea } = Input;
const { Title, Text, Paragraph, Link } = Typography;
const { Panel } = Collapse;

const DetailInvoiceOpen = ({
  invoiceData,
  invoiceUpdate,
  updateConditionInvoice,
  invoiceDetail,
  setInvoiceDetail,
  clientDetail,
  clientUpdate,
  productList,
  productListData,
  productListGroupData,
  productListGroup,
  clientList,
  invoiceUpdateTTRecuring,
  generateInvoiceRecurring,
  reqInvoiceSentEmail,
  ...props
}) => {
  const detailData = invoiceData?.data;
  const detailDataLoading = invoiceData?.loading;
  const productListArr = productListData?.data;
  const recurringInvoice = detailData?.is_recurring;
  const recurringInvoiceCount = detailData?.recurring_count;
  const transactionData = detailData?.transaction;
  const transactionInvoiceNumber =
    detailData?.transaction[transactionData.length - 1]?.invoice_number;
  const transactionRecurring =
    detailData?.transaction[transactionData.length - 1]?.recurring_count;
  const transactionCreatedDate =
    detailData?.transaction[transactionData.length - 1]?.created_at;
  const statusDetailData =
    detailData?.transaction[transactionData.length - 1]?.payment_status;
  const statusDetailDataID =
    detailData?.transaction[transactionData.length - 1]?.payment_status_id;

  const navigate = useNavigate();
  const invoiceID = getParamURL('invoiceID');
  const [state, setState] = useState({
    needMounting: true,
    renderFormClient: false,
    renderFormInvoice: false,
    renderDetailInvoice: true,
    fileList: [],
  });

  const navigationContainer = ({ ...nav }) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      switch (nav.type) {
        case 'editClient':
          currentState.renderFormClient = !currentState.renderFormClient;
          currentState.dataDetailClient = nav?.data;
          break;
        default:
          currentState.renderListInvoice = true;
          currentState.renderDetailInvoice = false;
          currentState.renderFormInvoice = false;
          currentState.renderFormClient = false;
          break;
      }
      return { ...currentState };
    });
  };

  // Initializing Transaction data //
  // SET PRODUCT LIST
  const [stateDaftarLayanan, setStateDaftarLayanan] = React.useState({
    loadProgressDone: true,
    addNew: false,
    list_data: [],
    list_data_existing: [],
  });
  // SET Calculation data
  const [stateTotal, setStateTotal] = React.useState({
    loadProgressDone: true,
    total_ppn: 0,
    total_pph: 0,
    total: 0,
    total_amount: 0,
  });

  const mountingFirstData = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setInvoiceDetail({ id: invoiceID });
    productList();
    productListGroup();

    // recollecting data from transaction draft
    // product listing
    const productFromTransactionDraft = [];
    const attactmentFromTransactionDraft = [];
    const transactionProduct = detailData.product_tt;
    const transactionRules = [0, 1];
    transactionData.forEach((transac) => {
      if (transac.recurring_count !== 1) {
        // recollection attachment
        const attachtmentTransaction = transac.attachment.split(';');
        attachtmentTransaction.forEach((attachment) => {
          if (attachment !== '') {
            attactmentFromTransactionDraft.push({
              uid: attachment,
              name: attachment,
              url: attachment,
              status: 'done',
            });
          }
        });

        // recollecting product
        transactionProduct.forEach((prod) => {
          if (transac.id === prod.transaction_id) {
            const ppnNominal =
              prod.ppn === 1
                ? (prod.qty * prod.amount - prod.discount) * 0.11
                : 0;
            const pphNominal =
              prod.pph === 1
                ? (prod.qty * prod.amount - prod.discount) * 0.02
                : 0;

            if (transac.payment_status_id === 0) {
              productFromTransactionDraft.push({
                ...prod,
                ppn_nominal: ppnNominal,
                pph_nominal: pphNominal,
              });
            }
            if (transac.payment_status_id === 1) {
              productFromTransactionDraft.push({
                ...prod,
                ppn_nominal: ppnNominal,
                pph_nominal: pphNominal,
                isTransactionOpen: true,
              });
            }
          }
        });
      }
    });

    setState((prevState) => {
      const currenState = { ...prevState };
      currenState.fileList = [
        ...currenState.fileList,
        ...attactmentFromTransactionDraft,
      ];
      return { ...currenState };
    });

    // INIT CALCULATED DATA PRODUCT - TOTAL AMOUNT
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.list_data_existing = detailData.product.map((val) => ({
        ...val,
        existing: true,
        ppn_nominal:
          val.ppn === 1 ? (val.qty * val.amount - val.discount) * 0.11 : 0,
        pph_nominal:
          val.pph === 1 ? (val.qty * val.amount - val.discount) * 0.02 : 0,
      }));

      currentLayanan.list_data = detailData.product.map((val) => ({
        ...val,
        existing: true,
        ppn_nominal:
          val.ppn === 1 ? (val.qty * val.amount - val.discount) * 0.11 : 0,
        pph_nominal:
          val.pph === 1 ? (val.qty * val.amount - val.discount) * 0.02 : 0,
      }));
      currentLayanan.list_data = [
        ...currentLayanan.list_data,
        ...productFromTransactionDraft,
      ];
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });

    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };

  useEffect(() => {
    if (state.needMounting) mountingFirstData();
  }, [state.needMounting]);

  // section product listing
  const onChangeInputValueLayanan = (indexLayanan, typeValue, value) => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      const findExistLayanan = currentLayanan.list_data.find(
        (layanan, index) => index === indexLayanan,
      );

      if (findExistLayanan !== undefined) {
        switch (typeValue) {
          case 'product':
            findExistLayanan.service_id = value;
            break;
          case 'quantity':
            findExistLayanan.qty = value;
            // update sub quantity
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            break;
          case 'amount':
            findExistLayanan.amount = value;
            // update sub total
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'discount':
            findExistLayanan.discount = value;
            // update discount
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'ppn':
            // update ppn
            // value ? 1 : 0; validasi jika value nya TRUE kasi nilai 1
            findExistLayanan.ppn = value ? 1 : 0;
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          case 'pph':
            // update pph
            // value ? 1 : 0; validasi jika value nya TRUE kasi nilai 1
            findExistLayanan.pph = value ? 1 : 0;
            findExistLayanan.sub_total =
              findExistLayanan.qty * findExistLayanan.amount -
              findExistLayanan.discount;
            if (findExistLayanan.ppn === 1) {
              findExistLayanan.ppn_nominal = findExistLayanan.sub_total * 0.11;
            } else {
              findExistLayanan.ppn_nominal = 0;
            }
            if (findExistLayanan.pph === 1) {
              findExistLayanan.pph_nominal = findExistLayanan.sub_total * 0.02;
            } else {
              findExistLayanan.pph_nominal = 0;
            }
            break;
          default:
            break;
        }
      }
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });
  };
  const tambahLayanan = () => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.list_data = [
        ...currentLayanan.list_data,
        {
          service_id: 0,
          qty: 0,
          amount: 0,
          discount: 0,
          ppn: 0,
          pph: 0,
          sub_total: 0,
          ppn_nominal: 0,
          pph_nominal: 0,
        },
      ];
      currentLayanan.addNew = true;
      return { ...currentLayanan };
    });
  };
  const hapusLayanan = (indexLayanan) => {
    setStateDaftarLayanan((prevLayanan) => {
      const currentLayanan = { ...prevLayanan };
      currentLayanan.loadProgressDone = false;
      const layananBaru = currentLayanan.list_data.filter(
        (items, index) => index !== indexLayanan,
      );
      currentLayanan.list_data = layananBaru;
      calculateTotalAmount(currentLayanan.list_data);
      return { ...currentLayanan };
    });
    setTimeout(() => {
      setStateDaftarLayanan((prevLayanan) => {
        const currentLayanan = { ...prevLayanan };
        currentLayanan.loadProgressDone = true;
        return { ...currentLayanan };
      });
    }, 500);
  };

  const calculateTotalAmount = (returnData) => {
    setStateTotal((prevTotal) => {
      const currentTotal = { ...prevTotal };
      currentTotal.total_ppn = returnData.reduce(
        (acc, obj) => acc + obj.ppn_nominal,
        0,
      );
      currentTotal.total_pph = returnData.reduce(
        (acc, obj) => acc + obj.pph_nominal,
        0,
      );
      currentTotal.total_amount = returnData.reduce(
        (acc, obj) => acc + obj.sub_total,
        0,
      );
      currentTotal.total = returnData.reduce(
        (acc, obj) => acc + obj.ppn_nominal - obj.pph_nominal + obj.sub_total,
        0,
      );
      return { ...currentTotal };
    });
  };
  const renderInvoiceDetail = () => (
    <div className="inv-detail">
      <div className="content-inv-detail">
        <div className="header">
          <Row gutter={21}>
            <Col md={1} className="actions-"></Col>
            <Col md={5}>
              <Text level={5}>Nama Layanan</Text>
            </Col>
            <Col md={2}>
              <Text level={5}>Qty</Text>
            </Col>
            <Col md={5}>
              <Text level={5}>Harga Satuan</Text>
            </Col>
            <Col md={5}>
              <Text level={5}>Diskon</Text>
            </Col>
            <Col md={3}>
              <Text level={5}>Subtotal</Text>
            </Col>
            <Col md={2} className="ppn-pph">
              <Text level={5}>PPN</Text>
            </Col>
            <Col md={2} className="ppn-pph">
              <Text level={5}>PPH 23</Text>
            </Col>
          </Row>
        </div>
        <div className="body-layanan">
          {stateDaftarLayanan.loadProgressDone &&
            stateDaftarLayanan.list_data.map((itemLayanan, indexLayanan) => (
              <div className="content-field-layanan">
                <Row gutter={21}>
                  <Col md={1} className="actions-">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<MinusOutlined />}
                      onClick={() => hapusLayanan(indexLayanan)}
                    />
                  </Col>
                  <Col md={5}>
                    {/* product */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Pilih Produk',
                        },
                      ]}
                    >
                      <TreeSelect
                        style={{
                          width: '100%',
                        }}
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: 'auto',
                        }}
                        showSearch
                        treeDefaultExpandAll
                        filterTreeNode={(input, option) =>
                          (option?.title ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        treeData={productListGroupData.data}
                        onChange={(val) =>
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'product',
                            val,
                          )
                        }
                        defaultValue={
                          itemLayanan.service_id !== 0
                            ? itemLayanan.service_id
                            : null
                        }
                      />
                      {/* <Select
                        placeholder="choose..."
                        defaultValue={
                          itemLayanan.service_id !== 0
                            ? itemLayanan.service_id
                            : null
                        }
                        onChange={(val) =>
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'product',
                            val,
                          )
                        }
                      >
                        {productListArr.map((option) => (
                          <option value={option.id}>
                            {option.service_name}
                          </option>
                        ))}
                      </Select> */}
                    </Form.Item>
                  </Col>
                  <Col md={2}>
                    {/* qty */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Quantity',
                        },
                        {
                          min: 1,
                          message: 'quantity tidak boleh kurang dari 1',
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="input quantity..."
                        defaultValue={
                          itemLayanan.qty !== 0 ? itemLayanan.qty : null
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'quantity',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5}>
                    {/* harga satuan */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Amount',
                        },
                        {
                          min: 1,
                          message: 'harga tidak boleh 0',
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        prefix="Rp. "
                        placeholder="input harga..."
                        defaultValue={
                          itemLayanan.amount !== 0 ? itemLayanan.amount : null
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'amount',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={5}>
                    {/* discount */}
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Isi Discount',
                        },
                      ]}
                    >
                      <InputNumber
                        defaultValue={
                          itemLayanan.discount !== 0
                            ? itemLayanan.discount
                            : null
                        }
                        controls={false}
                        placeholder="input diskon ..."
                        prefix="Rp. "
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onKeyUp={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'discount',
                            Number(val.target.value.replaceAll(',', '')),
                          );
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    {/* sub total */}
                    <Text>Rp. {formatNumber(itemLayanan.sub_total)}</Text>
                  </Col>
                  <Col md={2} className="ppn-pph">
                    {/* ppn */}
                    <Form.Item>
                      <Checkbox
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        defaultChecked={
                          itemLayanan.ppn !== 0 ? 'checked' : false
                        }
                        onClick={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'ppn',
                            Number(val.target.checked),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} className="ppn-pph">
                    {/* pph */}
                    <Form.Item>
                      <Checkbox
                        disabled={
                          itemLayanan.service_id === 0 ? 'disabled' : false
                        }
                        defaultChecked={
                          itemLayanan.pph !== 0 ? 'checked' : false
                        }
                        onClick={(val) => {
                          onChangeInputValueLayanan(
                            indexLayanan,
                            'pph',
                            Number(val.target.checked),
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      </div>
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => tambahLayanan()}
      >
        Tambah Layanan
      </Button>
    </div>
  );

  // Update Client Primary Email and Email CC only
  const actionUpdateClient = (payload) => {
    const requestBody = {
      ...payload,
      id: detailData.client_id,
    };
    clientUpdate(requestBody).then(() => {
      clientDetail(detailData.client_id).then(() => {
        setInvoiceDetail({ id: invoiceID });
      });
    });
  };

  // Validate Attachments File Size
  const beforeUploadAttachment = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('File must smaller than 10MB!');
    } else message.success('File has been uploaded!');
    return isLt10M;
  };
  // Handle Upload Attachment
  const actionUpload = (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
      status: 'done',
    };
    generateS3UploadUrl('invoice/attachment', upload?.file).then((url) => {
      executeS3UploadUrl(url, upload?.file).then((imageUrl) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.fileList = [...currentState.fileList];
          currentState.fileList.push({
            ...tempData,
            url: imageUrl,
            status: imageUrl ? 'done' : 'error',
          });
          return { ...currentState };
        });
      });
    });
  };
  const handleDeleteAttachment = (data) => {
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.fileList = currentState.fileList.filter(
        (items) => items.url !== data.url,
      );
      return { ...currentState };
    });
  };
  const formTransactionRecuring = React.useRef();

  const renderInvoiceTerjadwal = () => {
    transactionData.sort((a, b) => a.recurring_count - b.recurring_count);
    const productForTransaction = detailData.product_tt;

    return (
      <Collapse
        defaultActiveKey={[`${transactionData.length}`]}
        expandIconPosition="end"
      >
        {transactionData.map((items, indexTransaction) => {
          const statusTransaction = items.payment_status_id;
          const attachment = items.attachment.split(';');
          const attachmentList =
            attachment.length > 0 &&
            attachment.map(
              (attach) =>
                attach !== '' && (
                  <div>
                    <Link href={attach} target="_blank">
                      {attach}
                    </Link>
                  </div>
                ),
            );

          // recollection data for next recuring
          const productDefault = [];
          // const dataProductByTransaction = [];
          detailData.product.forEach((defaultItems) => {
            const ppnNominal =
              defaultItems.ppn === 1
                ? (defaultItems.qty * defaultItems.amount - defaultItems.discount) * 0.11
                : 0;

            const pphNominal =
              defaultItems.pph === 1
                ? (defaultItems.qty * defaultItems.amount - defaultItems.discount) * 0.02
                : 0;

            productDefault.push({
              ...defaultItems,
              ppn_nominal: ppnNominal,
              pph_nominal: pphNominal,
            });
          });
          productForTransaction.forEach((productTransaction) => {
            if (
              items.recurring_count !== 1 &&
              productTransaction.transaction_id === items.id
            ) {
              const ppnNominal =
                productTransaction.ppn === 1
                  ? (productTransaction.qty * productTransaction.amount - productTransaction.discount) * 0.11
                  : 0;

              const pphNominal =
                productTransaction.pph === 1
                  ? (productTransaction.qty * productTransaction.amount - productTransaction.discount) * 0.02
                  : 0;

              productDefault.push({
                ...productTransaction,
                ppn_nominal: ppnNominal,
                pph_nominal: pphNominal,
              });

              // if (items.payment_status_id === 1) {
              //   setStateTotal((prevTotal) => {
              //     const currentTotal = { ...prevTotal };
              //     currentTotal.total_ppn += ppnNominal;
              //     currentTotal.total_pph += pphNominal;
              //     currentTotal.total_amount += productTransaction.sub_total;
              //     currentTotal.total +=
              //       ppnNominal - pphNominal + productTransaction.sub_total;
              //     return { ...currentTotal };
              //   });
              // }
            }
          });
          if (items.payment_status_id === 0) {
            formTransactionRecuring.current?.setFieldsValue({
              notes: items.notes,
            });
          }

          // generate total transaction per recurring
          const recurringSubtotal = productDefault.reduce(
            (acc, obj) => acc + obj.sub_total,
            0,
          );
          const recurringPPN = productDefault.reduce(
            (acc, obj) => acc + obj.ppn_nominal,
            0,
          );
          const recurringPPH = productDefault.reduce(
            (acc, obj) => acc + obj.pph_nominal,
            0,
          );
          const recurringTotal = productDefault.reduce(
            (acc, obj) => acc + obj.ppn_nominal - obj.pph_nominal + obj.sub_total,
            0,
          );

          const overviewRecuring = (
            <Panel
              header={
                <Title level={5} style={{ margin: 'unset' }}>
                  Bulan {items.recurring_count}
                </Title>
              }
              key={`${indexTransaction + 1}`}
            >
              <div className="item-recuring">
              <Row gutter={24}>
                  <Col md={18}>
                    <div>
                      Nomor Invoice : {items.invoice_number}
                    </div>
                    <div>
                      Di kirim : {formatDate(items.sent_date)}
                    </div>
                    <div>
                      Jatuh Tempo : {formatDate(items.due_date)}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Popconfirm
                        placement="top"
                        title={`Kirim ulang invoice bulan ${items.recurring_count}?`}
                        onConfirm={() => resendEmailInvoice(items)}
                        okText="Ya"
                        cancelText="Batal"
                      >
                        <Button type="primary">Kirim Ulang Invoice</Button>
                      </Popconfirm>
                      <div
                        style={{ marginLeft: 10 }}
                        className={`status-badges-${items.payment_status.toLowerCase()}`}
                      >
                        {items.payment_status}
                      </div>
                    </div>
                  </Col>
                  <Col md={6} hidden>
                    <Text>
                      Checkout Link :{' '}
                      <Link
                        target="_blank"
                        href={`${window.location.origin}/checkout?trx=${items.transaction_hash}`}
                      >
                        click here...
                      </Link>
                    </Text>
                  </Col>
                </Row>
                <div className="history-invoice" style={{ marginTop: 10, marginBottom: 10 }}>
                  <DataTable
                    columns={config.defaultProps.columnHeader}
                    dataSource={productDefault}
                    withPagination={99}
                    disablePagination
                    sizeChanger
                  />
                </div>
                <Row gutter={24}>
                  <Col md={24}>
                    <div className="notes-recuring">
                      <Title level={5}>Catatan</Title>
                      <Text>{items.notes}</Text>
                    </div>
                    <div className="attachment-recuring">
                      <Title level={5}>Lampiran</Title>
                      {attachmentList}
                    </div>
                    <div className="inv-overview">
                      <List.Item className="item-overview">
                        <Text>Subtotal:</Text>
                        <Text>Rp. {formatNumber(recurringSubtotal)}</Text>
                      </List.Item>
                      <List.Item
                        className="item-overview"
                        hidden={recurringPPN === 0 ? 'hidden' : false}
                      >
                        <Text>PPN:</Text>
                        <Text>Rp. {formatNumber(recurringPPN)}</Text>
                      </List.Item>
                      <List.Item
                        className="item-overview"
                        hidden={recurringPPH === 0 ? 'hidden' : false}
                      >
                        <Text>PPh 23:</Text>
                        <Text>Rp. {formatNumber(recurringPPH)}</Text>
                      </List.Item>
                      <List.Item className="item-overview total">
                        <Text>
                          <b>Total Ditagihkan:</b>
                        </Text>
                        <Text>
                          <b>Rp. {formatNumber(recurringTotal)}</b>
                        </Text>
                      </List.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            </Panel>
          );
          const editableRecuring = (
            <Panel
              header={
                <Title level={5} style={{ margin: 'unset' }}>
                  Bulan {items.recurring_count}
                </Title>
              }
              key={`${indexTransaction + 1}`}
            >
              <div className="item-recuring">
                <Row gutter={24}>
                  <Col md={6}>
                    <Text>Jumlah Tagihan Bulan {items.recurring_count}</Text>
                  </Col>
                  <Col md={6}>
                    <Text>Rp. {formatNumber(stateTotal.total)}</Text>
                  </Col>
                  <Col md={12}>
                    <div
                      className={`status-badges-${items.payment_status.toLowerCase()}`}
                    >
                      {items.payment_status}
                    </div>
                  </Col>
                </Row>
                <div className="notes-recuring">
                  <Form.Item label="Catatan" name="notes">
                    <TextArea
                      name="notes"
                      type="text"
                      placeholder="Isi catatan disini..."
                    />
                  </Form.Item>
                </div>
                <div className="attachment-recuring">
                  <Form.Item label="Lampiran" name="attachment">
                    <Upload
                      fileList={state.fileList}
                      customRequest={actionUpload}
                      beforeUpload={beforeUploadAttachment}
                      onRemove={(data) => handleDeleteAttachment(data)}
                    >
                      {state.fileList.length !== 10 && (
                        <Button icon={<UploadOutlined />}>
                          Upload (Max: 10)
                        </Button>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </Panel>
          );

          return statusTransaction === 0 ? editableRecuring : overviewRecuring;
        })}
      </Collapse>
    );
  };

  const readRulesDaftarLayanan = () => {
    let readyToCreate = false;
    if (stateDaftarLayanan.list_data.length === 0) {
      notification.warning({
        message:
          'Anda belum menambahkan produk untuk invoice ini, silahkan untuk menambahkan produk terlebih dahulu',
      });
      readyToCreate = false;
    } else {
      stateDaftarLayanan.list_data.forEach((items) => {
        if (items.service_id === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi belum ada produk yang di pilih',
          });
          readyToCreate = false;
        } else if (items.service_id !== 0 && items.qty === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi quatity produk belum ditambahkan',
          });
          readyToCreate = false;
        } else if (items.service_id !== 0 && items.amount === 0) {
          notification.warning({
            message:
              'anda memiliki item produk yang ditambahkan, akan tetapi harga satuan produk belum ditambahkan',
          });
          readyToCreate = false;
        } else {
          readyToCreate = true;
        }
      });
    }
    return readyToCreate;
  };
  const updateTransactionRecuring = () => {
    const readyToCreate = readRulesDaftarLayanan();
    const indexTransaction = transactionData.length - 1;
    const fieldsRecuring = formTransactionRecuring.current.getFieldsValue();
    // collect data
    const requiredData = {
      invoice_id: detailData.id,
      client_id: detailData.client_id,
      transaction_id: transactionData[indexTransaction].id,
      invoice_number: transactionData[indexTransaction].invoice_number,
      attachment: null,
      total_amount: stateTotal.total,
      status: 0,
      notes: fieldsRecuring.notes,
      product: [],
    };

    const collectProduct = [];
    stateDaftarLayanan.list_data.forEach((product) => {
      if (!product?.existing) {
        collectProduct.push(product);
      }
      // stateDaftarLayanan.list_data_existing.forEach((productExisting) => {
      //   if (
      //     productExisting.service_id !== product.service_id &&
      //     product.service_id !== 0 &&
      //     productExisting.service_id !== 0
      //   ) {
      //     collectProduct.push(product);
      //   }
      // });
    });
    requiredData.product = collectProduct;

    // attachment
    const tempAttachment = state.fileList.map((files) => files.url);
    requiredData.attachment = tempAttachment.join(';');

    // validation schedule date for new invoice
    const schDate = new Date(transactionData[indexTransaction].scheduled_date);
    const dateNow = new Date();
    const diffTime = Math.ceil(dateNow - schDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if (readyToCreate && diffDays > 0) {
      
      invoiceUpdateTTRecuring(requiredData).then(() => {
        setInvoiceDetail({ id: invoiceID }).then(() => {
          navigate(`/admin/invoice/send?invoiceID=${invoiceID}`);
        });
      });
    } else {
      notification.open({
         type: "error",
         message: "invoice gagal dikirim",
         description: "tanggal hari ini belum sesuai dengan tanggal kirim invoice"
      })
    }
  };

  const resendEmailInvoice = async (transactionRev) => {
    const requestBody = {
      // ...payload,
      invoice_id: invoiceID,
      status: 1,
      total_amount: transactionRev.total_amount,
      client_id: detailData?.client_id,
      transaction_id: transactionRev.id,
      invoice_number: transactionRev.invoice_number,
    };
    await reqInvoiceSentEmail(requestBody);
  };

  const utilitiesTerjadwal = () => (
    <div className="recuring-data">
      <Form ref={formTransactionRecuring} layout="vertical" name="invoice">
        <div className="section-field">
          <Title level={5}>Daftar Layanan</Title>
          <div className="history-invoice">
            <DataTable
              columns={config.defaultProps.columnHeader}
              dataSource={stateDaftarLayanan.list_data_existing}
              loading={detailDataLoading}
              withPagination={99}
              disablePagination
              sizeChanger
            />
            <Card className="card-tambah-product">{renderInvoiceDetail()}</Card>
          </div>
        </div>
        <div className="section-field">{renderInvoiceTerjadwal()}</div>
        <div className="section-field" style={{ textAlign:'right' }}>
          <Button
            type="primary"
            onClick={() => {
              generateInvoiceRecurring({ invoiceID: detailData.id }).then(() => {
                window.location.reload();
              });
            }}
          >
            Generate next Recurring
          </Button>
        </div>
        <div className="section-field">{renderOverviewInvoice()}</div>
        <div className="section-field">
          <Popconfirm
            placement="top"
            title="Pastikan Data Invoice sudah benar dan Tidak Ada Yang Terlewat"
            onConfirm={() => updateTransactionRecuring()}
            okText="Simpan dan Kirim Invoice"
            cancelText="Cek Sekali Lagi"
          >
            <Button type="primary">Simpan dan Kirim</Button>
          </Popconfirm>
        </div>
      </Form>
    </div>
  );
  const utilitiesSatuan = () => (
    <div>
      <div className="section-field">
        <Title level={5}>Daftar Layanan</Title>
        <div className="history-invoice">
          <DataTable
            columns={config.defaultProps.columnHeader}
            dataSource={stateDaftarLayanan.list_data_existing}
            loading={detailDataLoading}
            withPagination={99}
            disablePagination
            sizeChanger
          />
        </div>
      </div>
      <div className="section-field">
        Jadwal Invoice : Tidak ada jadwal, Invoice Satuan. <b>Jatuh tempo </b>
        pada <b>{detailData?.due_date}</b>
      </div>
      <div className="section-field">{renderOverviewInvoice()}</div>
      <div className="section-field">
        <Row gutter={24}>
          <Col md={6}>
            <Title level={5}>Catatan</Title>
            <Text>{detailData?.notes}</Text>
          </Col>
          <Col md={12}>
            <Title level={5}>Lampiran</Title>
            {state.fileList.map(
              (items) =>
                items.url !== '' && (
                  <div>
                    <Link href={items.url} target="_blank">
                      {items.url}
                    </Link>
                  </div>
                ),
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
  const renderOverviewInvoice = () => (
    <div className="inv-overview">
      <List.Item className="item-overview">
        <Text>Subtotal:</Text>
        <Text>Rp. {formatNumber(stateTotal.total_amount)}</Text>
      </List.Item>
      <List.Item
        className="item-overview"
        hidden={stateTotal.total_ppn === 0 ? 'hidden' : false}
      >
        <Text>PPN:</Text>
        <Text>Rp. {formatNumber(stateTotal.total_ppn)}</Text>
      </List.Item>
      <List.Item
        className="item-overview"
        hidden={stateTotal.total_pph === 0 ? 'hidden' : false}
      >
        <Text>PPh 23:</Text>
        <Text>Rp. {formatNumber(stateTotal.total_pph)}</Text>
      </List.Item>
      <List.Item className="item-overview total">
        <Text>
          <b>Total Ditagihkan:</b>
        </Text>
        <Text>
          <b>Rp. {formatNumber(stateTotal.total)}</b>
        </Text>
      </List.Item>
    </div>
  );

  return (
    <div id="DetailInvoiceOpen">
      <div className="button-action-draft" style={{ float: 'right' }}>
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e !== undefined) {
                  let statusDef = 'draft';
                  switch (e.key) {
                    // case '1':
                    //   message.info('Your invoice has been Duplicated!');
                    //   break;
                    case '2':
                      // message.info('Your invoice has been Marked as Void!');
                      statusDef = 'void';
                      break;
                    case '3':
                      // message.info('Your invoice has been Removed!');
                      statusDef = 'remove';
                      break;
                    default:
                      // message.info('Applied');
                      statusDef = 'draft';
                      break;
                  }
                  updateConditionInvoice({
                    id: detailData.transaction[0].id,
                    status_condition: statusDef,
                  }).then(() => {
                    setInvoiceDetail({ id: invoiceID }).then(() => {
                      navigate(`/admin/invoice/draft?invoiceID=${invoiceID}`);
                    });
                  });
                }
              }}
              items={[
                {
                  label: 'Mark as Draft',
                  key: '1',
                  icon: <PlusSquareOutlined />,
                  disabled: detailData.transaction[0].payment_status_id === 0,
                },
                {
                  label: 'Mark as Void',
                  key: '2',
                  icon: <CloseCircleOutlined />,
                  disabled: detailData.transaction[0].payment_status_id === 3,
                },
                {
                  label: 'Remove',
                  key: '3',
                  icon: <DeleteOutlined />,
                  disabled: detailData.transaction[0].payment_status_id === 0,
                },
              ]}
            />
          }
        >
          <Button
            onClick={() =>
              navigationContainer({ type: 'editInvoice', data: detailData })
            }
          >
            {statusDetailData}
            <DownOutlined />
          </Button>
        </Dropdown>
        {detailData.is_recurring === 0 && (
          <Popconfirm
            placement="top"
            title={`Kirim ulang invoice ${transactionInvoiceNumber}?`}
            onConfirm={() =>
              resendEmailInvoice({
                ...detailData,
                invoice_number: transactionInvoiceNumber,
              })
            }
            okText="Ya"
            cancelText="Batal"
          >
            <Button type="default">Kirim Ulang Invoice</Button>
          </Popconfirm>
        )}
        {/* {statusDetailDataID === 1 && (
          <Button
            type="default"
            onClick={() => {
              setInvoiceDetail({ id: invoiceID }).then(() => {
                navigate(`/admin/invoice/send?invoiceID=${invoiceID}`);
              });
            }}
          >
            Kirim Ulang Invoice
          </Button>
        )} */}
      </div>
      <div className="section-field">
        <Title level={2}>{detailData?.project_name}</Title>
        <Card title="Data Klien">
          <Row gutter={24}>
            <Col flex="auto">
              <div hidden={recurringInvoice === 1 ? false : 'hidden'}>
                <div>
                  <b>Total Tagihan :</b> {recurringInvoiceCount} Bulan
                </div>
                <div>
                  <b>Tagihan Bulan :</b> {transactionRecurring}
                </div>
              </div>
              <div>
                <b>Nomor Invoice :</b> {transactionInvoiceNumber}
              </div>
              <div><b>Dibuat Pada Tanggal :</b> {formatDateTime(transactionCreatedDate)}</div>
              <div><b>Dapat dikirim pada dan setelah :</b> {formatDate(transactionCreatedDate)}</div>
              <br />
              <div>Nama Klien : {detailData?.brand_name}</div>
              <div>Nama Kontak Klien : {detailData?.person_in_charge}</div>
              <div>Nomor Kontak Klien : {detailData?.phone}</div>
              <div>Dikirim Ke : {detailData?.primary_email}</div>
              <div>Diteruskan Ke : {detailData?.emailcc}</div>
              <div>Alamat Klien : {detailData?.address}</div>
              {/* <div>
                Nomor Virtual Account :{' '}
                <div className="va"> {detailData?.virtual_account} </div>
              </div> */}
              {/* <div hidden={detailData.is_recurring === 1 ? 'hidden' : false}> */}
              <div hidden>
                <Text>
                  Checkout Link :{' '}
                  <Link
                    target="_blank"
                    href={`${window.location.origin}/checkout?trx=${detailData.transaction[0].transaction_hash}`}
                  >
                    click here...
                  </Link>
                </Text>
              </div>
            </Col>
            <Col
              md={4}
              hidden={detailData.is_recurring === 1 ? false : 'hidden'}
            >
              <div className="button-action">
                <Button
                  type="link"
                  icon={<EditFilled />}
                  onClick={() =>
                    navigationContainer({
                      type: 'editClient',
                      data: detailData,
                    })
                  }
                >
                  Ubah Data Client
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <Card title="Detail Invoice">
        {detailData.is_recurring === 1 ? utilitiesTerjadwal() : utilitiesSatuan()}
      </Card>
      <FormClient
        sourceData={detailData}
        visibleModal={state.renderFormClient}
        navigationContainer={navigationContainer}
        actionUpdateClient={actionUpdateClient}
      />
    </div>
  );
};

DetailInvoiceOpen.displayName = config.displayName;
DetailInvoiceOpen.defaultProps = config.defaultProps;
DetailInvoiceOpen.propTypes = config.propTypes;

export default DetailInvoiceOpen;
