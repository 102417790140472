import { connect } from 'react-redux';
import AdminClient from './AdminClient.component';
import {
  clientUpdate,
  clientList,
  setClientDetail,
} from '../../../redux/actions/client.actions';

const mapStateToProps = (state) => ({
  clientListData: state.client.list,
});

const mapDispatchToProps = {
  clientUpdate,
  clientList,
  setClientDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminClient);
