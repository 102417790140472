import { connect } from 'react-redux';
import AdminInvoice from './AdminInvoice.component';
import {
  invoiceUpdate,
  invoiceListMaster,
  invoiceList,
  invoiceListForExport,
  setInvoiceDetail,
} from '../../../redux/actions/invoice.actions';

const mapStateToProps = (state) => ({
  invoiceListMasterData: state.invoice.listMaster,
  invoiceListData: state.invoice.list,
});

const mapDispatchToProps = {
  invoiceUpdate,
  invoiceListMaster,
  invoiceList,
  invoiceListForExport,
  setInvoiceDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminInvoice);
