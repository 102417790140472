import React from 'react';
import ReactDOM from 'react-dom';

import withClearCache from './cache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './containers';
import './index.css';

const ClearCacheComponent = withClearCache(App);

ReactDOM.render(
  <React.StrictMode>
    <ClearCacheComponent />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
