import React from 'react';
import { Card, Typography } from 'antd';

import DataTable from '../DataTable/DataTable.component';

import config from './CardDataTable.component.config';
import './CardDataTable.component.styles.css';

const { Title, Text } = Typography;

const CardDataTable = ({
  titleCard,
  titleAction,
  tableTitle,
  tableColumn,
  tableData,
  tableSearch,
  tableAction,
  tableWithPagination,
  tableDisablePagination,
  tableSizeChanger,
  loading,
}) => (
  <div id="CardDataTable">
    <Card
      title={
        <div>
          <Title level={4}>{titleCard}</Title>
          <div className="action-header">{titleAction}</div>
        </div>
      }
    >
      <DataTable
        title={tableTitle}
        columns={tableColumn}
        dataSource={tableData}
        haveAction={tableAction}
        withPagination={tableWithPagination}
        disablePagination={tableDisablePagination}
        sizeChanger={tableSizeChanger}
        loading={loading}
      />
    </Card>
  </div>
);

CardDataTable.displayName = config.displayName;
CardDataTable.defaultProps = config.defaultProps;
CardDataTable.propTypes = config.propTypes;

export default CardDataTable;
