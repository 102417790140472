import PropTypes from 'prop-types';

const displayName = 'TextLayout';
const propTypes = {
  // title: PropTypes.string,
  // children: PropTypes.string,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
