import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Image,
  Input,
  message,
  Space,
  Typography,
  Upload,
} from 'antd';
import { MinusOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  executeS3UploadUrl,
  generateS3UploadUrl,
} from '../../../services/aws.service';
import { BTN_LOGIN } from '../../../assets';

import config from './AdminAccount.component.config';
import './AdminAccount.component.styles.css';

const { Title, Text } = Typography;

const AdminAccount = ({ getSignature, updateSignature, ...props }) => {
  const [state, setState] = useState({
    needMounting: true,
    signatureData: {
      signature: null,
      approver: null,
    },
    signatureList: [],
  });
  const formUsers = React.useRef();
  const formSignature = React.useRef();

  const changeSignatureData = () => {
    getSignature().then((data) => {
      setState((prevState) => {
        const currentState = { ...prevState };

        currentState.signatureData.signature = data.signature_file;
        currentState.signatureData.approver = data.signature_approver;

        const signatureList = {
          uid: data.signature_file,
          name: data.signature_approver,
          url: data.signature_file,
          status: 'done',
        };
        currentState.signatureList = [signatureList];

        formSignature.current.setFieldsValue({
          approver: data.signature_approver,
        });

        return { ...currentState };
      });
    });
  };

  const mountingData = () => {
    changeSignatureData();
    setState((prevState) => {
      const currentState = { ...prevState };
      currentState.needMounting = false;
      return { ...currentState };
    });
  };
  useEffect(() => {
    if (state.needMounting) mountingData();
  });

  // Handle Upload Signature
  const actionUploadSignature = (upload) => {
    const tempData = {
      uid: upload?.file?.uid,
      name: upload?.file?.name,
      status: 'done',
    };
    generateS3UploadUrl('invoice/signature', upload?.file).then((url) => {
      executeS3UploadUrl(url, upload?.file).then((imageUrl) => {
        setState((prevState) => {
          const currentState = { ...prevState };
          currentState.signatureList = [
            {
              url: imageUrl,
              ...tempData,
            },
          ];
          return { ...currentState };
        });
      });
    });
  };
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
    } else message.success('Signature has been uploaded!');
    return isJpgOrPng && isLt10M;
  };

  const actionUpdateSignature = () => {
    const fields = formSignature.current.getFieldsValue();
    const bodyRequest = {
      signature_approver: fields.approver,
      signature_file: state.signatureList[0].url,
    };
    updateSignature(bodyRequest).then(() => {
      changeSignatureData();
    });
  };

  return (
    <div id="AdminAccount">
      <Card>
        <Form
          ref={formUsers}
          layout="vertical"
          onFinish={() => {
            console.log(formUsers.current.getFieldsValue());
          }}
        >
          <Title level={4}>Account Users</Title>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
          >
            <Input
              placeholder="Masukkan alamat email..."
              autoComplete="off"
              className="input-text"
              type="email"
            />
          </Form.Item>
          <Form.Item
            label="Password Lama"
            name="current_password"
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Masukkan password Lama..."
              type="password"
            />
          </Form.Item>
          <Form.Item
            label="Password Baru"
            name="new_password"
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Masukkan password Baru..."
              type="password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              block
              htmlType="submit"
              type="primary"
              icon={<Image src={BTN_LOGIN} preview={false} />}
              loading={state.loadingForm}
            >
              Simpan Perubahan
            </Button>
          </Form.Item>
        </Form>

        <Form
          ref={formSignature}
          layout="vertical"
          onFinish={() => actionUpdateSignature()}
        >
          <Title level={4}>Signature</Title>
          <Form.Item
            name="signature"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Upload
                name="files"
                customRequest={actionUploadSignature}
                beforeUpload={beforeUpload}
                fileList={state.signatureList}
                listType="picture"
                accept=".png, .jpeg, .jpg"
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: false,
                }}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Ganti Tandatangan</Button>
              </Upload>
            </Space>
          </Form.Item>
          <Form.Item
            name="approver"
            label="Approver"
            rules={[
              {
                required: true,
                message: 'Dont make me empty :(',
              },
            ]}
          >
            <Input type="text" placeholder="Ex: Name, Job Title..." />
          </Form.Item>
          <Form.Item>
            <Button
              block
              htmlType="submit"
              type="primary"
              icon={<Image src={BTN_LOGIN} preview={false} />}
              loading={state.loadingForm}
            >
              Simpan Signature
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

AdminAccount.displayName = config.displayName;
AdminAccount.defaultProps = config.defaultProps;
AdminAccount.propTypes = config.propTypes;

export default AdminAccount;
