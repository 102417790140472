const BASE_PATH = {
  auth: '/auth',
  admin: '/admin/*',
  checkout: '/checkout/*',
  paid: '/paid/*',
  sitepolicy: '/site-policy/*',
};

const ADMIN_PATH = {
  dashboard: 'dashboard',
  invoice: 'invoice',
  client: 'client',
  product: 'product',
  tax: 'tax',
  settings: 'settings',
  create: 'create',
  draft: 'draft',
  send: 'send',
  detail: 'detail',
};

export default {
  ...BASE_PATH,
  ADMIN_PATH,
};
