/* eslint-disable prettier/prettier */
export const SET_DEFAULT_REDUX = 'SET_DEFAULT_REDUX';
export const SET_DEFAULT_REDUX_HEADER = 'SET_DEFAULT_REDUX_HEADER';
export const SET_DEFAULT_REDUX_MENU = 'SET_DEFAULT_REDUX_MENU';

export const SET_AUTH_USER = 'SET_AUTH_USER';

export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const SET_CLIENT_DETAIL = 'SET_CLIENT_DETAIL';

export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_PRODUCT_GROUP = 'SET_PRODUCT_GROUP';
export const SET_PRODUCT_GROUP_LIST = 'SET_PRODUCT_GROUP_LIST';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';

export const SET_INVOICE_LIST_MASTER = 'SET_INVOICE_LIST_MASTER';
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_INVOICE_LIST_DASHBOARD = 'SET_INVOICE_LIST_DASHBOARD';
export const SET_INVOICE_DETAIL = 'SET_INVOICE_DETAIL';
export const SET_INVOICE_CHECKOUT = 'SET_INVOICE_CHECKOUT';
