import React from 'react';
import path from './path';

import Auth from '../containers/Auth/Auth.container';
import Admin from '../containers/Admin/Admin.container';
import Checkout from '../containers/Checkout/Checkout.container';
import CheckoutPaid from '../containers/Checkout/CheckoutPaid/CheckoutPaid.container';
import SitePolicy from '../containers/SitePolicy/SitePolicy.container';

export const BASE_ROUTE = [
  {
    key: 'auth',
    index: true,
    path: path.auth,
    element: <Auth />,
  },
  {
    key: 'admin',
    path: path.admin,
    element: <Admin />,
  },
  {
    key: 'checkout',
    path: path.checkout,
    element: <Checkout />,
  },
  {
    key: 'paid',
    path: path.paid,
    element: <CheckoutPaid />,
  },
  {
    key: 'sitepolicy',
    path: path.sitepolicy,
    element: <SitePolicy />,
  },
];
