import { SET_CLIENT_LIST, SET_CLIENT_DETAIL } from '../reduxType';

const initialState = {
  list: {
    data: [],
    loading: false,
  },
  detail: {
    data: {},
    loading: false,
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_CLIENT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case SET_CLIENT_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    default:
      return state;
  }
};
